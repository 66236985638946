import { useState, useEffect } from "react";
import Banner from "../../components/Banner";
const DwuckHero = () => {

    const [images, setimages] = useState([]);

    const loadEachTime = 20;
    const [showMoreData, setShowMoreData] = useState(loadEachTime);
    const showMore = () => {
        if ((showMoreData >= loadEachTime) && (showMoreData <= images.length)) {
            setShowMoreData(showMoreData + loadEachTime);
        }
    };

    const importAll = (r) => {
        return r?.keys()?.map(r);
    }
    useEffect(() => {
        //Runs on every render
        let temps = importAll(require?.context('../../assets/dwoobz/dwuck', false, /\.(png|gif)$/));
        setimages(temps)
    }, []);
    return (
        <>
            <Banner bannerText="DWUCK" />
            <>
                <div className="pinkBg">
                    <div className="container">
                        <div className="py-20 text-xl text-[#cccccc]">
                            <p className="leading-8">
                                This is it! We've located the merge of our Main Cast. Cheetah Baby has tangled them up into
                                some sort of.. Greeting Ducks… Heroes mixed with Villains.
                                <br /><br />
                                This is going to take some time to figure out, but don't count them out yet. These dudes
                                were born with an attitude on a planet that needs constant saving. Fabric of time and space
                                in jeopardy? This is a Tuesday to the inhabitants of this world. This time it will take the
                                entire population to save this calamity! Dreams and Nightmares must work together. Everyone
                                will need everyone to survive this. Give in to the mixture!
                            </p>
                        </div>
                    </div>
                </div>
                <div className="yellowBg">
                    <div className="container p-6">
                        <div className="grid grid-cols-3 md:grid-cols-4 xl:grid-cols-5 gap-5 p-5 bg-[#252525] rounded-xl">
                            {images?.slice(0, showMoreData)?.map((item, index) => (
                                <div key={index}>
                                    <div className="items-center justify-center overflow-hidden rounded-xl bg-[#0a0a0a56]">
                                        <img
                                            src={item}
                                            alt={item}
                                            className=" scale-100 hover:scale-125 ease-in duration-500"
                                        />
                                    </div>
                                </div>
                            ))}
                        </div>

                        {(images.length > showMoreData) ?
                            <>
                                <div className="text-center my-4 p-4">
                                    {images.length > loadEachTime && (
                                        <button type="button" onClick={showMore}
                                            className="button text-xl text-[#232323] p-3 rounded-xl border border-amber-300 bg-gradient-to-t from-indigo-400  via-pink-300 to-red-200">
                                            Show More
                                        </button>
                                    )}
                                </div>
                            </>
                            :
                            ''
                        }
                    </div>
                </div>
            </>
        </>
    )
}
export default DwuckHero;