import { useState, useEffect } from "react";
import Banner from "../../components/Banner";
const DwogHero = () => {

    const [images, setimages] = useState([]);

    const loadEachTime = 20;
    const [showMoreData, setShowMoreData] = useState(loadEachTime);
    const showMore = () => {
        if ((showMoreData >= loadEachTime) && (showMoreData <= images.length)) {
            setShowMoreData(showMoreData + loadEachTime);
        }
    };

    const importAll = (r) => {
        return r?.keys()?.map(r);
    }
    useEffect(() => {
        //Runs on every render
        let temps = importAll(require?.context('../../assets/dwoobz/dwog', false, /\.(png|gif)$/));
        setimages(temps)
    }, []);


    return (
        <>
            <Banner bannerText="DWOG" />
            <>
                <div className="pinkBg">
                    <div className="container">
                        <div className="py-20 text-xl text-[#cccccc]">
                            <p className="leading-8">
                                “DWOG Wishes to join your party!”
                                <br /><br />

                                Cheetah baby has taken the most loyal of our party members and mixed them with a pack of woofs!
                                Locking them in some kind of Dog Shelter.
                                <br /><br />

                                Little did she know that DWOGs live for the ruff days… Even severely impacted and separated,
                                these allies of the dreamworld all share a common goal. They each have something to fight for
                                on this planet and they've chosen to follow you. You can't save the world on your own, or at
                                least it wouldn't be as fun. Inside each dwog is a guardian for this dream that even Cheetah
                                baby couldn't erase. They'll put their life on the line because they believe in you. Now make
                                em proud and help free them so they can join the fight
                            </p>
                        </div>
                    </div>
                </div>
                <div className="yellowBg">
                    <div className="container p-6">
                        <div className="grid grid-cols-3 md:grid-cols-4 xl:grid-cols-5 gap-5 p-5 bg-[#252525] rounded-xl">
                            {images?.slice(0, showMoreData)?.map((item, index) => (
                                <div key={index}>
                                    <div className="items-center justify-center overflow-hidden rounded-xl bg-[#0a0a0a56]">
                                        <img
                                            src={item}
                                            alt={item}
                                            className=" scale-100 hover:scale-125 ease-in duration-500"
                                        />
                                    </div>
                                </div>
                            ))}
                        </div>

                        {(images.length > showMoreData) ?
                            <>
                                <div className="text-center my-4 p-4">
                                    {images.length > loadEachTime && (
                                        <button type="button" onClick={showMore}
                                            className="button text-xl text-[#232323] p-3 rounded-xl border border-amber-300 bg-gradient-to-t from-indigo-400  via-pink-300 to-red-200">
                                            Show More
                                        </button>
                                    )}
                                </div>
                            </>
                            :
                            ''
                        }
                    </div>
                </div>
            </>
        </>
    )
}
export default DwogHero;