import WeepleHero from "./WeepleHero";
import GoToTop from "../../components/GoToTop.jsx";
import DwoobyTopGif from "../../assets/topButton/m-spraito-sell.gif"
const WeepleHome=()=>{
    return(
        <>
        <WeepleHero/>
            <div className=" fixed -right-[0%] top-[88%] z-50 rotate-[270]">
                <GoToTop imageSrc={DwoobyTopGif} />
            </div>
        </>
    )
}
export default WeepleHome;