const PrivacyPolicyDetails = () => {
    return (
        <>
            <div className="min-h-screen text-[#CCC] leading-8">
                <div className="pinkBg ">
                    <div className="container pt-20">
                        <div className="pb-3">
                            DWOPE (“DWOPE,” “we”, “us”, or “our”) is committed to protecting your personal information and your right to privacy. We have prepared this Privacy Notice to describe to you our practices regarding the personal data we collect, use, and share in connection with all software provided on or in connection with our service, as described in our DWOPE Terms (collectively, the “Service”). “NFT” in this Privacy Notice means the DWOOBZ NFT as described in the DWOPE Terms.
                        </div>
                        <ul className="list-disc pl-10 md:pl-20">
                            <li>
                                Data we will never collect. We do not sell, trade, or otherwise transfer to outside parties your personally identifiable information. We will never ask you to share your private key(s) or wallet seed phrase. Never trust anyone or any site that asks you to provide or share your private key(s) or wallet seed phrase.
                            </li>
                            <li>
                                Data we may collect. We may collect personal information that you voluntarily provide to us when you express an interest in obtaining information about us or our service, when you participate in activities on the website or otherwise when you contact us.
                            </li>
                            <li>
                                Public information observed from blockchains. We collect data from activity that is publicly visible and/or accessible on blockchains. This may include blockchain addresses and information regarding purchases, sales, or transfers of nfts, which may then be associated with other data you have provided to us.
                            </li>
                        </ul>
                    </div>
                </div>
                <div className="yellowBg">
                    <div className="container">
                        <ul className="list-disc pl-10 md:pl-20">
                            <li>
                                How data is used. We process your information for purposes based on legitimate business interests, the fulfillment of our contract with you, compliance with our legal obligations, and/or your consent.
                            </li>
                            <li>
                                Third-party wallets. To use our service, you must use a third-party wallet which allows you to engage in transactions on public blockchains. Your interactions with any third-party wallet provider are governed by the applicable terms of service and privacy notice of that third party. You are responsible for complying with those terms. We are not responsible for any malfunction or damage related to your use of any third-party wallet. Third-party websites. Our service may contain links to third-party websites. When you click on a link to any other website or location, you will leave our service and go to another site, and another entity may collect personal data from you. We have no control over and are not responsible for these third-party websites or their content. This privacy notice does not apply to these third-party websites or their content, or to any collection of your personal data after you click on links to such third-party websites. You are responsible for reading the privacy policies of every website you visit. Any links to third-party websites or locations do not indicate or signify our endorsement of such third parties or their products, content, or websites or any related product or service.
                            </li>
                            <li>
                                Legal rights. We may disclose personal data if we believe in good faith that such disclosure is necessary:
                                <ol className="list-[lower-alpha] pl-10 md:pl-20">
                                    <li>in connection with any legal investigation; </li>
                                    <li>to comply with relevant laws or to respond to subpoenas, warrants, or other legal process served on universal page; </li>
                                    <li> to protect or defend the rights or property of dwope or users of the service; and/or </li>
                                    <li> to investigate or assist in preventing any violation or potential violation of the law, this privacy notice, or our terms.</li>
                                </ol>
                            </li>
                            <li>
                                If you are a user in the european economic area or united kingdom, you have certain rights under the respective european and uk general data protection regulations (“gdpr”). If you are a california resident, you have certain rights under the california consumer privacy act (“ccpa”). If you are a non-u.S. User of the service, by visiting the service and providing us with data, you acknowledge and agree that your personal data may be processed for the purposes identified in the privacy notice.
                            </li>
                            <li>
                                Minors. We do not knowingly collect data from or market to minors and in order to use the service you represent that you have the legal capacity to do so in the jurisdiction where you are located.
                            </li>
                            <li>
                                Changes to this privacy notice. This privacy notice may be updated from time to time for any reason. We will notify you of any changes to our privacy notice by posting the new privacy notice at this web page.
                            </li>
                            <li>
                                Contacting us. If you have questions or comments about this notice, you may email us at contact@dwope.Works
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </>
    )
}
export default PrivacyPolicyDetails;