import React from "react";
import FounderBanner from "./FounderBanner";
import FounderSection from "./FounderSection";
import GoToTop from "../components/GoToTop.jsx";
import TeamTopGif from "../assets/topButton/m-spraito-ade.gif";

function FounderHome() {
  return (
    <div>
      <FounderBanner />
      <FounderSection />
      <div className=" fixed -right-[0%] top-[88%] z-50 rotate-[270]">
        <GoToTop imageSrc={TeamTopGif} />
      </div>
    </div>
  );
}

export default FounderHome;
