import React from "react";

import Banner from "../components/Banner";

const DwoobxBanner = () => {
  return (
    <div className="relative">
      <Banner bannerText="DWOOBX" />
    </div>
  );
};

export default DwoobxBanner;
