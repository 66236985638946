import React from "react";
import BG1 from "../assets/backgrounds/DW71-Intro-7-Samurai-7.mp4";

const EndVideo = () => {
  return (
    <div className="mainpage">
      <div className="video-docker absolute left-0 top-0 h-full w-full overflow-hidden">
        <video
          className="absolute min-h-full min-w-full object-cover "
          src={BG1}
          type="video/mp4"
          autoPlay
          muted
          loop
        ></video>
      </div>
      <div className="h-screen w-screen"></div>
    </div>
  );
};

export default EndVideo;
