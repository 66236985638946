import DwoodzBanner from "./DwoodzBanner";
import DwoodzDetails from "./DwoodzDetails"
import GoToTop from "../components/GoToTop.jsx";
import DwoobyTopGif from "../assets/topButton/m-spraito-tuna.gif";
const DwoodzHome = () => {
    return (
        <>
            <DwoodzBanner />
            <DwoodzDetails />
            <div className=" fixed -right-[0%] top-[88%] z-50 rotate-[270]">
                <GoToTop imageSrc={DwoobyTopGif} />
            </div>
        </>
    )
}
export default DwoodzHome;