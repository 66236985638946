import React from "react";
// import Image from "../assets/nft/image6.png";
import Image from "../assets/nft/dreamweave infographic.png";

function WaveSection() {
  return (
    <div>
      <div className="relative">
        <div className="pinkBg absolute left-0 top-0 mt-32 h-full w-full "></div>
        <img className="relative z-10 mx-auto " src={Image} alt="" />
      </div>
    </div>
  );
}

export default WaveSection;
