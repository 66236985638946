import DwoobyBanner from "./DwoobyBanner";
import DwoobyDetails from "./DwoobyDetails";
import DwoobyImage from "./DwoobyImage";
import GoToTop from "../components/GoToTop.jsx";
import DwoobyTopGif from "../assets/topButton/m-spraito-hanu.gif";
 
const Dwooby = () => {
  return (
    <>
      <DwoobyBanner />
      <DwoobyDetails />
      <DwoobyImage />
      <div className=" fixed -right-[0%] top-[88%] z-50 rotate-[270]">
        <GoToTop imageSrc={DwoobyTopGif} />
      </div>
    </>
  );
};
export default Dwooby;
