const BattleCardzDetails = () => {
    return (
        <>
            <div className="pinkBg">
                <div className="container py-20 text-xl md:text-2xl text-[#cccccc]">
                    <div className="leading-8">
                        <div className="text-3xl mb-5 text-[#FFC700]">
                            A dream in a dream in a dream
                        </div>
                        Someday the shards of the save crystal will come back together, and the great Untangling will commence. Everyone will be themselves, and a great victory will be had.
                        <br /><br />
                        <div className="text-3xl mb-5 text-[#FFC700]">
                            The DreamSeed however has an idea of its own…
                        </div>
                        Powered and amped up in its experience and time here absorbing so many dreams of the millions of HODLERS across chains and coins, it has decided.. Only one of these dreams can be granted. Forge your destiny and enter the DreamWave with what you’ve collected. Assemble your party and fight! The truth lies in the heart of battle.
                    </div>
                </div>
            </div>
        </>
    )
}
export default BattleCardzDetails