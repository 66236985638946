import { useState, useEffect } from "react";
import Banner from "../../components/Banner";
const DwagonHero = () => {

    const [images, setimages] = useState([]);

    const loadEachTime = 20;
    const [showMoreData, setShowMoreData] = useState(loadEachTime);
    const showMore = () => {
        if ((showMoreData >= loadEachTime) && (showMoreData <= images.length)) {
            setShowMoreData(showMoreData + loadEachTime);
        }
    };

    const importAll = (r) => {
        return r?.keys()?.map(r);
    }
    useEffect(() => {
        //Runs on every render
        let temps = importAll(require?.context('../../assets/dwoobz/dwagon', false, /\.(png|gif)$/));
        setimages(temps)
    }, []);
    return (
        <>
            <Banner bannerText="DWAGON" />
            <>
                <div className="pinkBg">
                    <div className="container">
                        <div className="py-20 text-xl text-[#cccccc]">
                            <p className="leading-8">
                                Summon me and I will bring you great victories over the imp. For too long have we let her
                                play such games with our realm. Trapped inside us are powerful gods and relics that could
                                aid you on your journey. Set us free…
                                <br /><br />
                                Cheetah Baby's magic seems to have had less of an effect on the Eternal Beings of the universe..
                                During the phase into the blockchain they shielded themselves in energy to keep their data from
                                splitting into thousands of pieces like the lesser beings. Still damaged and part of her game,
                                hey seek revenge and a way to limit her power. All powerful, but still at the mercy of those
                                weaker than themselves. Let them lend you their power. Change the rules.
                                <br /><br />
                                Release The DWAGONs!
                            </p>
                        </div>
                    </div>
                </div>
                <div className="yellowBg">
                    <div className="container p-6">
                        <div className="grid grid-cols-3 md:grid-cols-4 xl:grid-cols-5 gap-5 p-5 bg-[#252525] rounded-xl">
                            {images?.slice(0, showMoreData)?.map((item, index) => (
                                <div key={index}>
                                    <div className="items-center justify-center overflow-hidden rounded-xl bg-[#0a0a0a56]">
                                        <img
                                            src={item}
                                            alt={item}
                                            className=" scale-100 hover:scale-125 ease-in duration-500"
                                        />
                                    </div>
                                </div>
                            ))}
                        </div>

                        {(images.length > showMoreData) ?
                            <>
                                <div className="text-center my-4 p-4">
                                    {images.length > loadEachTime && (
                                        <button type="button" onClick={showMore}
                                            className="button text-xl text-[#232323] p-3 rounded-xl border border-amber-300 bg-gradient-to-t from-indigo-400  via-pink-300 to-red-200">
                                            Show More
                                        </button>
                                    )}
                                </div>
                            </>
                            :
                            ''
                        }
                    </div>
                </div>
            </>
        </>
    )
}
export default DwagonHero;