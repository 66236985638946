import React from "react";
import BG1 from "../assets/backgrounds/New_Pearl_Texture_2Loop_Inverted.webm";
import Poster from "../assets/backgrounds/thumbnail.webp"

const Banner = ({ bannerText, BannerTextSub }) => {
  return (
    <div className="mainpage">
      <div className="video-docker fixed left-0 top-0 h-full w-full overflow-hidden">
        <video
          className="absolute min-h-full min-w-full object-cover"
          src={BG1}
          type="video/webm"
          autoPlay
          muted
          loop
        ></video>
      </div>
      <div className="relative py-40 text-center ">
        <h1 className="text-5xl text-[#cccccc] md:text-8xl">
          {bannerText}
        </h1>
        <h4 className="text-3xl text-[#cccccc] md:text-5xl">{BannerTextSub}</h4>
      </div>
    </div>
  );
};

export default Banner;
