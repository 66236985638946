import React from "react";

const DwoobxDetails = () => {
  return (
    <div className="pinkBg">
      <div className="container py-20 text-xl text-[#cccccc]">
        <div className="leading-8">
          For fun Cheetah baby would cast Chibi status on all the beings of the
          DreamWorld and laugh as they ran to look for the Cookie item that would
          heal them. Realizing as “chibis” they shared magic in their veins
          similar to the imp. They then came up with a plan to delve deeper..
          <br /> <br />
          “it was like a vaccuum of souls entering a tube of
          infinite technological wonders” The X = The Unknown.
          <br /> <br />
          As cheetah baby laughed and giggled every time they tried
          to stop her games she paused for a moment when she saw the characters
          take her game even further.. Racing off into the X of unknown, a portal
          of mirrors showed up. Taking their reflections and shooting them off
          into another plane. Using the imps magic to make infinite multiple
          versions of themselves in different universes.
          <br /> <br />
          These cute sprite versions are now cruising at the top of
          all multiverses.. but unfortunate for their counterparts, they cant stop
          acting like kids and their attention span is fixated on eating
          cookies…May the Dream Fairies help us…
        </div>
      </div>
    </div>

  );
};

export default DwoobxDetails;
