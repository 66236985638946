import React, { useState, useEffect } from "react";
import Banner from "../../components/Banner";
const DeepleHero = () => {

    const [images, setimages] = useState([]);
    const loadEachTime = 20;
    const [showMoreData, setShowMoreData] = useState(loadEachTime);
    const showMore = () => {
        if ((showMoreData >= loadEachTime) && (showMoreData <= images.length)) {
            setShowMoreData(showMoreData + loadEachTime);
        }
    };

    const importAll = (r) => {
        return r?.keys()?.map(r);
    }
    useEffect(() => {
        //Runs on every render
        let temps = importAll(require?.context('../../assets/dwoobz/deeple', false, /\.(png|gif)$/));
        setimages(temps)
    }, []);
    return (
        <>
            <Banner bannerText="DEEPLE" />
            <>
                <div className="pinkBg">
                    <div className="container">
                        <div className="py-20 text-xl text-[#cccccc]">
                            <p className="leading-8">
                                Strange inhabitants of the DreamWorld have appeared. Normal when compared to the rest, but
                                underneath lies something far more wondrous. The DreamWaves frequencies are loud and the
                                noise is thick in-between. Dimensional planes that once ruled the universe are being bent
                                and some broken…
                                <br /><br />
                                Into the deep with the Deeple! The vessels of the chaotic noise of creation have shattered
                                over the DreamWorld and its crash into the Cryptoverse. The Peeple are here and with them
                                comes a different vision of the dream…
                                <br /><br />
                                Catch the shattering noise of these interdimensional tourist and see dreams evolve…
                            </p>
                        </div>
                    </div>
                </div>
                <div className="yellowBg">
                    <div className="container p-6">
                        <div className="grid grid-cols-3 md:grid-cols-4 xl:grid-cols-5 gap-5 p-5 bg-[#252525] rounded-xl">
                            {images?.slice(0, showMoreData)?.map((item, index) => (
                                <div key={index}>
                                    <div className="items-center justify-center overflow-hidden rounded-xl bg-[#0a0a0a56]">
                                        <img
                                            src={item}
                                            alt={item}
                                            className=" scale-100 hover:scale-125 ease-in duration-500"
                                        />
                                    </div>
                                </div>
                            ))}
                        </div>

                        {(images.length > showMoreData) ?
                            <>
                                <div className="text-center my-4 p-4">
                                    {images.length > loadEachTime && (
                                        <button type="button" onClick={showMore}
                                            className="button text-xl text-[#232323] p-3 rounded-xl border border-amber-300 bg-gradient-to-t from-indigo-400  via-pink-300 to-red-200">
                                            Show More
                                        </button>
                                    )}
                                </div>
                            </>
                            :
                            ''
                        }
                    </div>
                </div>
            </>
        </>
    )
}
export default DeepleHero;