import React from "react";
import card1 from "../assets/DWOOBX/Controllers.gif";
import card2 from "../assets/DWOOBX/Players.gif";
import card3 from "../assets/DWOOBX/Assists.gif";
import { NavLink, Outlet } from "react-router-dom";

function DwoobxSections() {
  const Nav = [
    {
      id: 1,
      img: card1,
      name: "Controllers",
      path: "controllers",
    },
    {
      id: 2,
      img: card2,
      name: "Players",
      path: "players",
    },
    {
      id: 3,
      img: card3,
      name: "Assists",
      path: "assists",
    },
  ];
  return (
    <div className="blueBg">
      <div className="container">
        <div className="flex flex-col items-center justify-center gap-5 md:flex-row">
          {Nav.map((item) => (
            <NavLink
              to={item.path}
              key={item.id}
              className={({ isActive }) =>
                isActive
                  ? "flex animate-text flex-col bg-gradient-to-r from-[#B9C3CA] via-[#9FCCD6] to-[#BDC0D1] bg-clip-text text-transparent "
                  : "flex-col text-white"
              }
            >
              <div className="flex w-40 md:w-60 flex-col items-center">
                <div>
                  <p className="text-[#cccccc]">{item.name}</p>
                </div>
                <div className="overflow-hidden rounded-xl">
                  <img
                    className="h-52 w-52 rounded-xl object-contain scale-100 hover:scale-125 ease-in duration-500"
                    src={item.img}
                    alt="card1"
                  />
                </div>
                <NavLink
                  className={({ isActive }) =>
                    isActive
                      ? "mx-auto mt-2 h-2 w-14 animate-text rounded bg-gradient-to-r from-[#B9C3CA] via-[#9FCCD6] to-[#BDC0D1] md:w-20"
                      : "hidden"
                  }
                  to={item.path}
                  key={item.id}
                ></NavLink>
              </div>
            </NavLink>
          ))}
        </div>
        <Outlet />
      </div>
    </div>
  );
}

export default DwoobxSections;
