import PrivacyPolicyBanner from "./PrivacyPolicyBanner";
import PrivacyPolicyDetails from "./PrivacyPolicyDetails";
import GoToTop from "../components/GoToTop.jsx";
import PrivacyTopGif from "../assets/topButton/m-spraito-gobbo.gif";

const PrivacyPolicyHome = () => {
  return (
    <>
      <PrivacyPolicyBanner />
      <PrivacyPolicyDetails />
      <div className=" fixed -right-[0%] top-[88%] z-50 rotate-[270]">
        <GoToTop imageSrc={PrivacyTopGif} />
      </div>
    </>
  );
};
export default PrivacyPolicyHome;
