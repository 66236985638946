const TermAndConditionDetails = () => {
    return (
        <>
            <div className="min-h-screen text-[#CCC] leading-8">
                <div className="pinkBg">
                    <div className="container pt-20">
                        <p>
                            DWOOBZ is a collection of one thousand nine hundreds seventy nine digital artworks (the “NFTs”) secured by the
                            LUKSO & Kaspa Networks. Each NFT is minted by a smart contract deployed to the LUKSO & Kaspa Blockchains. These terms are a legally
                            binding agreement between DWOPE (“DWOPE”), a Catawba Digital Economic Zone LLC, (“we” or “us”), and any lawful owner of a DWOOBZ
                            NFT (“you” or “owner”) (the “Terms”). DWOPE offers this platform and all information, tools, and services available
                            from this platform to you, the user, conditioned upon your acceptance of all terms, conditions, policies, and
                            notices stated here. When you purchase the NFT, you own the underlying DWOOBZ (the “Art”). Except for the
                            DWOOBZ we own, we have no right or ability to freeze, seize, or burn any DWOOBZ NFT. Once a DWOOBZ is
                            purchased by you, there is no ability to undo, reverse, or restore any transactions.By agreeing to these Terms,
                            you acknowledge that you understand the unique risks associated with holding, using, and trading digital assets
                            such as NFTs.
                        </p>
                        <div className="pt-10 text-2xl">DWOPE Licensing</div>
                        <div>
                            <div className="text-xl pt-6">Ownership.</div>
                            When you purchase the NFT, you own the underlying DWOOBZ. Your ownership is recorded by the transaction
                            on the DWOOBZ smart contract. Your ownership will not be modified by us at any time in any universe.
                        </div>
                        <div className="text-xl pt-6">Permitted Uses.</div>
                        <p>
                            DWOPE grants you a worldwide, royalty-free license to use, copy, and display in any form the purchased Art
                            exclusively for the following purposes.
                        </p>
                    </div>
                </div>
                <div className="yellowBg">
                    <div className="container">
                        <ol className="list-decimal pl-10 md:pl-20">
                            <li>personal non-commercial use;</li>
                            <li>commercial use which allows for the use, copy, and display of the purchased DWOOBZ NFT for the purposes of creating unrestricted and unlimited derivative works based upon the Art which allow for the earning of revenue;</li>
                            <li>as part of a marketplace that permits the purchase and sale of your DWOOBZ NFT; or</li>
                            <li>as part of a third party website or application, centralized or decentralized, that allows for the display or participation of your DWOOBZ, only if the website or application cryptographically verify each DWOOBZ owner’s rights to display the Art for their DWOOBZ to ensure that only the actual owner can display the Art.</li>
                        </ol>
                        <div className="text-xl pt-6">Marketplace Sales. </div>
                        <p>
                            Your use, copy, and display of the Art on a marketplace that allows for the purchase and sale of your DWOOBZ NFT are permitted only if the marketplace cryptographically verifies each owner’s rights display sell the DWOOBZ NFT in order to ensure that it is being displayed and sold by the actual lawful owner.
                        </p>

                        <div className="text-xl pt-6">License Term.</div>
                        <p>
                            The license term is the duration during which you hold, according to the data recorded on the DWOOBZ smart contract, the DWOOBZ NFT (the “License Term”). The license is intended to be broad to allow you to publicly display and use the Art. The license covers all forms of media and commercial uses, both digital and non-digital.
                        </p>
                        <div className="text-xl pt-6">Termination of License. </div>
                        <p>
                            Your license is immediately and automatically terminated upon your blockchain-based transfer of your DWOOBZ NFT to another owner as recorded on the DWOOBZ smart contract. You must immediately discontinue any use of your DWOOBZ Art as a trademark and any trademark or registration which you hold in connection with the license shall no longer be valid or shall be transferred to the new owner in writing in compliance with applicable law.
                        </p>

                        <div className="text-xl pt-6">DWOPE Right to Display.
                            <p>
                                You grant DWOPE the irrevocable, exclusive and royalty-free, sublicensable license to publicly display and use in any manner your DWOOBZ Art in conjunction with other DWOOBZ NFTs for the purpose of exhibiting the entire DWOOBZ collection and for the purpose of promoting the DWOOBZ project.
                            </p>
                        </div>

                    </div>
                </div>
                <div className="blueBgRightBottom">
                    <div className="container">
                        <div className="text-xl pt-6">Restrictions.</div>
                        <ol className="list-decimal pl-10 md:pl-20">
                            <li>All rights not expressly granted are reserved by us.</li>
                            <li>Any application to register a trademark of your DWOOBZ Art must occur during the License Term. The application must be based only upon your actual use in commerce of the DWOOBZ Art and not on an “intent to use” basis.</li>
                            <li>If during the license term you create and provide to the public a work using your DWOOBZ art, you can continue to use the published work in accordance with the terms and will be responsible for any claims, damages, obligations or liabilities arising from your continued use of the publicly available work after the expiration of the license.</li>
                            <li>Purchasers may not use the DWOOBZ Art in a manner that expresses hate or encourages violence towards a person or group based on protected classes.</li>
                        </ol>
                        <div className="text-xl pt-6">Purchaser Representations and Warranties. </div>
                        <div>
                            You represent and warrant that:
                            <ol className="list-[upper-roman] pl-10 md:pl-20">
                                <li>you are not a child or robot and have the legal capacity to enter into these Terms,  </li>
                                <li> you will act in accordance to these Terms in any use or display of your DWOOBZ NFT, </li>
                                <li>you will comply with all applicable laws and acknowledge that you understand the law surrounding NFTs may change and that NFTs may become restrictively regulated products in your jurisdiction, and </li>
                                <li> you acknowledge and understand that dealing in digital assets such as NFTs can incur a risk of financial loss.</li>
                            </ol>
                            You represent and warrant that you have no expectation of profit derived from the purchase of the Art and that you acknowledge and understand that DWOPE will not take any actions, either directly or indirectly, intended to increase the financial value of the Art of any associated product derived from the DWOOBZ NFT, including third-party products.

                        </div>

                        <div className="text-xl pt-6">DWOPE Warranty Disclaimers.</div>
                        <div>
                            <ol className="list-decimal pl-10 md:pl-20">
                                <li>We will not take any actions to promote or increase the value of DWOOBZ NFTs.</li>
                                <li> We are not responsible for any claim arising out of the use or display of the DWOOBZ Art offered by any third party or for the acts or omissions of any third party.</li>
                                <li> We are not responsible for any loss of any kind and are not liable to any purchaser of any of DWOOBZ NFT including, but not limited to:
                                    <ol className="list-[lower-alpha] pl-10 md:pl-20">
                                        <li> loss due to errors relating to wallet addresses,  </li>
                                        <li> loss due to forgotten passwords or lost private keys and or  </li>
                                        <li> loss due to technical errors on the blockchain or software or hardware issues. </li>


                                    </ol>
                                </li>
                            </ol>
                        </div>
                        <div className="text-xl pt-6">Notice of Arbitration and Class Action Waiver.</div>
                        <p>
                            You waive the right to participate in class-action or class-wide arbitration. You agree and understand that any controversy, claim, or dispute arising out of or relating to terms of this agreement shall be settled solely and exclusively by binding arbitration held in the Marshall Islands and conducted in English, rather than in court, pursuant to the Arbitration Act 1980 [30 MIRC Ch 3]. You agree that this arbitration provision applies not just to disputes with DWOPE but also to disputes with any other party named or added as a co-defendant along with DWOPE at any time during a court action. Any such co-defendant or defendant is a third-party beneficiary entitled to enforce this arbitration provision. Each side agrees to bear its own attorney’s fees, costs, and expenses.

                        </p>
                        <div className="text-xl pt-6">You agree to keep any arbitration strictly confidential.</div>
                        <div className="text-xl pt-6">Governing Law and Forum Choice.</div>
                        <p>
                            All actions arising out of the terms of this agreement or relating to the terms of this agreement shall be governed by the laws of the Marshall Islands.
                        </p>
                        <div className="text-xl pt-6">Disputes.</div>
                        <p>
                            We have no obligation to intervene in any capacity in any dispute, including internet trolling or Twitter arguments about the future of NFTs, which may arise between DWOOBZ owners regardless of the basis of the cause of action in any worldwide jurisdiction.
                        </p>
                        <div className="text-xl pt-6">Risks Related to NFTs Generally.</div>
                        <p>
                            Different jurisdictions may treat NFTs differently, and the cross-border nature of the blockchain and of NFTs may make them subject to the laws of various jurisdictions. You must always make sure that any use you make of any NFT is compliant with all applicable laws.
                        </p>
                        <p>In the absence of regulatory clarity, there is a risk that the NFTs may be viewed as a security, financial instrument, specified investment, or other regulated instruments. In any such event, the NFTs may not be offered or sold except pursuant to an exemption from, or a transaction not subject to, the applicable registration requirements of the applicable Laws. These restrictions may limit the transferability, value and liquidity of the NFTs. DWOOBZ does not intend or undertake to register the NFTs for trading on any securities exchange.

                        </p>
                        <div className="text-xl pt-6">Risk of Loss.</div>
                        <p>
                            Any third party gaining access to your digital wallet can extract your NFT, and you may not be able to identify or find such parties. Never provide any person with your wallet’s private keys or backup phrases.
                        </p>
                        <div className="text-xl pt-6">Limitation of Liability.</div>
                        <p>
                            TO THE MAXIMUM EXTENT PERMITTED BY LAW, NEITHER DWOPE NOR ITS SERVICE PROVIDERS INVOLVED IN CREATING, PRODUCING, OR DELIVERING THE PLATFORM WILL BE LIABLE FOR ANY INCIDENTAL, SPECIAL, EXEMPLARY, OR CONSEQUENTIAL DAMAGES, OR DAMAGES FOR LOST PROFITS, LOST REVENUES, LOST SAVINGS, LOST BUSINESS OPPORTUNITY, LOSS OF DATA OR GOODWILL, SERVICE INTERRUPTION, COMPUTER DAMAGE OR SYSTEM FAILURE, OR THE COST OF SUBSTITUTE SERVICES OF ANY KIND ARISING OUT OF OR IN CONNECTION WITH THESE TERMS OR FROM THE USE OF OR INABILITY TO USE THE SITE, WHETHER BASED ON WARRANTY, CONTRACT, TORT (INCLUDING NEGLIGENCE), PRODUCT LIABILITY, OR ANY OTHER LEGAL THEORY, AND WHETHER OR NOT DWOPE OR ITS SERVICE PROVIDERS HAVE BEEN INFORMED OF THE POSSIBILITY OF SUCH DAMAGE, EVEN IF A LIMITED REMEDY SET FORTH HEREIN IS FOUND TO HAVE FAILED OF ITS ESSENTIAL PURPOSE.
                        </p>
                        <div className="text-xl pt-6">Entire Agreement. </div>
                        <p>
                            These Terms constitute the entire and exclusive understanding and agreement between DWOPE and you regarding the use of the platform, and these Terms supersede and replace all prior oral or written understandings or agreements between DWOPE and you regarding the platform and its services. If any provision of these Terms is held invalid or unenforceable, that provision will be enforced to the maximum extent permissible and the other provisions of these Terms will remain in full force and effect.
                        </p>
                    </div>
                </div>
            </div>

        </>
    )
}
export default TermAndConditionDetails;