import Hero from "./Hero.jsx";
import AboutDwope from "./AboutDwope.jsx";
import Category from "./Category.jsx";
import FAQ from "./FAQ.jsx";
import EndVideo from "./EndVideo.jsx";
import GoToTop from "../components/GoToTop.jsx";
import HomeTopGif from "../assets/topButton/m-spraito-bobbo.gif";

const Home = () => {
  return (
    <div>
      <Hero />
      <AboutDwope />
      <Category />
      <FAQ />
      <EndVideo />

      <div className=" fixed -right-[0%] top-[88%] z-50 rotate-[270]">
        <GoToTop imageSrc={HomeTopGif} />
      </div>
    </div>
  );
};

export default Home;
