import React, { useState } from "react";
// import players from "../data/PlayersData";
import Modal from "@mui/material/Modal";
import { DwoobxModalDataPlayers } from "../data/NFTModalData";

function Player() {
  const [itemNumber, setItemNumber] = useState();
  const [navModalOpen, setNavModalOpen] = useState(false);

  const loadEachTime = 20;
  const [showMoreData, setShowMoreData] = useState(loadEachTime);
  const showMore = () => {
    if ((showMoreData >= loadEachTime) && (showMoreData <= DwoobxModalDataPlayers.length)) {
      setShowMoreData(showMoreData + loadEachTime);
    }
  };

  const navModalhandler = (type) => {
    setItemNumber(type)
    setNavModalOpen(true)
  }

  const navModalhandleClose = () => {
    setItemNumber('')
    setNavModalOpen(false)
  }
  // const [images, setimages] = useState([]);
  // const loadEachTime = 20;
  // const [showMoreData, setShowMoreData] = useState(loadEachTime);
  // const showMore = () => {
  //   if ((showMoreData >= loadEachTime) && (showMoreData <= images.length)) {
  //     setShowMoreData(showMoreData + loadEachTime);
  //   }
  // };
  // const importAll = (r) => {
  //   return r?.keys()?.map(r);
  // }
  // useEffect(() => {
  //   //Runs on every render
  //   let temps = importAll(require?.context('../assets/Final_NFT_Images/DWOOBX/Players', false, /\.(png|gif)$/));
  //   setimages(temps)
  // }, []);

  return (
    <>
      <div id="sections" className="py-8">
        <div className="bg-[#1919197a] p-8">
          <div className="cards grid grid-cols-2 gap-5 md:grid-cols-4 xl:grid-cols-5">
            {DwoobxModalDataPlayers?.slice(0, showMoreData)?.map((item, index) =>
              <button onClick={() => navModalhandler(index + 1)} type="button" className='card h-[100%] rounded-xl bg-[#0a0a0a56] py-4 overflow-hidden' key={index}>
                <div className="overflow-hidden rounded-xl">
                  <img src={item?.Filename} alt={item?.Numbering} className=" scale-100 hover:scale-125 ease-in duration-500" />
                </div>
                {/* <p className='p-2 text-center text-[#cccccc]'># {index+1} {item.Numbering}</p> */}
              </button>
            )}
          </div>

          {(DwoobxModalDataPlayers.length > showMoreData) ?
            <>
              <div className="text-center my-4 p-4">
                {DwoobxModalDataPlayers.length > loadEachTime && (
                  <button type="button" onClick={showMore}
                    className="button text-xl text-[#232323] p-3 rounded-xl border border-amber-300 bg-gradient-to-t from-indigo-400  via-pink-300 to-red-200">
                    Show More
                  </button>
                )}
              </div>
            </>
            :
            ''
          }
        </div>
      </div>
      {/* Modal Start here */}
      <Modal
        open={navModalOpen}
        onClose={navModalhandleClose}
        aria-labelledby="nav-modal"
        aria-describedby="nav-modal"
      >
        <>
          <div className="p-4 w-full md:w-2/5 max-h-3/4 justify-center items-center text-[#cccccc] position absolute top-1/2 left-1/2  rounded-2xl -translate-x-1/2 -translate-y-1/2 bg-[#242424] border border-[#111]">
            <h1 className="mb-3 text-xl md:text-3xl font-[800] text-center">
              {DwoobxModalDataPlayers[itemNumber - 1]?.Name}
            </h1>
            <div className="">
              <img src={DwoobxModalDataPlayers[itemNumber - 1]?.Filename} alt={DwoobxModalDataPlayers[itemNumber - 1]?.Name} className="w-fit h-3/4" />
            </div>
            <div className="text-[12px] md:text-[25px] font-[Jost] text-center">
              <span className="leading-1 md:leading-8">
                {DwoobxModalDataPlayers[itemNumber - 1]?.DreamDex}
              </span>
            </div>
          </div>
        </>
      </Modal>
      {/* Modal end here */}
    </>
  );
}

export default Player;
