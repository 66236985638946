import React, { useState } from "react";
import Modal from "@mui/material/Modal";
import { DwoobYModalData } from "../data/NFTModalData";

const DwoobyImage = () => {
    const [itemNumber, setItemNumber] = useState();
    const loadEachTime = 20;
    const [showMoreData, setShowMoreData] = useState(loadEachTime);
    const [navModalOpen, setNavModalOpen] = useState(false);

    const showMore = () => {
        if ((showMoreData >= loadEachTime) && (showMoreData <= DwoobYModalData.length)) {
            setShowMoreData(showMoreData + loadEachTime);
        }
    };

    // const importAll = (r) => {
    //     return r?.keys()?.map(r);
    // }

    const navModalhandler = (type) => {
        setItemNumber(type)
        setNavModalOpen(true)
    }

    const navModalhandleClose = () => {
        setItemNumber('')
        setNavModalOpen(false)
    }


    // useEffect(() => {
    //     //Runs on every render
    //     let temps = importAll(require?.context('../assets/Final_NFT_Images/DWOOBY', false, /\.(png|gif)$/));
    //     setimages(temps)
    // }, []);

    return (
        <>
            <div className="blueBg">
                <div className='bg-[#26262680] p-8'>
                    <div className="container">
                        <div className='mx-auto cards grid grid-cols-3 md:grid-cols-4 xl:grid-cols-5 gap-4'>
                            {DwoobYModalData?.slice(0, showMoreData)?.map((item, index) =>
                                <button onClick={() => navModalhandler(index + 1)} type="button" className='card h-[100%] rounded-xl bg-[#0a0a0a56] py-4 overflow-hidden' key={index}>
                                    <div className="overflow-hidden rounded-xl">
                                        <img src={item?.Filename} alt={item?.Numbering} className=" scale-100 hover:scale-125 ease-in duration-500" />
                                    </div>
                                    {/* <p className='p-2 text-center text-[#cccccc]'># {index+1} {item.Numbering}</p> */}
                                </button>
                            )}
                        </div>
                        {(DwoobYModalData.length > showMoreData) ?
                            <>
                                <div className="text-center my-4 p-4">
                                    {DwoobYModalData.length > loadEachTime && (
                                        <button type="button" onClick={showMore}
                                            className="button text-xl text-[#232323] p-3 rounded-xl border border-amber-300 bg-gradient-to-t from-indigo-400  via-pink-300 to-red-200">
                                            Show More
                                        </button>
                                    )}
                                </div>
                            </>
                            :
                            ''
                        }
                    </div>
                </div>
            </div>
            
            <Modal
                open={navModalOpen}
                onClose={navModalhandleClose}
                aria-labelledby="nav-modal"
                aria-describedby="nav-modal"
            >
                <div className="p-4 w-full md:w-fit md:max-h-4/5 justify-center items-center text-[#cccccc] position absolute top-1/2 left-1/2 transform rounded-2xl -translate-x-1/2 -translate-y-1/2 bg-[#242424] border border-[#111]">
                    <h1 className="mb-3 text-xl font-[800] text-center">DreamDex</h1>
                    {/* {DwoobYModalData[itemNumber-1].Name} */}
                    <div className="grid grid-cols-2 h-max md:grid-cols-2">
                        <div className="items-center">
                            <img src={DwoobYModalData[itemNumber - 1]?.Filename} alt={DwoobYModalData[itemNumber - 1]?.Name} className="w-full h-fit" />
                        </div>
                        <div className="px-2 md:px-3 text-[13px] md:text-[35px] font-[Jost]">
                            <span className="leading-1 md:leading-[2rem]">
                            {DwoobYModalData[itemNumber - 1]?.DreamDex}
                            </span>
                        </div>
                    </div>
                    <div>
                        <div className="text-xl md:text-3xl font-bold text-center w-1/2">{DwoobYModalData[itemNumber - 1]?.Name}</div>
                    </div>
                </div>
            </Modal>
        </>
    )
}
export default DwoobyImage