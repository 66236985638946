import BattleCardzBanner from "./BattleCardzBanner";
import BattleCardzDetails from "./BattleCardzDetails";
import BattleCardzImage from "./BattleCardzImage";
import GoToTop from "../components/GoToTop.jsx";
import BattleTopGif from "../assets/topButton/m-spraito-kalo.gif";

const BattleCardzHome = () => {
  return (
    <>
      <BattleCardzBanner />
      <BattleCardzDetails />
      <BattleCardzImage />
      <div className=" fixed -right-[0%] top-[88%] z-50 rotate-[270]">
        <GoToTop imageSrc={BattleTopGif} />
      </div>
    </>
  );
};
export default BattleCardzHome;
