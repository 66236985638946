import { NavLink } from "react-router-dom";
import DwoobzTopImage from "../data/DwoobzData";

const DwoodzDetails = () => {
    return (
        <>
            <div className="pinkBg relative min-h-screen">
                <div className="absolute left-0 top-0 -mt-20 h-full w-full">
                    <div className=" container flex w-full items-start justify-around gap-5 max-md:flex-wrap">
                        {DwoobzTopImage.map((item, index) => (
                            <div key={index}>
                                <NavLink to={item.link} duration={500}>
                                    <div className="relative flex flex-col items-center justify-center overflow-hidden rounded-xl bg-[#0a0a0a56]">
                                        <div className="overflow-hidden rounded-xl">
                                            <img
                                                src={item.image}
                                                alt={item.name}
                                                className=" h-24 w-24 sm:h-28 sm:w-28  md:h-44 md:w-44 scale-100 hover:scale-125 ease-in duration-500"
                                            />
                                        </div>
                                        <div className="py-2">
                                            {item.name}
                                        </div>
                                    </div>
                                </NavLink>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </>
    );
};

export default DwoodzDetails;
