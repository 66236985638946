import Dwoobx0001 from '../assets/New_NFT/DWOOBx/Gifs/gbcc1.gif'
import Dwoobx0002 from '../assets/New_NFT/DWOOBx/Gifs/gbcc2.gif'
import Dwoobx0003 from '../assets/New_NFT/DWOOBx/Gifs/gbcc3.gif'
import Dwoobx0004 from '../assets/New_NFT/DWOOBx/Gifs/gbcc4.gif'
import Dwoobx0005 from '../assets/New_NFT/DWOOBx/Gifs/gbcc5.gif'
import Dwoobx0006 from '../assets/New_NFT/DWOOBx/Gifs/gbcc6.gif'
import Dwoobx0007 from '../assets/New_NFT/DWOOBx/Gifs/gbcc7.gif'
import Dwoobx0008 from '../assets/New_NFT/DWOOBx/Gifs/gbcc8.gif'
import Dwoobx0009 from '../assets/New_NFT/DWOOBx/Gifs/gbcc9.gif'
import Dwoobx0010 from '../assets/New_NFT/DWOOBx/Gifs/gbcc10.gif'
import Dwoobx0011 from '../assets/New_NFT/DWOOBx/Gifs/gbcc11.gif'
import Dwoobx0012 from '../assets/New_NFT/DWOOBx/Gifs/gbcc12.gif'
import Dwoobx0013 from '../assets/New_NFT/DWOOBx/Gifs/gbcc13.gif'
import Dwoobx0014 from '../assets/New_NFT/DWOOBx/Gifs/gbcc14.gif'
import Dwoobx0015 from '../assets/New_NFT/DWOOBx/Gifs/gbcc15.gif'
import Dwoobx0016 from '../assets/New_NFT/DWOOBx/Gifs/gbcc16.gif'
import Dwoobx0017 from '../assets/New_NFT/DWOOBx/Gifs/gbcca.gif'
import Dwoobx0018 from '../assets/New_NFT/DWOOBx/Gifs/gbccb.gif'
import Dwoobx0019 from '../assets/New_NFT/DWOOBx/Gifs/gbccc.gif'
import Dwoobx0020 from '../assets/New_NFT/DWOOBx/Gifs/gbccd.gif'
import Dwoobx0021 from '../assets/New_NFT/DWOOBx/Gifs/gbcce.gif'
import Dwoobx0022 from '../assets/New_NFT/DWOOBx/Gifs/gbccf.gif'
import Dwoobx0023 from '../assets/New_NFT/DWOOBx/Gifs/gbccg.gif'
import Dwoobx0024 from '../assets/New_NFT/DWOOBx/Gifs/gbcch.gif'
import Dwoobx0025 from '../assets/New_NFT/DWOOBx/Gifs/gbcci.gif'
import Dwoobx0026 from '../assets/New_NFT/DWOOBx/Gifs/gbccj.gif'
import Dwoobx0027 from '../assets/New_NFT/DWOOBx/Gifs/gbcp1.gif'
import Dwoobx0028 from '../assets/New_NFT/DWOOBx/Gifs/gbcp2.gif'
import Dwoobx0029 from '../assets/New_NFT/DWOOBx/Gifs/gbcp3.gif'
import Dwoobx0030 from '../assets/New_NFT/DWOOBx/Gifs/gbcp4.gif'
import Dwoobx0031 from '../assets/New_NFT/DWOOBx/Gifs/gbcp5.gif'
import Dwoobx0032 from '../assets/New_NFT/DWOOBx/Gifs/gbcp6.gif'
import Dwoobx0033 from '../assets/New_NFT/DWOOBx/Gifs/gbcp7.gif'
import Dwoobx0034 from '../assets/New_NFT/DWOOBx/Gifs/gbcp8.gif'
import Dwoobx0035 from '../assets/New_NFT/DWOOBx/Gifs/gbcp9.gif'
import Dwoobx0036 from '../assets/New_NFT/DWOOBx/Gifs/gbcp10.gif'
import Dwoobx0037 from '../assets/New_NFT/DWOOBx/Gifs/gbcp11.gif'
import Dwoobx0038 from '../assets/New_NFT/DWOOBx/Gifs/gbcp12.gif'
import Dwoobx0039 from '../assets/New_NFT/DWOOBx/Gifs/gbcp13.gif'
import Dwoobx0040 from '../assets/New_NFT/DWOOBx/Gifs/gbcp14.gif'
import Dwoobx0041 from '../assets/New_NFT/DWOOBx/Gifs/gbcp15.gif'
import Dwoobx0042 from '../assets/New_NFT/DWOOBx/Gifs/gbcp16.gif'
import Dwoobx0043 from '../assets/New_NFT/DWOOBx/Gifs/gbcp17.gif'
import Dwoobx0044 from '../assets/New_NFT/DWOOBx/Gifs/gbcp18.gif'
import Dwoobx0045 from '../assets/New_NFT/DWOOBx/Gifs/gbcp19.gif'
import Dwoobx0046 from '../assets/New_NFT/DWOOBx/Gifs/gbcp20.gif'
import Dwoobx0047 from '../assets/New_NFT/DWOOBx/Gifs/gbcp21.gif'
import Dwoobx0048 from '../assets/New_NFT/DWOOBx/Gifs/gbcp22.gif'
import Dwoobx0049 from '../assets/New_NFT/DWOOBx/Gifs/gbcp23.gif'
import Dwoobx0050 from '../assets/New_NFT/DWOOBx/Gifs/gbcp24.gif'
import Dwoobx0051 from '../assets/New_NFT/DWOOBx/Gifs/gbcp25.gif'
import Dwoobx0052 from '../assets/New_NFT/DWOOBx/Gifs/gbcp26.gif'
import Dwoobx0053 from '../assets/New_NFT/DWOOBx/Gifs/gbcp27.gif'
import Dwoobx0054 from '../assets/New_NFT/DWOOBx/Gifs/gbcp28.gif'
import Dwoobx0055 from '../assets/New_NFT/DWOOBx/Gifs/gbcp29.gif'
import Dwoobx0056 from '../assets/New_NFT/DWOOBx/Gifs/gbcp30.gif'
import Dwoobx0057 from '../assets/New_NFT/DWOOBx/Gifs/gbcp31.gif'
import Dwoobx0058 from '../assets/New_NFT/DWOOBx/Gifs/gbcp32.gif'
import Dwoobx0059 from '../assets/New_NFT/DWOOBx/Gifs/gbcp33.gif'
import Dwoobx0060 from '../assets/New_NFT/DWOOBx/Gifs/gbcp34.gif'
import Dwoobx0061 from '../assets/New_NFT/DWOOBx/Gifs/gbcp35.gif'
import Dwoobx0062 from '../assets/New_NFT/DWOOBx/Gifs/gbcp36.gif'
import Dwoobx0063 from '../assets/New_NFT/DWOOBx/Gifs/gbcp37.gif'
import Dwoobx0064 from '../assets/New_NFT/DWOOBx/Gifs/gbcp38.gif'
import Dwoobx0065 from '../assets/New_NFT/DWOOBx/Gifs/gbcp39.gif'
import Dwoobx0066 from '../assets/New_NFT/DWOOBx/Gifs/gbcp40.gif'
import Dwoobx0067 from '../assets/New_NFT/DWOOBx/Gifs/gbcp41.gif'
import Dwoobx0068 from '../assets/New_NFT/DWOOBx/Gifs/gbcp42.gif'
import Dwoobx0069 from '../assets/New_NFT/DWOOBx/Gifs/gbcp43.gif'
import Dwoobx0070 from '../assets/New_NFT/DWOOBx/Gifs/gbcp44.gif'
import Dwoobx0071 from '../assets/New_NFT/DWOOBx/Gifs/gbcp45.gif'
import Dwoobx0072 from '../assets/New_NFT/DWOOBx/Gifs/gbcp46.gif'
import Dwoobx0073 from '../assets/New_NFT/DWOOBx/Gifs/gbcp47.gif'
import Dwoobx0074 from '../assets/New_NFT/DWOOBx/Gifs/gbcp48.gif'
import Dwoobx0075 from '../assets/New_NFT/DWOOBx/Gifs/gbca1.gif'
import Dwoobx0076 from '../assets/New_NFT/DWOOBx/Gifs/gbca2.gif'
import Dwoobx0077 from '../assets/New_NFT/DWOOBx/Gifs/gbca3.gif'
import Dwoobx0078 from '../assets/New_NFT/DWOOBx/Gifs/gbca4.gif'
import Dwoobx0079 from '../assets/New_NFT/DWOOBx/Gifs/gbca5.gif'
import Dwoobx0080 from '../assets/New_NFT/DWOOBx/Gifs/gbca6.gif'
import Dwoobx0081 from '../assets/New_NFT/DWOOBx/Gifs/gbca7.gif'
import Dwoobx0082 from '../assets/New_NFT/DWOOBx/Gifs/gbca8.gif'
import Dwoobx0083 from '../assets/New_NFT/DWOOBx/Gifs/gbca9.gif'
import Dwoobx0084 from '../assets/New_NFT/DWOOBx/Gifs/gbca10.gif'
import Dwoobx0085 from '../assets/New_NFT/DWOOBx/Gifs/gbca11.gif'
import Dwoobx0086 from '../assets/New_NFT/DWOOBx/Gifs/gbca12.gif'
import Dwoobx0087 from '../assets/New_NFT/DWOOBx/Gifs/gbca13.gif'
import Dwoobx0088 from '../assets/New_NFT/DWOOBx/Gifs/gbca14.gif'
import Dwoobx0089 from '../assets/New_NFT/DWOOBx/Gifs/gbca15.gif'
import Dwoobx0090 from '../assets/New_NFT/DWOOBx/Gifs/gbca16.gif'
import Dwoobx0091 from '../assets/New_NFT/DWOOBx/Gifs/gbca17.gif'
import Dwoobx0092 from '../assets/New_NFT/DWOOBx/Gifs/gbca18.gif'
import Dwoobx0093 from '../assets/New_NFT/DWOOBx/Gifs/gbca19.gif'
import Dwoobx0094 from '../assets/New_NFT/DWOOBx/Gifs/gbca20.gif'
import Dwoobx0095 from '../assets/New_NFT/DWOOBx/Gifs/gbca21.gif'
import Dwoobx0096 from '../assets/New_NFT/DWOOBx/Gifs/gbca22.gif'
import Dwoobx0097 from '../assets/New_NFT/DWOOBx/Gifs/gbca23.gif'
import Dwoobx0098 from '../assets/New_NFT/DWOOBx/Gifs/gbca24.gif'
import Dwoobx0099 from '../assets/New_NFT/DWOOBx/Gifs/gbca25.gif'
import Dwoobx0100 from '../assets/New_NFT/DWOOBx/Gifs/gbca26.gif'
import Dwoobx0101 from '../assets/New_NFT/DWOOBx/Gifs/gbca27.gif'
import Dwoobx0102 from '../assets/New_NFT/DWOOBx/Gifs/gbca28.gif'
import Dwoobx0103 from '../assets/New_NFT/DWOOBx/Gifs/gbca29.gif'
import Dwoobx0104 from '../assets/New_NFT/DWOOBx/Gifs/gbca30.gif'
import Dwoobx0105 from '../assets/New_NFT/DWOOBx/Gifs/gbca31.gif'
import Dwoobx0106 from '../assets/New_NFT/DWOOBx/Gifs/gbca32.gif'
import Dwoobx0107 from '../assets/New_NFT/DWOOBx/Gifs/gbca33.gif'
import Dwoobx0108 from '../assets/New_NFT/DWOOBx/Gifs/gbca34.gif'
import Dwoobx0109 from '../assets/New_NFT/DWOOBx/Gifs/gbca35.gif'
import Dwoobx0110 from '../assets/New_NFT/DWOOBx/Gifs/gbca36.gif'
import Dwoobx0111 from '../assets/New_NFT/DWOOBx/Gifs/gbca37.gif'
import Dwoobx0112 from '../assets/New_NFT/DWOOBx/Gifs/gbca38.gif'
import Dwoobx0113 from '../assets/New_NFT/DWOOBx/Gifs/gbca39.gif'
import Dwoobx0114 from '../assets/New_NFT/DWOOBx/Gifs/gbca40.gif'
import Dwoobx0115 from '../assets/New_NFT/DWOOBx/Gifs/gbca41.gif'
import Dwoobx0116 from '../assets/New_NFT/DWOOBx/Gifs/gbca42.gif'
import Dwoobx0117 from '../assets/New_NFT/DWOOBx/Gifs/gbca43.gif'
import Dwoobx0118 from '../assets/New_NFT/DWOOBx/Gifs/gbca44.gif'
import Dwoobx0119 from '../assets/New_NFT/DWOOBx/Gifs/gbca45.gif'
import Dwoobx0120 from '../assets/New_NFT/DWOOBx/Gifs/gbca46.gif'
import Dwoobx0121 from '../assets/New_NFT/DWOOBx/Gifs/gbca47.gif'
import Dwoobx0122 from '../assets/New_NFT/DWOOBx/Gifs/gbca48.gif'
import Dwoobx0123 from '../assets/New_NFT/DWOOBx/Gifs/gbca49.gif'
import Dwoobx0124 from '../assets/New_NFT/DWOOBx/Gifs/gbca50.gif'


import Dwooby0001 from '../assets/New_NFT/DWOOBy/Showoffs/dwooby-0001.png'
import Dwooby0002 from '../assets/New_NFT/DWOOBy/Showoffs/dwooby-0002.png'
import Dwooby0003 from '../assets/New_NFT/DWOOBy/Showoffs/dwooby-0003.png'
import Dwooby0004 from '../assets/New_NFT/DWOOBy/Showoffs/dwooby-0004.png'
import Dwooby0005 from '../assets/New_NFT/DWOOBy/Showoffs/dwooby-0005.png'
import Dwooby0006 from '../assets/New_NFT/DWOOBy/Showoffs/dwooby-0006.png'
import Dwooby0007 from '../assets/New_NFT/DWOOBy/Showoffs/dwooby-0007.png'
import Dwooby0008 from '../assets/New_NFT/DWOOBy/Showoffs/dwooby-0008.png'
import Dwooby0009 from '../assets/New_NFT/DWOOBy/Showoffs/dwooby-0009.png'
import Dwooby0010 from '../assets/New_NFT/DWOOBy/Showoffs/dwooby-0010.png'
import Dwooby0011 from '../assets/New_NFT/DWOOBy/Showoffs/dwooby-0011.png'
import Dwooby0012 from '../assets/New_NFT/DWOOBy/Showoffs/dwooby-0012.png'
import Dwooby0013 from '../assets/New_NFT/DWOOBy/Showoffs/dwooby-0013.png'
import Dwooby0014 from '../assets/New_NFT/DWOOBy/Showoffs/dwooby-0014.png'
import Dwooby0015 from '../assets/New_NFT/DWOOBy/Showoffs/dwooby-0015.png'
import Dwooby0016 from '../assets/New_NFT/DWOOBy/Showoffs/dwooby-0016.png'
import Dwooby0017 from '../assets/New_NFT/DWOOBy/Showoffs/dwooby-0017.png'
import Dwooby0018 from '../assets/New_NFT/DWOOBy/Showoffs/dwooby-0018.png'
import Dwooby0019 from '../assets/New_NFT/DWOOBy/Showoffs/dwooby-0019.png'
import Dwooby0020 from '../assets/New_NFT/DWOOBy/Showoffs/dwooby-0020.png'
import Dwooby0021 from '../assets/New_NFT/DWOOBy/Showoffs/dwooby-0021.png'
import Dwooby0022 from '../assets/New_NFT/DWOOBy/Showoffs/dwooby-0022.png'
import Dwooby0023 from '../assets/New_NFT/DWOOBy/Showoffs/dwooby-0023.png'
import Dwooby0024 from '../assets/New_NFT/DWOOBy/Showoffs/dwooby-0024.png'
import Dwooby0025 from '../assets/New_NFT/DWOOBy/Showoffs/dwooby-0025.png'
import Dwooby0026 from '../assets/New_NFT/DWOOBy/Showoffs/dwooby-0026.png'
import Dwooby0027 from '../assets/New_NFT/DWOOBy/Showoffs/dwooby-0027.png'
import Dwooby0028 from '../assets/New_NFT/DWOOBy/Showoffs/dwooby-0028.png'
import Dwooby0029 from '../assets/New_NFT/DWOOBy/Showoffs/dwooby-0029.png'
import Dwooby0030 from '../assets/New_NFT/DWOOBy/Showoffs/dwooby-0030.png'
import Dwooby0031 from '../assets/New_NFT/DWOOBy/Showoffs/dwooby-0031.png'
import Dwooby0032 from '../assets/New_NFT/DWOOBy/Showoffs/dwooby-0032.png'
import Dwooby0033 from '../assets/New_NFT/DWOOBy/Showoffs/dwooby-0033.png'
import Dwooby0034 from '../assets/New_NFT/DWOOBy/Showoffs/dwooby-0034.png'
import Dwooby0035 from '../assets/New_NFT/DWOOBy/Showoffs/dwooby-0035.png'
import Dwooby0036 from '../assets/New_NFT/DWOOBy/Showoffs/dwooby-0036.png'
import Dwooby0037 from '../assets/New_NFT/DWOOBy/Showoffs/dwooby-0037.png'
import Dwooby0038 from '../assets/New_NFT/DWOOBy/Showoffs/dwooby-0038.png'
import Dwooby0039 from '../assets/New_NFT/DWOOBy/Showoffs/dwooby-0039.png'
import Dwooby0040 from '../assets/New_NFT/DWOOBy/Showoffs/dwooby-0040.png'
import Dwooby0041 from '../assets/New_NFT/DWOOBy/Showoffs/dwooby-0041.png'
import Dwooby0042 from '../assets/New_NFT/DWOOBy/Showoffs/dwooby-0042.png'
import Dwooby0043 from '../assets/New_NFT/DWOOBy/Showoffs/dwooby-0043.png'
import Dwooby0044 from '../assets/New_NFT/DWOOBy/Showoffs/dwooby-0044.png'
import Dwooby0045 from '../assets/New_NFT/DWOOBy/Showoffs/dwooby-0045.png'
import Dwooby0046 from '../assets/New_NFT/DWOOBy/Showoffs/dwooby-0046.png'
import Dwooby0047 from '../assets/New_NFT/DWOOBy/Showoffs/dwooby-0047.png'
import Dwooby0048 from '../assets/New_NFT/DWOOBy/Showoffs/dwooby-0048.png'
import Dwooby0049 from '../assets/New_NFT/DWOOBy/Showoffs/dwooby-0049.png'
import Dwooby0050 from '../assets/New_NFT/DWOOBy/Showoffs/dwooby-0050.png'
import Dwooby0051 from '../assets/New_NFT/DWOOBy/Showoffs/dwooby-0051.png'
import Dwooby0052 from '../assets/New_NFT/DWOOBy/Showoffs/dwooby-0052.png'
import Dwooby0053 from '../assets/New_NFT/DWOOBy/Showoffs/dwooby-0053.png'
import Dwooby0054 from '../assets/New_NFT/DWOOBy/Showoffs/dwooby-0054.png'
import Dwooby0055 from '../assets/New_NFT/DWOOBy/Showoffs/dwooby-0055.png'
import Dwooby0056 from '../assets/New_NFT/DWOOBy/Showoffs/dwooby-0056.png'
import Dwooby0057 from '../assets/New_NFT/DWOOBy/Showoffs/dwooby-0057.png'
import Dwooby0058 from '../assets/New_NFT/DWOOBy/Showoffs/dwooby-0058.png'
import Dwooby0059 from '../assets/New_NFT/DWOOBy/Showoffs/dwooby-0059.png'
import Dwooby0060 from '../assets/New_NFT/DWOOBy/Showoffs/dwooby-0060.png'
import Dwooby0061 from '../assets/New_NFT/DWOOBy/Showoffs/dwooby-0061.png'
import Dwooby0062 from '../assets/New_NFT/DWOOBy/Showoffs/dwooby-0062.png'
import Dwooby0063 from '../assets/New_NFT/DWOOBy/Showoffs/dwooby-0063.png'
import Dwooby0064 from '../assets/New_NFT/DWOOBy/Showoffs/dwooby-0064.png'
import Dwooby0065 from '../assets/New_NFT/DWOOBy/Showoffs/dwooby-0065.png'
import Dwooby0066 from '../assets/New_NFT/DWOOBy/Showoffs/dwooby-0066.png'
import Dwooby0067 from '../assets/New_NFT/DWOOBy/Showoffs/dwooby-0067.png'
import Dwooby0068 from '../assets/New_NFT/DWOOBy/Showoffs/dwooby-0068.png'
import Dwooby0069 from '../assets/New_NFT/DWOOBy/Showoffs/dwooby-0069.png'
import Dwooby0070 from '../assets/New_NFT/DWOOBy/Showoffs/dwooby-0070.png'
import Dwooby0071 from '../assets/New_NFT/DWOOBy/Showoffs/dwooby-0071.png'
import Dwooby0072 from '../assets/New_NFT/DWOOBy/Showoffs/dwooby-0072.png'
import Dwooby0073 from '../assets/New_NFT/DWOOBy/Showoffs/dwooby-0073.png'
import Dwooby0074 from '../assets/New_NFT/DWOOBy/Showoffs/dwooby-0074.png'
import Dwooby0075 from '../assets/New_NFT/DWOOBy/Showoffs/dwooby-0075.png'
import Dwooby0076 from '../assets/New_NFT/DWOOBy/Showoffs/dwooby-0076.png'
import Dwooby0077 from '../assets/New_NFT/DWOOBy/Showoffs/dwooby-0077.png'
import Dwooby0078 from '../assets/New_NFT/DWOOBy/Showoffs/dwooby-0078.png'
import Dwooby0079 from '../assets/New_NFT/DWOOBy/Showoffs/dwooby-0079.png'
import Dwooby0080 from '../assets/New_NFT/DWOOBy/Showoffs/dwooby-0080.png'
import Dwooby0081 from '../assets/New_NFT/DWOOBy/Showoffs/dwooby-0081.png'
import Dwooby0082 from '../assets/New_NFT/DWOOBy/Showoffs/dwooby-0082.png'
import Dwooby0083 from '../assets/New_NFT/DWOOBy/Showoffs/dwooby-0083.png'
import Dwooby0084 from '../assets/New_NFT/DWOOBy/Showoffs/dwooby-0084.png'
import Dwooby0085 from '../assets/New_NFT/DWOOBy/Showoffs/dwooby-0085.png'
import Dwooby0086 from '../assets/New_NFT/DWOOBy/Showoffs/dwooby-0086.png'
import Dwooby0087 from '../assets/New_NFT/DWOOBy/Showoffs/dwooby-0087.png'
import Dwooby0088 from '../assets/New_NFT/DWOOBy/Showoffs/dwooby-0088.png'
import Dwooby0089 from '../assets/New_NFT/DWOOBy/Showoffs/dwooby-0089.png'
import Dwooby0090 from '../assets/New_NFT/DWOOBy/Showoffs/dwooby-0090.png'
import Dwooby0091 from '../assets/New_NFT/DWOOBy/Showoffs/dwooby-0091.png'
import Dwooby0092 from '../assets/New_NFT/DWOOBy/Showoffs/dwooby-0092.png'
import Dwooby0093 from '../assets/New_NFT/DWOOBy/Showoffs/dwooby-0093.png'
import Dwooby0094 from '../assets/New_NFT/DWOOBy/Showoffs/dwooby-0094.png'
import Dwooby0095 from '../assets/New_NFT/DWOOBy/Showoffs/dwooby-0095.png'
import Dwooby0096 from '../assets/New_NFT/DWOOBy/Showoffs/dwooby-0096.png'
import Dwooby0097 from '../assets/New_NFT/DWOOBy/Showoffs/dwooby-0097.png'
import Dwooby0098 from '../assets/New_NFT/DWOOBy/Showoffs/dwooby-0098.png'
import Dwooby0099 from '../assets/New_NFT/DWOOBy/Showoffs/dwooby-0099.png'
import Dwooby0100 from '../assets/New_NFT/DWOOBy/Showoffs/dwooby-0100.png'
import Dwooby0101 from '../assets/New_NFT/DWOOBy/Showoffs/dwooby-0101.png'
import Dwooby0102 from '../assets/New_NFT/DWOOBy/Showoffs/dwooby-0102.png'
import Dwooby0103 from '../assets/New_NFT/DWOOBy/Showoffs/dwooby-0103.png'
import Dwooby0104 from '../assets/New_NFT/DWOOBy/Showoffs/dwooby-0104.png'
import Dwooby0105 from '../assets/New_NFT/DWOOBy/Showoffs/dwooby-0105.png'
import Dwooby0106 from '../assets/New_NFT/DWOOBy/Showoffs/dwooby-0106.png'
import Dwooby0107 from '../assets/New_NFT/DWOOBy/Showoffs/dwooby-0107.png'
import Dwooby0108 from '../assets/New_NFT/DWOOBy/Showoffs/dwooby-0108.png'
import Dwooby0109 from '../assets/New_NFT/DWOOBy/Showoffs/dwooby-0109.png'
import Dwooby0110 from '../assets/New_NFT/DWOOBy/Showoffs/dwooby-0110.png'
import Dwooby0111 from '../assets/New_NFT/DWOOBy/Showoffs/dwooby-0111.png'
import Dwooby0112 from '../assets/New_NFT/DWOOBy/Showoffs/dwooby-0112.png'
import Dwooby0113 from '../assets/New_NFT/DWOOBy/Showoffs/dwooby-0113.png'
import Dwooby0114 from '../assets/New_NFT/DWOOBy/Showoffs/dwooby-0114.png'
import Dwooby0115 from '../assets/New_NFT/DWOOBy/Showoffs/dwooby-0115.png'
import Dwooby0116 from '../assets/New_NFT/DWOOBy/Showoffs/dwooby-0116.png'
import Dwooby0117 from '../assets/New_NFT/DWOOBy/Showoffs/dwooby-0117.png'
import Dwooby0118 from '../assets/New_NFT/DWOOBy/Showoffs/dwooby-0118.png'
import Dwooby0119 from '../assets/New_NFT/DWOOBy/Showoffs/dwooby-0119.png'
import Dwooby0120 from '../assets/New_NFT/DWOOBy/Showoffs/dwooby-0120.png'
import Dwooby0121 from '../assets/New_NFT/DWOOBy/Showoffs/dwooby-0121.png'
import Dwooby0122 from '../assets/New_NFT/DWOOBy/Showoffs/dwooby-0122.png'
import Dwooby0123 from '../assets/New_NFT/DWOOBy/Showoffs/dwooby-0123.png'
import Dwooby0124 from '../assets/New_NFT/DWOOBy/Showoffs/dwooby-0124.png'
import Dwooby0125 from '../assets/New_NFT/DWOOBy/Showoffs/dwooby-0125.png'
import Dwooby0126 from '../assets/New_NFT/DWOOBy/Showoffs/dwooby-0126.png'
import Dwooby0127 from '../assets/New_NFT/DWOOBy/Showoffs/dwooby-0127.png'
import Dwooby0128 from '../assets/New_NFT/DWOOBy/Showoffs/dwooby-0128.png'
import Dwooby0129 from '../assets/New_NFT/DWOOBy/Showoffs/dwooby-0129.png'
import Dwooby0130 from '../assets/New_NFT/DWOOBy/Showoffs/dwooby-0130.png'
import Dwooby0131 from '../assets/New_NFT/DWOOBy/Showoffs/dwooby-0131.png'
import Dwooby0132 from '../assets/New_NFT/DWOOBy/Showoffs/dwooby-0132.png'
import Dwooby0133 from '../assets/New_NFT/DWOOBy/Showoffs/dwooby-0133.png'
import Dwooby0134 from '../assets/New_NFT/DWOOBy/Showoffs/dwooby-0134.png'
import Dwooby0135 from '../assets/New_NFT/DWOOBy/Showoffs/dwooby-0135.png'
import Dwooby0136 from '../assets/New_NFT/DWOOBy/Showoffs/dwooby-0136.png'
import Dwooby0137 from '../assets/New_NFT/DWOOBy/Showoffs/dwooby-0137.png'
import Dwooby0138 from '../assets/New_NFT/DWOOBy/Showoffs/dwooby-0138.png'
import Dwooby0139 from '../assets/New_NFT/DWOOBy/Showoffs/dwooby-0139.png'
import Dwooby0140 from '../assets/New_NFT/DWOOBy/Showoffs/dwooby-0140.png'
import Dwooby0141 from '../assets/New_NFT/DWOOBy/Showoffs/dwooby-0141.png'
import Dwooby0142 from '../assets/New_NFT/DWOOBy/Showoffs/dwooby-0142.png'
import Dwooby0143 from '../assets/New_NFT/DWOOBy/Showoffs/dwooby-0143.png'
import Dwooby0144 from '../assets/New_NFT/DWOOBy/Showoffs/dwooby-0144.png'
import Dwooby0145 from '../assets/New_NFT/DWOOBy/Showoffs/dwooby-0145.png'
import Dwooby0146 from '../assets/New_NFT/DWOOBy/Showoffs/dwooby-0146.png'
import Dwooby0147 from '../assets/New_NFT/DWOOBy/Showoffs/dwooby-0147.png'
import Dwooby0148 from '../assets/New_NFT/DWOOBy/Showoffs/dwooby-0148.png'
import Dwooby0149 from '../assets/New_NFT/DWOOBy/Showoffs/dwooby-0149.png'
import Dwooby0150 from '../assets/New_NFT/DWOOBy/Showoffs/dwooby-0150.png'
import Dwooby0151 from '../assets/New_NFT/DWOOBy/Showoffs/dwooby-0151.png'
import Dwooby0152 from '../assets/New_NFT/DWOOBy/Showoffs/dwooby-0152.png'
import Dwooby0153 from '../assets/New_NFT/DWOOBy/Showoffs/dwooby-0153.png'
import Dwooby0154 from '../assets/New_NFT/DWOOBy/Showoffs/dwooby-0154.png'
import Dwooby0155 from '../assets/New_NFT/DWOOBy/Showoffs/dwooby-0155.png'
import Dwooby0156 from '../assets/New_NFT/DWOOBy/Showoffs/dwooby-0156.png'
import Dwooby0157 from '../assets/New_NFT/DWOOBy/Showoffs/dwooby-0157.png'
import Dwooby0158 from '../assets/New_NFT/DWOOBy/Showoffs/dwooby-0158.png'
import Dwooby0159 from '../assets/New_NFT/DWOOBy/Showoffs/dwooby-0159.png'
import Dwooby0160 from '../assets/New_NFT/DWOOBy/Showoffs/dwooby-0160.png'
import Dwooby0161 from '../assets/New_NFT/DWOOBy/Showoffs/dwooby-0161.png'
import Dwooby0162 from '../assets/New_NFT/DWOOBy/Showoffs/dwooby-0162.png'
import Dwooby0163 from '../assets/New_NFT/DWOOBy/Showoffs/dwooby-0163.png'
import Dwooby0164 from '../assets/New_NFT/DWOOBy/Showoffs/dwooby-0164.png'
import Dwooby0165 from '../assets/New_NFT/DWOOBy/Showoffs/dwooby-0165.png'
import Dwooby0166 from '../assets/New_NFT/DWOOBy/Showoffs/dwooby-0166.png'
import Dwooby0167 from '../assets/New_NFT/DWOOBy/Showoffs/dwooby-0167.png'
import Dwooby0168 from '../assets/New_NFT/DWOOBy/Showoffs/dwooby-0168.png'
import Dwooby0169 from '../assets/New_NFT/DWOOBy/Showoffs/dwooby-0169.png'
import Dwooby0170 from '../assets/New_NFT/DWOOBy/Showoffs/dwooby-0170.png'
import Dwooby0171 from '../assets/New_NFT/DWOOBy/Showoffs/dwooby-0171.png'
import Dwooby0172 from '../assets/New_NFT/DWOOBy/Showoffs/dwooby-0172.png'
import Dwooby0173 from '../assets/New_NFT/DWOOBy/Showoffs/dwooby-0173.png'
import Dwooby0174 from '../assets/New_NFT/DWOOBy/Showoffs/dwooby-0174.png'
import Dwooby0175 from '../assets/New_NFT/DWOOBy/Showoffs/dwooby-0175.png'
import Dwooby0176 from '../assets/New_NFT/DWOOBy/Showoffs/dwooby-0176.png'
import Dwooby0177 from '../assets/New_NFT/DWOOBy/Showoffs/dwooby-0177.png'
import Dwooby0178 from '../assets/New_NFT/DWOOBy/Showoffs/dwooby-0178.png'
import Dwooby0179 from '../assets/New_NFT/DWOOBy/Showoffs/dwooby-0179.png'
import Dwooby0180 from '../assets/New_NFT/DWOOBy/Showoffs/dwooby-0180.png'
import Dwooby0181 from '../assets/New_NFT/DWOOBy/Showoffs/dwooby-0181.png'
import Dwooby0182 from '../assets/New_NFT/DWOOBy/Showoffs/dwooby-0182.png'
import Dwooby0183 from '../assets/New_NFT/DWOOBy/Showoffs/dwooby-0183.png'
import Dwooby0184 from '../assets/New_NFT/DWOOBy/Showoffs/dwooby-0184.png'
import Dwooby0185 from '../assets/New_NFT/DWOOBy/Showoffs/dwooby-0185.png'
import Dwooby0186 from '../assets/New_NFT/DWOOBy/Showoffs/dwooby-0186.png'
import Dwooby0187 from '../assets/New_NFT/DWOOBy/Showoffs/dwooby-0187.png'
import Dwooby0188 from '../assets/New_NFT/DWOOBy/Showoffs/dwooby-0188.png'
import Dwooby0189 from '../assets/New_NFT/DWOOBy/Showoffs/dwooby-0189.png'
import Dwooby0190 from '../assets/New_NFT/DWOOBy/Showoffs/dwooby-0190.png'
import Dwooby0191 from '../assets/New_NFT/DWOOBy/Showoffs/dwooby-0191.png'
import Dwooby0192 from '../assets/New_NFT/DWOOBy/Showoffs/dwooby-0192.png'
import Dwooby0193 from '../assets/New_NFT/DWOOBy/Showoffs/dwooby-0193.png'
import Dwooby0194 from '../assets/New_NFT/DWOOBy/Showoffs/dwooby-0194.png'
import Dwooby0195 from '../assets/New_NFT/DWOOBy/Showoffs/dwooby-0195.png'


import Bcca from '../assets/New_NFT/Kardz/StandardCards/ControllerCards/bcca.png'
import Bccb from '../assets/New_NFT/Kardz/StandardCards/ControllerCards/bccb.png'
import Bccc from '../assets/New_NFT/Kardz/StandardCards/ControllerCards/bccc.png'
import Bccd from '../assets/New_NFT/Kardz/StandardCards/ControllerCards/bccd.png'
import Bcce from '../assets/New_NFT/Kardz/StandardCards/ControllerCards/bcce.png'
import Bccf from '../assets/New_NFT/Kardz/StandardCards/ControllerCards/bccf.png'
import Bccg from '../assets/New_NFT/Kardz/StandardCards/ControllerCards/bccg.png'
import Bcch from '../assets/New_NFT/Kardz/StandardCards/ControllerCards/bcch.png'
import Bcci from '../assets/New_NFT/Kardz/StandardCards/ControllerCards/bcci.png'
import Bccj from '../assets/New_NFT/Kardz/StandardCards/ControllerCards/bccj.png'
import Bcc1 from '../assets/New_NFT/Kardz/StandardCards/ControllerCards/bcc1.png'
import Bcc2 from '../assets/New_NFT/Kardz/StandardCards/ControllerCards/bcc2.png'
import Bcc3 from '../assets/New_NFT/Kardz/StandardCards/ControllerCards/bcc3.png'
import Bcc4 from '../assets/New_NFT/Kardz/StandardCards/ControllerCards/bcc4.png'
import Bcc5 from '../assets/New_NFT/Kardz/StandardCards/ControllerCards/bcc5.png'
import Bcc6 from '../assets/New_NFT/Kardz/StandardCards/ControllerCards/bcc6.png'
import Bcc7 from '../assets/New_NFT/Kardz/StandardCards/ControllerCards/bcc7.png'
import Bcc8 from '../assets/New_NFT/Kardz/StandardCards/ControllerCards/bcc8.png'
import Bcp1 from '../assets/New_NFT/Kardz/StandardCards/PlayerCards/bcp1.png'
import Bcp2 from '../assets/New_NFT/Kardz/StandardCards/PlayerCards/bcp2.png'
import Bcp3 from '../assets/New_NFT/Kardz/StandardCards/PlayerCards/bcp3.png'
import Bcp4 from '../assets/New_NFT/Kardz/StandardCards/PlayerCards/bcp4.png'
import Bcp5 from '../assets/New_NFT/Kardz/StandardCards/PlayerCards/bcp5.png'
import Bcp6 from '../assets/New_NFT/Kardz/StandardCards/PlayerCards/bcp6.png'
import Bcp7 from '../assets/New_NFT/Kardz/StandardCards/PlayerCards/bcp7.png'
import Bcp8 from '../assets/New_NFT/Kardz/StandardCards/PlayerCards/bcp8.png'
import Bcp9 from '../assets/New_NFT/Kardz/StandardCards/PlayerCards/bcp9.png'
import Bcp10 from '../assets/New_NFT/Kardz/StandardCards/PlayerCards/bcp10.png'
import Bcp11 from '../assets/New_NFT/Kardz/StandardCards/PlayerCards/bcp11.png'
import Bcp12 from '../assets/New_NFT/Kardz/StandardCards/PlayerCards/bcp12.png'
import Bcp13 from '../assets/New_NFT/Kardz/StandardCards/PlayerCards/bcp13.png'
import Bcp14 from '../assets/New_NFT/Kardz/StandardCards/PlayerCards/bcp14.png'
import Bcp15 from '../assets/New_NFT/Kardz/StandardCards/PlayerCards/bcp15.png'
import Bcp16 from '../assets/New_NFT/Kardz/StandardCards/PlayerCards/bcp16.png'
import Bcp17 from '../assets/New_NFT/Kardz/StandardCards/PlayerCards/bcp17.png'
import Bcp18 from '../assets/New_NFT/Kardz/StandardCards/PlayerCards/bcp18.png'
import Bcp19 from '../assets/New_NFT/Kardz/StandardCards/PlayerCards/bcp19.png'
import Bcp20 from '../assets/New_NFT/Kardz/StandardCards/PlayerCards/bcp20.png'
import Bcp21 from '../assets/New_NFT/Kardz/StandardCards/PlayerCards/bcp21.png'
import Bcp22 from '../assets/New_NFT/Kardz/StandardCards/PlayerCards/bcp22.png'
import Bcp23 from '../assets/New_NFT/Kardz/StandardCards/PlayerCards/bcp23.png'
import Bcp24 from '../assets/New_NFT/Kardz/StandardCards/PlayerCards/bcp24.png'
import Bcp25 from '../assets/New_NFT/Kardz/StandardCards/PlayerCards/bcp25.png'
import Bcp26 from '../assets/New_NFT/Kardz/StandardCards/PlayerCards/bcp26.png'
import Bcp27 from '../assets/New_NFT/Kardz/StandardCards/PlayerCards/bcp27.png'
import Bcp28 from '../assets/New_NFT/Kardz/StandardCards/PlayerCards/bcp28.png'
import Bcp29 from '../assets/New_NFT/Kardz/StandardCards/PlayerCards/bcp29.png'
import Bcp30 from '../assets/New_NFT/Kardz/StandardCards/PlayerCards/bcp30.png'
import Bcp31 from '../assets/New_NFT/Kardz/StandardCards/PlayerCards/bcp31.png'
import Bcp32 from '../assets/New_NFT/Kardz/StandardCards/PlayerCards/bcp32.png'
import Bcp33 from '../assets/New_NFT/Kardz/StandardCards/PlayerCards/bcp33.png'
import Bcp34 from '../assets/New_NFT/Kardz/StandardCards/PlayerCards/bcp34.png'
import Bcp35 from '../assets/New_NFT/Kardz/StandardCards/PlayerCards/bcp35.png'
import Bcp36 from '../assets/New_NFT/Kardz/StandardCards/PlayerCards/bcp36.png'
import Bcp37 from '../assets/New_NFT/Kardz/StandardCards/PlayerCards/bcp37.png'
import Bcp38 from '../assets/New_NFT/Kardz/StandardCards/PlayerCards/bcp38.png'
import Bcp39 from '../assets/New_NFT/Kardz/StandardCards/PlayerCards/bcp39.png'
import Bcp40 from '../assets/New_NFT/Kardz/StandardCards/PlayerCards/bcp40.png'
import Bcp41 from '../assets/New_NFT/Kardz/StandardCards/PlayerCards/bcp41.png'
import Bcp42 from '../assets/New_NFT/Kardz/StandardCards/PlayerCards/bcp42.png'
import Bcp43 from '../assets/New_NFT/Kardz/StandardCards/PlayerCards/bcp43.png'
import Bcp44 from '../assets/New_NFT/Kardz/StandardCards/PlayerCards/bcp44.png'
import Bcp45 from '../assets/New_NFT/Kardz/StandardCards/PlayerCards/bcp45.png'
import Bcp46 from '../assets/New_NFT/Kardz/StandardCards/PlayerCards/bcp46.png'
import Bcp47 from '../assets/New_NFT/Kardz/StandardCards/PlayerCards/bcp47.png'
import Bcp48 from '../assets/New_NFT/Kardz/StandardCards/PlayerCards/bcp48.png'
import Bcp49 from '../assets/New_NFT/Kardz/StandardCards/PlayerCards/bcp49a.gif'
import Bcp50 from '../assets/New_NFT/Kardz/StandardCards/PlayerCards/bcp50.gif'
import Bcp51 from '../assets/New_NFT/Kardz/StandardCards/PlayerCards/bcp51.gif'
import Bcp52 from '../assets/New_NFT/Kardz/StandardCards/PlayerCards/bcp52.gif'
import Bcp53 from '../assets/New_NFT/Kardz/StandardCards/PlayerCards/bcp53.gif'
import Bcp54 from '../assets/New_NFT/Kardz/StandardCards/PlayerCards/bcp54.gif'
import Bcp55 from '../assets/New_NFT/Kardz/StandardCards/PlayerCards/bcp55.gif'
import Bcp56 from '../assets/New_NFT/Kardz/StandardCards/PlayerCards/bcp56.gif'
import Bcp57 from '../assets/New_NFT/Kardz/StandardCards/PlayerCards/bcp57.gif'
import Bcp58 from '../assets/New_NFT/Kardz/StandardCards/PlayerCards/bcp58.gif'
import Bcp59 from '../assets/New_NFT/Kardz/StandardCards/PlayerCards/bcp59.gif'
import Bcp60 from '../assets/New_NFT/Kardz/StandardCards/PlayerCards/bcp60.gif'
import Bcp61 from '../assets/New_NFT/Kardz/StandardCards/PlayerCards/bcp61.gif'
import Bcp62 from '../assets/New_NFT/Kardz/StandardCards/PlayerCards/bcp62.gif'
import Bcp63 from '../assets/New_NFT/Kardz/StandardCards/PlayerCards/bcp63.gif'
import Bcp64 from '../assets/New_NFT/Kardz/StandardCards/PlayerCards/bcp64.gif'
import Bcp65 from '../assets/New_NFT/Kardz/StandardCards/PlayerCards/bcp65.gif'
import Bcp66 from '../assets/New_NFT/Kardz/StandardCards/PlayerCards/bcp66.gif'
import Bcp67 from '../assets/New_NFT/Kardz/StandardCards/PlayerCards/bcp67.gif'
import Bcp68 from '../assets/New_NFT/Kardz/StandardCards/PlayerCards/bcp68.gif'
import Bcp69 from '../assets/New_NFT/Kardz/StandardCards/PlayerCards/bcp69.gif'
import Bcp70 from '../assets/New_NFT/Kardz/StandardCards/PlayerCards/bcp70.gif'
import Bcp71 from '../assets/New_NFT/Kardz/StandardCards/PlayerCards/bcp71.gif'
import Bcp72 from '../assets/New_NFT/Kardz/StandardCards/PlayerCards/bcp72.gif'
import Bcp73 from '../assets/New_NFT/Kardz/StandardCards/PlayerCards/bcp73.gif'
import Bcp74 from '../assets/New_NFT/Kardz/StandardCards/PlayerCards/bcp74.gif'
import Bcp75 from '../assets/New_NFT/Kardz/StandardCards/PlayerCards/bcp75.gif'
import Bcp76 from '../assets/New_NFT/Kardz/StandardCards/PlayerCards/bcp76.gif'
import Bcp77 from '../assets/New_NFT/Kardz/StandardCards/PlayerCards/bcp77.gif'
import Bcp78 from '../assets/New_NFT/Kardz/StandardCards/PlayerCards/bcp78.gif'
import Bcp79 from '../assets/New_NFT/Kardz/StandardCards/PlayerCards/bcp79.gif'
import Bcp80 from '../assets/New_NFT/Kardz/StandardCards/PlayerCards/bcp80.gif'
import Bcp81 from '../assets/New_NFT/Kardz/StandardCards/PlayerCards/bcp81.gif'
import Bcp82 from '../assets/New_NFT/Kardz/StandardCards/PlayerCards/bcp82.gif'
import Bcp83 from '../assets/New_NFT/Kardz/StandardCards/PlayerCards/bcp83.gif'
import Bcp84 from '../assets/New_NFT/Kardz/StandardCards/PlayerCards/bcp84.gif'
import Bcp85 from '../assets/New_NFT/Kardz/StandardCards/PlayerCards/bcp85.gif'
import Bcp86 from '../assets/New_NFT/Kardz/StandardCards/PlayerCards/bcp86.gif'
import Bcp87 from '../assets/New_NFT/Kardz/StandardCards/PlayerCards/bcp87.gif'
import Bcp88 from '../assets/New_NFT/Kardz/StandardCards/PlayerCards/bcp88.gif'
import Bcp89 from '../assets/New_NFT/Kardz/StandardCards/PlayerCards/bcp89.gif'
import Bcp90 from '../assets/New_NFT/Kardz/StandardCards/PlayerCards/bcp90.gif'
import Bcp91 from '../assets/New_NFT/Kardz/StandardCards/PlayerCards/bcp91.gif'
import Bcp92 from '../assets/New_NFT/Kardz/StandardCards/PlayerCards/bcp92.gif'
import Bcp93 from '../assets/New_NFT/Kardz/StandardCards/PlayerCards/bcp93.gif'
import Bcp94 from '../assets/New_NFT/Kardz/StandardCards/PlayerCards/bcp94.gif'
import Bcp95 from '../assets/New_NFT/Kardz/StandardCards/PlayerCards/bcp95.gif'
import Bcp96 from '../assets/New_NFT/Kardz/StandardCards/PlayerCards/bcp96.gif'
import Bcs1 from '../assets/New_NFT/Kardz/StandardCards/SummonCards/bcs1a.gif'
import Bcs2 from '../assets/New_NFT/Kardz/StandardCards/SummonCards/bcs2a.gif'
import Bcs3 from '../assets/New_NFT/Kardz/StandardCards/SummonCards/bcs3a.gif'
import Bcs4 from '../assets/New_NFT/Kardz/StandardCards/SummonCards/bcs4a.gif'
import Bcs5 from '../assets/New_NFT/Kardz/StandardCards/SummonCards/bcs5a.gif'
import Bcs6 from '../assets/New_NFT/Kardz/StandardCards/SummonCards/bcs6a.gif'
import Bcs7 from '../assets/New_NFT/Kardz/StandardCards/SummonCards/bcs7a.gif'
import Bcs8 from '../assets/New_NFT/Kardz/StandardCards/SummonCards/bcs8a.gif'
import Bca51 from '../assets/New_NFT/Kardz/StandardCards/AssistCards/bca51a.gif'
import Bca52 from '../assets/New_NFT/Kardz/StandardCards/AssistCards/bca52.png'
import Bca53 from '../assets/New_NFT/Kardz/StandardCards/AssistCards/bca53.png'
import Bca54 from '../assets/New_NFT/Kardz/StandardCards/AssistCards/bca54.png'
import Bca55 from '../assets/New_NFT/Kardz/StandardCards/AssistCards/bca55.png'
import Bca56 from '../assets/New_NFT/Kardz/StandardCards/AssistCards/bca56.png'
import Bca57 from '../assets/New_NFT/Kardz/StandardCards/AssistCards/bca57.png'
import Bca58 from '../assets/New_NFT/Kardz/StandardCards/AssistCards/bca58.png'
import Bca59 from '../assets/New_NFT/Kardz/StandardCards/AssistCards/bca59.png'
import Bca60 from '../assets/New_NFT/Kardz/StandardCards/AssistCards/bca60.png'
import Bca61 from '../assets/New_NFT/Kardz/StandardCards/AssistCards/bca61.png'
import Bca62 from '../assets/New_NFT/Kardz/StandardCards/AssistCards/bca62.png'
import Bca63 from '../assets/New_NFT/Kardz/StandardCards/AssistCards/bca63.png'
import Bca64 from '../assets/New_NFT/Kardz/StandardCards/AssistCards/bca64.png'
import Bca65 from '../assets/New_NFT/Kardz/StandardCards/AssistCards/bca65.png'
import Bca66 from '../assets/New_NFT/Kardz/StandardCards/AssistCards/bca66.png'
import Bca67 from '../assets/New_NFT/Kardz/StandardCards/AssistCards/bca67.png'
import Bca68 from '../assets/New_NFT/Kardz/StandardCards/AssistCards/bca68.png'
import Bca69 from '../assets/New_NFT/Kardz/StandardCards/AssistCards/bca69.png'
import Bca70 from '../assets/New_NFT/Kardz/StandardCards/AssistCards/bca70.png'
import Bca71 from '../assets/New_NFT/Kardz/StandardCards/AssistCards/bca71.png'
import Bca72 from '../assets/New_NFT/Kardz/StandardCards/AssistCards/bca72.png'
import Bca73 from '../assets/New_NFT/Kardz/StandardCards/AssistCards/bca73.png'
import Bca74 from '../assets/New_NFT/Kardz/StandardCards/AssistCards/bca74.png'
import Bca75 from '../assets/New_NFT/Kardz/StandardCards/AssistCards/bca75.png'
import Bca76 from '../assets/New_NFT/Kardz/StandardCards/AssistCards/bca76.png'
import Bca77 from '../assets/New_NFT/Kardz/StandardCards/AssistCards/bca77.png'
import Bca78 from '../assets/New_NFT/Kardz/StandardCards/AssistCards/bca78.png'
import Bca79 from '../assets/New_NFT/Kardz/StandardCards/AssistCards/bca79.png'
import Bca80 from '../assets/New_NFT/Kardz/StandardCards/AssistCards/bca80.png'
import Bca81 from '../assets/New_NFT/Kardz/StandardCards/AssistCards/bca81.png'
import Bca82 from '../assets/New_NFT/Kardz/StandardCards/AssistCards/bca82.png'
import Bca83 from '../assets/New_NFT/Kardz/StandardCards/AssistCards/bca83.png'
import Bca84 from '../assets/New_NFT/Kardz/StandardCards/AssistCards/bca84.png'
import Bca85 from '../assets/New_NFT/Kardz/StandardCards/AssistCards/bca85.png'
import Bca86 from '../assets/New_NFT/Kardz/StandardCards/AssistCards/bca86.png'
import Bca87 from '../assets/New_NFT/Kardz/StandardCards/AssistCards/bca87.png'
import Bca88 from '../assets/New_NFT/Kardz/StandardCards/AssistCards/bca88.png'
import Bca89 from '../assets/New_NFT/Kardz/StandardCards/AssistCards/bca89.png'
import Bca90 from '../assets/New_NFT/Kardz/StandardCards/AssistCards/bca90.png'
import Bca91 from '../assets/New_NFT/Kardz/StandardCards/AssistCards/bca91.png'
import Bca92 from '../assets/New_NFT/Kardz/StandardCards/AssistCards/bca92.png'
import Bca93 from '../assets/New_NFT/Kardz/StandardCards/AssistCards/bca93.png'
import Bca94 from '../assets/New_NFT/Kardz/StandardCards/AssistCards/bca94.png'
import Bca95 from '../assets/New_NFT/Kardz/StandardCards/AssistCards/bca95.png'
import Bca96 from '../assets/New_NFT/Kardz/StandardCards/AssistCards/bca96.png'
import Bca97 from '../assets/New_NFT/Kardz/StandardCards/AssistCards/bca97.png'
import Bca98 from '../assets/New_NFT/Kardz/StandardCards/AssistCards/bca98.png'
import Bcs9 from '../assets/New_NFT/Kardz/StandardCards/SummonCards/bcs9.png'
import Bcs10 from '../assets/New_NFT/Kardz/StandardCards/SummonCards/bcs10.png'
import Bcs11 from '../assets/New_NFT/Kardz/StandardCards/SummonCards/bcs11.png'
import Bcs12 from '../assets/New_NFT/Kardz/StandardCards/SummonCards/bcs12.png'
import Bcs13 from '../assets/New_NFT/Kardz/StandardCards/SummonCards/bcs13.png'
import Bcs14 from '../assets/New_NFT/Kardz/StandardCards/SummonCards/bcs14.png'
import Bcs15 from '../assets/New_NFT/Kardz/StandardCards/SummonCards/bcs15.png'
import Bcs16 from '../assets/New_NFT/Kardz/StandardCards/SummonCards/bcs16.png'
import Bca1 from '../assets/New_NFT/Kardz/StandardCards/AssistCards/bca1.png'
import Bca2 from '../assets/New_NFT/Kardz/StandardCards/AssistCards/bca2.png'
import Bca3 from '../assets/New_NFT/Kardz/StandardCards/AssistCards/bca3.png'
import Bca4 from '../assets/New_NFT/Kardz/StandardCards/AssistCards/bca4.png'
import Bca5 from '../assets/New_NFT/Kardz/StandardCards/AssistCards/bca5.png'
import Bca6 from '../assets/New_NFT/Kardz/StandardCards/AssistCards/bca6.png'
import Bca7 from '../assets/New_NFT/Kardz/StandardCards/AssistCards/bca7.png'
import Bca8 from '../assets/New_NFT/Kardz/StandardCards/AssistCards/bca8.png'
import Bca9 from '../assets/New_NFT/Kardz/StandardCards/AssistCards/bca9.png'
import Bca10 from '../assets/New_NFT/Kardz/StandardCards/AssistCards/bca10.png'
import Bca11 from '../assets/New_NFT/Kardz/StandardCards/AssistCards/bca11.png'
import Bca12 from '../assets/New_NFT/Kardz/StandardCards/AssistCards/bca12.png'
import Bca13 from '../assets/New_NFT/Kardz/StandardCards/AssistCards/bca13.png'
import Bca14 from '../assets/New_NFT/Kardz/StandardCards/AssistCards/bca14.png'
import Bca15 from '../assets/New_NFT/Kardz/StandardCards/AssistCards/bca15.png'
import Bca16 from '../assets/New_NFT/Kardz/StandardCards/AssistCards/bca16.png'
import Bca17 from '../assets/New_NFT/Kardz/StandardCards/AssistCards/bca17.png'
import Bca18 from '../assets/New_NFT/Kardz/StandardCards/AssistCards/bca18.png'
import Bca19 from '../assets/New_NFT/Kardz/StandardCards/AssistCards/bca19.png'
import Bca20 from '../assets/New_NFT/Kardz/StandardCards/AssistCards/bca20.png'
import Bca21 from '../assets/New_NFT/Kardz/StandardCards/AssistCards/bca21.png'
import Bca22 from '../assets/New_NFT/Kardz/StandardCards/AssistCards/bca22.png'
import Bca23 from '../assets/New_NFT/Kardz/StandardCards/AssistCards/bca23.png'
import Bca24 from '../assets/New_NFT/Kardz/StandardCards/AssistCards/bca24.png'
import Bca25 from '../assets/New_NFT/Kardz/StandardCards/AssistCards/bca25.png'
import Bca26 from '../assets/New_NFT/Kardz/StandardCards/AssistCards/bca26.png'
import Bca27 from '../assets/New_NFT/Kardz/StandardCards/AssistCards/bca27.png'
import Bca28 from '../assets/New_NFT/Kardz/StandardCards/AssistCards/bca28.png'
import Bca29 from '../assets/New_NFT/Kardz/StandardCards/AssistCards/bca29.png'
import Bca30 from '../assets/New_NFT/Kardz/StandardCards/AssistCards/bca30.png'
import Bca31 from '../assets/New_NFT/Kardz/StandardCards/AssistCards/bca31.png'
import Bca32 from '../assets/New_NFT/Kardz/StandardCards/AssistCards/bca32.png'
import Bca33 from '../assets/New_NFT/Kardz/StandardCards/AssistCards/bca33.png'
import Bca34 from '../assets/New_NFT/Kardz/StandardCards/AssistCards/bca34.png'
import Bca35 from '../assets/New_NFT/Kardz/StandardCards/AssistCards/bca35.png'
import Bca36 from '../assets/New_NFT/Kardz/StandardCards/AssistCards/bca36.png'
import Bca37 from '../assets/New_NFT/Kardz/StandardCards/AssistCards/bca37.png'
import Bca38 from '../assets/New_NFT/Kardz/StandardCards/AssistCards/bca38.png'
import Bca39 from '../assets/New_NFT/Kardz/StandardCards/AssistCards/bca39.png'
import Bca40 from '../assets/New_NFT/Kardz/StandardCards/AssistCards/bca40.png'
import Bca41 from '../assets/New_NFT/Kardz/StandardCards/AssistCards/bca41.png'
import Bca42 from '../assets/New_NFT/Kardz/StandardCards/AssistCards/bca42.png'
import Bca43 from '../assets/New_NFT/Kardz/StandardCards/AssistCards/bca43.png'
import Bca44 from '../assets/New_NFT/Kardz/StandardCards/AssistCards/bca44.png'
import Bca45 from '../assets/New_NFT/Kardz/StandardCards/AssistCards/bca45.png'
import Bca46 from '../assets/New_NFT/Kardz/StandardCards/AssistCards/bca46.png'
import Bca47 from '../assets/New_NFT/Kardz/StandardCards/AssistCards/bca47.png'
import Bca48 from '../assets/New_NFT/Kardz/StandardCards/AssistCards/bca48.png'
import Bca49 from '../assets/New_NFT/Kardz/StandardCards/AssistCards/bca49.png'
import Bca50 from '../assets/New_NFT/Kardz/StandardCards/AssistCards/bca50.png'
import Bcc9 from '../assets/New_NFT/Kardz/StandardCards/ControllerCards/bcc9.png'
import Bcc10 from '../assets/New_NFT/Kardz/StandardCards/ControllerCards/bcc10.png'
import Bcc11 from '../assets/New_NFT/Kardz/StandardCards/ControllerCards/bcc11.png'
import Bcc12 from '../assets/New_NFT/Kardz/StandardCards/ControllerCards/bcc12.png'
import Bcc13 from '../assets/New_NFT/Kardz/StandardCards/ControllerCards/bcc13.png'
import Bcc14 from '../assets/New_NFT/Kardz/StandardCards/ControllerCards/bcc14.png'
import Bcc15 from '../assets/New_NFT/Kardz/StandardCards/ControllerCards/bcc15.png'
import Bcc16 from '../assets/New_NFT/Kardz/StandardCards/ControllerCards/bcc16.png'

export const DwoobxModalDataController = [
    {
        Filename: Dwoobx0001,
        Name: 'Bobbo',
        DreamDex: 'Dam.. Eazy! Hugga-jabubba!'
    },
    {
        Filename: Dwoobx0002,
        Name: 'Ade',
        DreamDex: 'Ya Whatever.. Lets just get this over'
    },
    {
        Filename: Dwoobx0003,
        Name: 'Kalo', DreamDex:
            'Dude.. This burn is contagious'
    }, {
        Filename: Dwoobx0004,
        Name: 'Sell',
        DreamDex: "Not so fast.. lol I'll be taking that win!"
    }, {
        Filename: Dwoobx0005,
        Name: 'Hen',
        DreamDex: 'BRrAAaaINNNnnn CRrrAmP'
    },
    {
        Filename: Dwoobx0006,
        Name: 'Tuna',
        DreamDex: 'All Drown... ..heh.. ...None Frown'
    },
    {
        Filename: Dwoobx0007,
        Name: 'Hanu',
        DreamDex: 'TAKE THAT BITCHES XD'
    },
    {
        Filename: Dwoobx0008,
        Name: 'L',
        DreamDex: 'Ha just you? 2 thrusts! and this is over.'
    },
    {
        Filename: Dwoobx0009,
        Name: 'Minotaurus',
        DreamDex: "You're So Weak Prepare Yourself!"
    },
    {
        Filename: Dwoobx0010,
        Name: 'Goblin King',
        DreamDex: 'My weeds will feast on your Planets corpse'
    },
    {
        Filename: Dwoobx0011,
        Name: 'Dragon',
        DreamDex: 'YOU DAMN BRATS! PLAY TIME IS OVER'
    },
    {
        Filename: Dwoobx0012,
        Name: 'Requiem',
        DreamDex: "I'll Build My Kingdom On Your Bones"
    },
    {
        Filename: Dwoobx0013,
        Name: 'Gaiden',
        DreamDex: 'Time To Meet Your Maker...'
    },
    {
        Filename: Dwoobx0014,
        Name: 'NeitMare',
        DreamDex: 'Ready My Nightmares The Dream is Dead'
    },
    {
        Filename: Dwoobx0015,
        Name: 'Overture',
        DreamDex: "You're so cute when you scream"
    },
    {
        Filename: Dwoobx0016,
        Name: 'Negative One',
        DreamDex: 'Did you know the dead feel pain?'
    },
    {
        Filename: Dwoobx0017,
        Name: 'Fabian',
        DreamDex: 'But... I have a magic wand'
    },
    {
        Filename: Dwoobx0018,
        Name: 'Marjorie',
        DreamDex: 'Born to be Queen of the Metaverse'
    },
    {
        Filename: Dwoobx0019,
        Name: 'Alts Anonymous',
        DreamDex: 'We are all just pixels in the metaverse'
    }, {
        Filename: Dwoobx0020,
        Name: 'Ethalorian',
        DreamDex: 'Do you want some Pink pills?'
    },
    {
        Filename: Dwoobx0021,
        Name: 'SwoleLuksoBags',
        DreamDex: 'This reality is mine to create'
    },
    {
        Filename: Dwoobx0022,
        Name: 'LEDFUT',
        DreamDex: 'Turn up for what?'
    },
    {
        Filename: Dwoobx0023,
        Name: 'YellowTurtle.eth',
        DreamDex: 'This is the Gwei'
    },
    {
        Filename: Dwoobx0024,
        Name: 'Sage',
        DreamDex: 'Exploring the Frontier'
    },
    {
        Filename: Dwoobx0025,
        Name: 'CryptoDaddi',
        DreamDex: "U can't kill my happiness right now!"
    },
    {
        Filename: Dwoobx0026,
        Name: 'WolfOfLukso',
        DreamDex: 'If you concentrate real hard, you can hear shorts getting liquidated.'
    }
]

export const DwoobxModalDataPlayers = [
    {
        Filename: Dwoobx0027,
        Name: 'Dilden',
        DreamDex: 'Did you rip your pants again?'
    },
    {
        Filename: Dwoobx0028,
        Name: 'Kurdt',
        DreamDex: 'NeHeHeHeHe bEhiNd U-'
    },
    {
        Filename: Dwoobx0029,
        Name: 'Epek',
        DreamDex: '.....................................whatever...'
    },
    {
        Filename: Dwoobx0030,
        Name: 'Jabroni',
        DreamDex: 'REEEEEEEEEEEEEEEE ITS OVER.'
    },
    {
        Filename: Dwoobx0031,
        Name: 'Graul',
        DreamDex: 'Do that again, see what happens.'
    },
    {
        Filename: Dwoobx0032,
        Name: 'Strada',
        DreamDex: 'Woozy? Maybe you should lay down'
    },
    {
        Filename: Dwoobx0033,
        Name: 'Denom',
        DreamDex: 'Another complacent puppet on a string.'
    },
    {
        Filename: Dwoobx0034,
        Name: 'Ange',
        DreamDex: "I hope you're wearing clean under armor!"
    },
    {
        Filename: Dwoobx0035,
        Name: 'Cid',
        DreamDex: '(chupuchupuchupu)'
    },
    {
        Filename: Dwoobx0036,
        Name: 'Victory',
        DreamDex: "Is that all you got? I'm going easy.."
    },
    {
        Filename: Dwoobx0037,
        Name: 'Bigg',
        DreamDex: 'Stand back.. Time for an exit.'
    },
    {
        Filename: Dwoobx0038,
        Name: 'Endy',
        DreamDex: "Looks like it's my turn to save the day."
    },
    {
        Filename: Dwoobx0039,
        Name: 'Kamron',
        DreamDex: 'Oooooooooooooooo K-k-k-k-k-k-kkkk'
    },
    {
        Filename: Dwoobx0040,
        Name: 'Athroh',
        DreamDex: 'Good and bad are just 2 words in vibration'
    },
    {
        Filename: Dwoobx0041,
        Name: 'Quazi',
        DreamDex: 'I hear their voices... calling in ur head'
    },
    {
        Filename: Dwoobx0042,
        Name: 'CornCezar',
        DreamDex: "Its all so ugly.. You're all ugly.. Its ...beautiful"
    },
    {
        Filename: Dwoobx0043,
        Name: 'Hill',
        DreamDex: 'Lets see how you can do against this!'
    },
    {
        Filename: Dwoobx0044,
        Name: 'Na',
        DreamDex: 'In another world... We could be Friends..'
    },
    {
        Filename: Dwoobx0045,
        Name: 'Cheetah Baby',
        DreamDex: 'HEYyyyyyy FOOOooLLL'
    },
    {
        Filename: Dwoobx0046,
        Name: 'Geodi',
        DreamDex: 'Would you like to hear it again? (Yes) (No)'
    },
    {
        Filename: Dwoobx0047,
        Name: 'Lareina',
        DreamDex: 'Hrmph, a new servant! Well? Get my things!'
    },
    {
        Filename: Dwoobx0048,
        Name: 'Jest',
        DreamDex: 'Your eyes r the same.. same as his..'
    },
    {
        Filename: Dwoobx0049,
        Name: 'Eremi',
        DreamDex: 'One more time.. Shut up & lets Jam'
    },
    {
        Filename: Dwoobx0050,
        Name: 'Kimbop',
        DreamDex: 'U do know ur odds of surviving this r 0%'
    },
    {
        Filename: Dwoobx0051,
        Name: 'Senior',
        DreamDex: "You'll Never see me coming..."
    },
    {
        Filename: Dwoobx0052,
        Name: 'Lew',
        DreamDex: '(A grey wind moans..) Soon, this ends.'
    },
    {
        Filename: Dwoobx0053,
        Name: 'Yekub',
        DreamDex: "Don't waste the exp.. No 1 escapes my trap"
    },
    {
        Filename: Dwoobx0054,
        Name: 'Nerv',
        DreamDex: 'U think u know me? I march my own path!'
    },
    {
        Filename: Dwoobx0055,
        Name: 'Kare',
        DreamDex: 'Team 1Up Blast off @ the speed of sound!'
    },
    {
        Filename: Dwoobx0056,
        Name: 'Kal',
        DreamDex: 'Surrender now... or ground & pound!'
    },
    {
        Filename: Dwoobx0057,
        Name: 'Elly',
        DreamDex: 'Give in to despair... My silk has you..'
    },
    {
        Filename: Dwoobx0058,
        Name: 'Lambert',
        DreamDex: 'I got a BIG canon! With ur name on it.'
    },
    {
        Filename: Dwoobx0059,
        Name: 'Lil V',
        DreamDex: "I don't fear you. I don't fear anyone"
    },
    {
        Filename: Dwoobx0060,
        Name: 'Maren',
        DreamDex: 'Winning losers like u is my greatest trait'
    },
    {
        Filename: Dwoobx0061,
        Name: 'Bellidia',
        DreamDex: "Impure blood can't wield what's coming"
    },
    {
        Filename: Dwoobx0062,
        Name: 'FOTRG',
        DreamDex: '..................................(howdy)....'
    },
    {
        Filename: Dwoobx0063,
        Name: 'Tiger Yin',
        DreamDex: 'In the land of the tigers .... Im the Yin!'
    },
    {
        Filename: Dwoobx0064,
        Name: 'Wibu',
        DreamDex: 'Unable to see reality is a garden of fairies'
    },
    {
        Filename: Dwoobx0065,
        Name: 'Tuxedo Hat',
        DreamDex: 'Ur so predictable... Not worth my time'
    },
    {
        Filename: Dwoobx0066,
        Name: 'Vest Guy',
        DreamDex: 'Want to know wat ur friends rly think of u?'
    },
    {
        Filename: Dwoobx0067,
        Name: 'Jubi',
        DreamDex: "Please!! Don't give up! Fight Fight Fight!"
    },
    {
        Filename: Dwoobx0068,
        Name: 'Ladle',
        DreamDex: 'haHA Ha Ha! Yer Done Son!'
    },
    {
        Filename: Dwoobx0069,
        Name: 'Maiki',
        DreamDex: 'Whoa cool move! Teach it to me?'
    },
    {
        Filename: Dwoobx0070,
        Name: 'Doughni',
        DreamDex: 'So outdated... Ur not gonna make it'
    },
    {
        Filename: Dwoobx0071,
        Name: 'Roka',
        DreamDex: 'You just got... Charged.'
    },
    {
        Filename: Dwoobx0072,
        Name: 'Koupe',
        DreamDex: 'Mmm.. TOASTY... U smell of bacon..'
    },
    {
        Filename: Dwoobx0073,
        Name: 'Lina', DreamDex: 'Ahhh.. Close ur eyes! Now make a wish!!'
    },
    {
        Filename: Dwoobx0074,
        Name: 'Moose',
        DreamDex: 'Looking troubled... Let me give u a hand'
    }
]


export const DwoobxModalDataAssists = [
    {
        Filename: Dwoobx0075,
        Name: 'Artico',
        DreamDex: 'Help me find my Courage'
    }, {
        Filename: Dwoobx0076,
        Name: 'Baba',
        DreamDex: 'Have you any wool? ><Y> <N>'
    },
    {
        Filename: Dwoobx0077,
        Name: 'Beta',
        DreamDex: 'Our people once knew how to fly...'
    },
    {
        Filename: Dwoobx0078,
        Name: 'Dayla',
        DreamDex: 'They say a star fell in the lake nearby'
    },
    {
        Filename: Dwoobx0079,
        Name: 'Death',
        DreamDex: 'A broken soul heals twice as strong'
    },
    {
        Filename: Dwoobx0080,
        Name: 'Eden',
        DreamDex: 'I will test your skills'
    },
    {
        Filename: Dwoobx0081,
        Name: 'Fan',
        DreamDex: "I've forgotten how it feels to be warm..."
    },
    {
        Filename: Dwoobx0082,
        Name: 'Flare',
        DreamDex: 'Life could be poison if you live long enough'
    },
    {
        Filename: Dwoobx0083,
        Name: 'Gargle',
        DreamDex: 'You seriously call that showing off?!'
    },
    {
        Filename: Dwoobx0084,
        Name: 'Gekaman',
        DreamDex: "You'll never be able to find me"
    },
    {
        Filename: Dwoobx0085,
        Name: 'Gideon',
        DreamDex: 'You should try to relax once in a while'
    },
    {
        Filename: Dwoobx0086,
        Name: 'Hishaku',
        DreamDex: 'Clean it up!'
    },
    {
        Filename: Dwoobx0087,
        Name: 'Jones',
        DreamDex: "You're really testing me aren't you?"
    },
    {
        Filename: Dwoobx0088,
        Name: 'Kammy',
        DreamDex: 'Oh yeah, this one takes me back'
    },
    {
        Filename: Dwoobx0089,
        Name: 'Kusanoha',
        DreamDex: 'Only animals can pass through my land!!'
    },
    {
        Filename: Dwoobx0090,
        Name: 'Lauren',
        DreamDex: 'Look into my eye'
    },
    {
        Filename: Dwoobx0091,
        Name: 'Lilly',
        DreamDex: 'If only someone would remove this boulder'
    },
    {
        Filename: Dwoobx0092,
        Name: 'Lin',
        DreamDex: 'Can you play with me?'
    },
    {
        Filename: Dwoobx0093,
        Name: 'Liquid Smoke',
        DreamDex: 'Remember why you must win'
    },
    {
        Filename: Dwoobx0094,
        Name: 'Mae',
        DreamDex: "What's the first thing you remember?"
    },
    {
        Filename: Dwoobx0095,
        Name: 'Meddle',
        DreamDex: 'You should try to walk a mile in my shoes'
    },
    {
        Filename: Dwoobx0096,
        Name: 'Nu',
        DreamDex: 'Your tough mind brings Brains over Brawn'
    },
    {
        Filename: Dwoobx0097,
        Name: 'Omegga',
        DreamDex: "Sooner or later you're gonna crack"
    },
    {
        Filename: Dwoobx0098,
        Name: 'Pawn',
        DreamDex: 'That was neat. Want to see it again?'
    },
    {
        Filename: Dwoobx0099,
        Name: 'Pear',
        DreamDex: "My hope lies in you. So don't let me down"
    },
    {
        Filename: Dwoobx0100,
        Name: 'Poa',
        DreamDex: 'Ur the roots of a tree, Strong and Unseen'
    },
    {
        Filename: Dwoobx0101,
        Name: 'Pye',
        DreamDex: "if you close your eyes they'll go away"
    },
    {
        Filename: Dwoobx0102,
        Name: 'Quil',
        DreamDex: "There's something odd about this tape"
    },
    {
        Filename: Dwoobx0103,
        Name: 'Raru',
        DreamDex: "There's a growing glow inside your eyes"
    },
    {
        Filename: Dwoobx0104,
        Name: 'Rocky',
        DreamDex: "I forgot everything, I'm free"
    },
    {
        Filename: Dwoobx0105,
        Name: 'Ruth',
        DreamDex: "Sir we're going to have to remove this"
    },
    {
        Filename: Dwoobx0106,
        Name: 'Sadie',
        DreamDex: "The same moves don't work on me twice"
    },
    {
        Filename: Dwoobx0107,
        Name: 'Sandra',
        DreamDex: 'You should get some rest'
    },
    {
        Filename: Dwoobx0108,
        Name: 'Ploom',
        DreamDex: 'Your energy is weak but it will do'
    },
    {
        Filename: Dwoobx0109,
        Name: 'Torobo',
        DreamDex: "Don't Worry I always got ur back"
    },
    {
        Filename: Dwoobx0110,
        Name: 'Trocity',
        DreamDex: 'You should be fearing the small things'
    },
    {
        Filename: Dwoobx0111,
        Name: 'Vest Guy',
        DreamDex: 'Why is this river glowing?'
    },
    {
        Filename: Dwoobx0112,
        Name: 'Wallflower',
        DreamDex: "You can't wake up yet ...Dream Again"
    },
    {
        Filename: Dwoobx0113,
        Name: 'Wifi',
        DreamDex: 'So much data all around. Inspiring'
    }, {
        Filename: Dwoobx0114,
        Name: 'Yami',
        DreamDex: "You're not done! Not you! Not yet!"
    },
    {
        Filename: Dwoobx0115,
        Name: 'Zaga',
        DreamDex: "It's A Long Way Down From The Top!!"
    },
    {
        Filename: Dwoobx0116,
        Name: 'Legend Man',
        DreamDex: 'Get Ready~ Whale Wave incoming!'
    },
    {
        Filename: Dwoobx0117,
        Name: 'Bloomer',
        DreamDex: 'Congrats! You made it Great Landing Ace!'
    },
    {
        Filename: Dwoobx0118,
        Name: 'Tomacho',
        DreamDex: 'Looks like its just us and the road...'
    },
    {
        Filename: Dwoobx0119,
        Name: 'Jipzy',
        DreamDex: 'Speak up or lose everything'
    },
    {
        Filename: Dwoobx0120,
        Name: 'Whim',
        DreamDex: 'Dude, Its time to get up We gotta go'
    },
    {
        Filename: Dwoobx0121,
        Name: 'Zoli',
        DreamDex: "Now you see it Now you don't"
    },
    {
        Filename: Dwoobx0122,
        Name: 'Brazer',
        DreamDex: "Keep at that and I'll put you in a grave"
    },
    {
        Filename: Dwoobx0123,
        Name: 'Kei',
        DreamDex: 'ITS TIME TO GET FIRED UP'
    },
    {
        Filename: Dwoobx0124,
        Name: 'Dr00L',
        DreamDex: 'Forcefield Activated'
    }
]


export const DwoobYModalData = [
    {
        Filename: Dwooby0001,
        Name: "Egghead",
        DreamDex: "Eggheads are found in nearly every village. Cute feathery magical and tasty. They lay capsule eggs filled with baby eggheads and sometimes treasures!",
        Numbering: "#1"
    },
    {
        Filename: Dwooby0002,
        Name: "Fire Flour",
        DreamDex: "Fire Flour sacks are an odd type of ghost. They leave a powder trail everywhere they go. They have flames for eyes and smell like fresh baked dough. They seem small, but they can stretch and grow.",
        Numbering: "#2"
    },
    {
        Filename: Dwooby0003,
        Name: "Kerokay",
        DreamDex: "Kerokays look very similar to the vegetation in the regions where they come from so are hard to notice at all. There vegetable like tentacles cause a really bad stunning effect when touched",
        Numbering: "#3"
    },
    {
        Filename: Dwooby0004,
        Name: "Slick Walk",
        DreamDex: "Slick Walks are strange creatures found in a pocket dimension in the DreamWorld. They have 2 brains located at opposite ends. The tail is the main brain while the one its head is the back up",
        Numbering: "#4"
    },
    {
        Filename: Dwooby0005,
        Name: "Dinoborg",
        DreamDex: "Dinoborgs live on a mountain made entirely from a crashed comet. They enjoy rolling down into its craters and using their weird brushy hand like tails to walk around. Their eyes can shoot a green laser that they often use to clear boulders from their paths",
        Numbering: "#5"
    },
    {
        Filename: Dwooby0006,
        Name: "Sound Ant",
        DreamDex: "Sound ants have a microphone type device attached to them but they can only mimic 5 seconds of speech. They have such tiny useless life's but an army of them can conduct an orchestra. They use psychological warfare on anyone who will go to war with them.",
        Numbering: "#6"
    },
    {
        Filename: Dwooby0007,
        Name: "Gruvvel",
        DreamDex: "Living in the prairies these vicious rodents hide behind tiny dirt mounds and kick sand clouds up when approached. They have a strong bushy tail they use to conduct the dirt particles into tiny storms",
        Numbering: "#7"
    },
    {
        Filename: Dwooby0008,
        Name: "Clowder",
        DreamDex: "Pet fighting fish. They are won at the fairs and come in many colors. Not strong at all but If you can make them breed you can get a gold Clowder that can be sold for a lot.",
        Numbering: "#8"
    },
    {
        Filename: Dwooby0009,
        Name: "Bush Hacker",
        DreamDex: "Bush hackers are no taller than a foot and have a very hypnotic dance. If you keep your eyes on them you will find yourself doing the Bush Hackers Dance as well.",
        Numbering: "#9"
    },
    {
        Filename: Dwooby0010,
        Name: "Roblin",
        DreamDex: "Chirpy little featherballs that like to hurl themselves like a baseball through windows and at threats",
        Numbering: "#10"
    },
    {
        Filename: Dwooby0011,
        Name: "Stareoid",
        DreamDex: "I see you mirin.. Stareoids are monsters made of eyeballs and muscles. They secrete a slimy body oil  for shining in the sun but it can be so slippery",
        Numbering: "#11"
    },
    {
        Filename: Dwooby0012,
        Name: "Hollar Dollar",
        DreamDex: "Burning cash takes on a new meaning with the Hollar Dollar. A phantom of the money spent wastefully has come back for revenge.",
        Numbering: "#12"
    },
    {
        Filename: Dwooby0013,
        Name: "Macro Sin",
        DreamDex: "Macro Sins are nightmares with eyes and teeth for hands. They Are as hard as steel and they take pleasure in torturing anything that slips into their realm",
        Numbering: "#13"
    },
    {
        Filename: Dwooby0014,
        Name: "Lekoi",
        DreamDex: "Lekois are fancy expensive fish that legend says can turn into beautiful maidens if treated well.",
        Numbering: "#14"
    },
    {
        Filename: Dwooby0015,
        Name: "Shell Slug",
        DreamDex: "Shell slugs are lazy pests crawling around ruining vegetation in their paths. They have a protective skin that exposes only the front of the body when it stands up.",
        Numbering: "#15"
    },
    {
        Filename: Dwooby0016,
        Name: "Brontacap",
        DreamDex: "Fungus turtles love dark humid forests. They live in the deepest parts and work to make their forest grow.",
        Numbering: "#16"
    },
    {
        Filename: Dwooby0017,
        Name: "Flint Mite",
        DreamDex: "Flint mites live in deep mining caves. These short agile creatures have no hands but they can jump very high. They can communicate with one another and glow in the dark.",
        Numbering: "#17"
    },
    {
        Filename: Dwooby0018,
        Name: "Warnut",
        DreamDex: "Sometimes cracking a walnut will cause it to crack back. Warnuts live in the woods and wear a walnut looking shell. Their voice is very muffled and they throw seeds from the trees.",
        Numbering: "#18"
    },
    {
        Filename: Dwooby0019,
        Name: "Nullture",
        DreamDex: "The nullture is a scavenger creature that absorbs energy from weak prey. It can not see like we see, it sees the world as energies.",
        Numbering: "#19"
    },
    {
        Filename: Dwooby0020,
        Name: "Rustle Tut",
        DreamDex: "Rustle Tuts have taken over sunken kingdoms in the sea so low and cold that ice caverns stay frozen and they have made it their own. To spot a rustle tut out of his environment is a very special sighting.",
        Numbering: "#20"
    },
    {
        Filename: Dwooby0021,
        Name: "Swask",
        DreamDex: "The swask lives to attack pezant biiz. These pesky insects serve a king swask making all these female",
        Numbering: "#21"
    },
    {
        Filename: Dwooby0022,
        Name: "Froof",
        DreamDex: "Froofs have very long curly tongues that blow out when they want attention. They have a small spikey set of horns on their head that resemble a crown",
        Numbering: "#22"
    },
    {
        Filename: Dwooby0023,
        Name: "Pocoral",
        DreamDex: "Pocoral are sea creatures floating around the water. They like to come up above sea level by using tiny holes on its outer shell that shoot out the water contained in its core. When it wants to sink back down the holes open and push water in",
        Numbering: "#23"
    },
    {
        Filename: Dwooby0024,
        Name: "Bush Bunny",
        DreamDex: "Bush bunnies like to rearrange the map of the woods by hopping their bushy tail around making paths disappear",
        Numbering: "#24"
    },
    {
        Filename: Dwooby0025,
        Name: "Night Imp",
        DreamDex: "Night imps are nightmares that can't help themselves from giving out clues of their plans. They have very good black magic and like to fly more than walk",
        Numbering: "#25"
    },
    {
        Filename: Dwooby0026,
        Name: "Grifter",
        DreamDex: "Grifters live on top the highest peaks of the DreamWorld and rarely ever go near the bottom. They want nothing to do with other species of the DreamWorld and prefer to be alone",
        Numbering: "#26"
    },
    {
        Filename: Dwooby0027,
        Name: "Core Dragon",
        DreamDex: "Core Dragons live within the planet where the warmth comes from the core not the sun. They breathe fire and their hair fibers are so strong at maintaining body temperatures that it can withstand lava",
        Numbering: "#27"
    },
    {
        Filename: Dwooby0028,
        Name: "Cherub",
        DreamDex: "Cherubs are guardians of all the creatures of the DreamWorld. They have lived for ages and live in the planets dreamwave, often choosing where a creature will spawn from.",
        Numbering: "#28"
    },
    {
        Filename: Dwooby0029,
        Name: "Stain Fly",
        DreamDex: "Stain Flys capture the rays of the sun and create beautiful shadows below themselves of dancing colored light.Their wings change shape as they flutter through the skies",
        Numbering: "#29"
    },
    {
        Filename: Dwooby0030,
        Name: "Wettle",
        DreamDex: "Wettles live in the snow and travel through the snow like a fish travels through water. They're very playful but make the ground unstable",
        Numbering: "#30"
    },
    {
        Filename: Dwooby0031,
        Name: "Wrisp Jest",
        DreamDex: "Wrisp Jests use their stinger to drill through wood, You often see them flying backwards shooting themselves at trees like darts. ",
        Numbering: "#31"
    },
    {
        Filename: Dwooby0032,
        Name: "Zzzebra",
        DreamDex: "the Zzzebras main changes pattern to black and white to white and black. They exist on a wavelength in between sleep and awake, so if you see one then most likely you are too tired to fight it. Some say these don't exist at all and they're entirely made up",
        Numbering: "#32"
    },
    {
        Filename: Dwooby0033,
        Name: "Gutter Gunk",
        DreamDex: "Gutter gunks are strong monsters that live in the sewers. They are a type of water elemental that has gone mad from dirty water. They use manholes as shields and attach themselves to the bottom of them.",
        Numbering: "#33"
    },
    {
        Filename: Dwooby0034,
        Name: "Neit Doze",
        DreamDex: "Neit Dozes are larva from the Lord of Nightmares himself. They can distort frequencies and like to attach themselves like parasites to corrupt anything.",
        Numbering: "#34"
    },
    {
        Filename: Dwooby0035,
        Name: "Flora Flea",
        DreamDex: "Flora Fleas like to attach themselves to great big trees and absorb their energy. They are very afraid so hard to battle or sneak up on since they have eyes around their whole body.",
        Numbering: "#35"
    },
    {
        Filename: Dwooby0036,
        Name: "Reaper",
        DreamDex: "Reaper Fairies exist in the nightmare realm usually and dissolve eXp by hovering around any energy source. Reaper fairies can waste an opponent's resources. The stolen energy can be returned if they are captured",
        Numbering: "#36"
    },
    {
        Filename: Dwooby0037,
        Name: "Exp Fairy",
        DreamDex: "eXp Fairies are beings of pure eXp energy. They often exist near locations on the planet that have a vein of energy exposed. If captured they can provide a big boost of energy",
        Numbering: "#37"
    },
    {
        Filename: Dwooby0038,
        Name: "Aphid",
        DreamDex: "Aphids are beastmen that live in the open fields and use the grassy blades on its heads like propellers to stay above land. These grass monsters are pests to anyone traveling through the fields",
        Numbering: "#38"
    },
    {
        Filename: Dwooby0039,
        Name: "Sky Dragon",
        DreamDex: "This ancient sky dragon is the master of all wind energy on the planet. It flies around all day purifying the air and sleeping on clouds. The halo on its head is an antenna that jams the planets frequency from reaching too far out into space to protect it from unwanted visitors",
        Numbering: "#39"
    },
    {
        Filename: Dwooby0040,
        Name: "Lost Change",
        DreamDex: "Lost change is a monster with pocket change all around it. Using money as a shield and shooting a laser from its eye",
        Numbering: "#40"
    },
    {
        Filename: Dwooby0041,
        Name: "Shadow Sapper",
        DreamDex: "Shadow sappers have long needles on their wrists that they use to absorb blood. Their bodies are made of many nightmares that want a taste.",
        Numbering: "#41"
    },
    {
        Filename: Dwooby0042,
        Name: "Tropical Scorpion",
        DreamDex: "Tropical scorpions live in palm trees and attach themselves to the back of the fruit grown from them. They are not poisonous but they have very sharp pincers and tail",
        Numbering: "#42"
    },
    {
        Filename: Dwooby0043,
        Name: "Red Knight",
        DreamDex: "Red Knights are puppets brought to life by the Kalo bloodlines ability to capture life and put it in other objects. They can be useful depending on who's controlling them",
        Numbering: "#43"
    },
    {
        Filename: Dwooby0044,
        Name: "Meat Vice",
        DreamDex: "Meat Vice is a dungeon monster that has metal teeth stretched over its face that come forward to squash anything when it attacks",
        Numbering: "#44"
    },
    {
        Filename: Dwooby0045,
        Name: "Paraslime",
        DreamDex: "A parasitic slime that hunts other slimes. It can not grow on its  own, so uses slimes to eat for it so it can travel and spread",
        Numbering: "#45"
    },
    {
        Filename: Dwooby0046,
        Name: "Dog",
        DreamDex: "A friendly Doggo that loves to play fetch and bury items. If you throw something it will catch it but it does not know how to bring it back.",
        Numbering: "#46"
    },
    {
        Filename: Dwooby0047,
        Name: "Whistle Thistle",
        DreamDex: "Whistle Thistles hide in the brush and push wind through their blades of grass to create loud whistles. The decibels are so loud it can damage your hearing.",
        Numbering: "#47"
    },
    {
        Filename: Dwooby0048,
        Name: "Space Phantom",
        DreamDex: "Space phantoms are tears in space where nightmare energy has gravitated to. They usually appear after Reaper Crystals are shattered.",
        Numbering: "#48"
    },
    {
        Filename: Dwooby0049,
        Name: "Poisetta",
        DreamDex: "Poisetta is a fruit from the Hydragora that wanders the forest looking for food and a place to grow.",
        Numbering: "#49"
    },
    {
        Filename: Dwooby0050,
        Name: "Hypnocoo",
        DreamDex: "Hypnocoos are common birds that are very low level psychics that use their power to be fed by the weak willed",
        Numbering: "#50"
    },
    {
        Filename: Dwooby0051,
        Name: "Shleep",
        DreamDex: "Shleep appear only in the clouds that are low enough to touch the mountains. They jump around causing more cloud activity and put mountains to sleep",
        Numbering: "#51"
    },
    {
        Filename: Dwooby0052,
        Name: "Yunicorn",
        DreamDex: "Yunicorns are very small horseflys that are found in rainbows on a hidden island. To get them to come out you'll have to have a lot of charm",
        Numbering: "#52"
    },
    {
        Filename: Dwooby0053,
        Name: "Queen Prawn",
        DreamDex: "Queen Prawns lives in the cold rivers near North Diakanon and they are usually always submerged with only their head and ears sticking out. They float around eating seakarats.",
        Numbering: "#53"
    },
    {
        Filename: Dwooby0054,
        Name: "Snow Puuti",
        DreamDex: "Snow Puutis can tune into low frequency dream waves and are often using that to disappear and reappear ",
        Numbering: "#54"
    },
    {
        Filename: Dwooby0055,
        Name: "Desert Walker",
        DreamDex: "Desert Walkers are not noticeable in the day  but at night you will see entire deserts rearranging themselves. These creatures sleep in the day and are most active at night.",
        Numbering: "#55"
    },
    {
        Filename: Dwooby0056,
        Name: "Berry Bird",
        DreamDex: "Berry Birds hang from fingers and trees with a tiny hook located on the top of their heads. When they sleep they curl themselves up into their wings and they look like berries hanging",
        Numbering: "#56"
    },
    {
        Filename: Dwooby0057,
        Name: "Puph",
        DreamDex: "Puphs are tiny spirits that usually appear in big numbers depending on how haunted or spooky a location is. They almost worship fearful things",
        Numbering: "#57"
    },
    {
        Filename: Dwooby0058,
        Name: "Quailiu",
        DreamDex: "Found on the field it's a dangerous bird to fight but worth it if you can get it to drop its feather. It expands and shoots quills and if knocked down it springs back up for recoil",
        Numbering: "#58"
    },
    {
        Filename: Dwooby0059,
        Name: "Spinnow",
        DreamDex: "Spinnow fins don't just look like seaweed but can control seaweeds movement in the water. They'll use a lot of it as cover to swim around in safety",
        Numbering: "#59"
    },
    {
        Filename: Dwooby0060,
        Name: "Glare Slime",
        DreamDex: "A teary eye with teeth. This slimelike monster looks small but it can stretch in an upward direction exposing a giant mouth.",
        Numbering: "#60"
    },
    {
        Filename: Dwooby0061,
        Name: "Gummy Skull",
        DreamDex: "Gummy Skulls float around in dark places looking for dead remains. Their favorite food is bones that they take the collagen out of.",
        Numbering: "#61"
    },
    {
        Filename: Dwooby0062,
        Name: "Pupperoni",
        DreamDex: "Sleepy pupperonis are found in relaxing shaded areas. Despite their look they do not taste like pizza. They're kind monsters and if they wake up they tend to disappear",
        Numbering: "#62"
    },
    {
        Filename: Dwooby0063,
        Name: "Stunny Munny",
        DreamDex: "Stunny munny doesn't leave anything to chance. If provoked it will stun its enemies at every attack. If physically attacked it will spin, and if it stops on its head side it can take much more damage.",
        Numbering: "#63"
    },
    {
        Filename: Dwooby0064,
        Name: "Coodabra",
        DreamDex: "Coodabras are more aggressive and higher leveled psychics than their smaller versions. Do not look into its eye or fall for its trance like swaying back and forth. ",
        Numbering: "#64"
    },
    {
        Filename: Dwooby0065,
        Name: "Wrist Watcher",
        DreamDex: "Wrist watchers are mechanical monsters that secure high level office buildings and use the shavings on its sides to slice and dice. ",
        Numbering: "#65"
    },
    {
        Filename: Dwooby0066,
        Name: "Funny Munny",
        DreamDex: "Funny Munny is what happens when a mimic realizes you can't help but want money so bad you'll pick it up from anywhere, even if it's worth practically nothing. They attach themselves to lost change that was abandoned and will never see their wish of being spent by the ones who earned it.",
        Numbering: "#66"
    },
    {
        Filename: Dwooby0067,
        Name: "Sewer Cap",
        DreamDex: "Sewer caps are city monsters that take the shape of manholes.They usually stay dormant unless if a dream energy that is high level walks nearby",
        Numbering: "#67"
    },
    {
        Filename: Dwooby0068,
        Name: "Mimic",
        DreamDex: "The low level item mimic is much different than the rest. It moves around locations hoping someone will open it. If it can surprise its guests then it will attack using its chest plated hands and feet",
        Numbering: "#68"
    },
    {
        Filename: Dwooby0069,
        Name: "Rolly Bro",
        DreamDex: "Rolly Bros have excellent defense and can retreat into its exoskeleton to protect it from all physical dmg",
        Numbering: "#69"
    },
    {
        Filename: Dwooby0070,
        Name: "Kilo Sin",
        DreamDex: "Kilo Sins are very difficult Nightmare monsters to defeat. If they see an item being used they will instantly disable it. They have a dangerous attack that comes from its eyes hands and mouth all at once.",
        Numbering: "#70"
    },
    {
        Filename: Dwooby0071,
        Name: "Neit Sentry",
        DreamDex: "Neit Sentry's seek out candidates for allies to help the Nightmares and will often bond themselves with dark spirited beings",
        Numbering: "#71"
    },
    {
        Filename: Dwooby0072,
        Name: "Golden Furaknid",
        DreamDex: "Golden Furaknids can be worth a lot of money if you find one that can spin web made of gold",
        Numbering: "#72"
    },
    {
        Filename: Dwooby0073,
        Name: "Nectar Specter",
        DreamDex: "Nectar Specters are Dream energy monsters that protect nature. They have a set of teeth and sword like arms that they use to preserve nature",
        Numbering: "#73"
    },
    {
        Filename: Dwooby0074,
        Name: "Fizzer Crystal",
        DreamDex: "Fizzer Crystals are found where there is a lot of eXp pollution in the area. Born from the exhaust of eXp machines and factories. They are unstable and will explode",
        Numbering: "#74"
    },
    {
        Filename: Dwooby0075,
        Name: "Triray",
        DreamDex: "Trirays come out of the ocean from time to time and fly around coastal cities causing trouble. They have a laser attack from their eye that can change style depending on the rotation of its front lens piece",
        Numbering: "#75"
    },
    {
        Filename: Dwooby0076,
        Name: "Fiscerator",
        DreamDex: "Fiscerators have elemental mud attacks that can create mud holes under your feet.",
        Numbering: "#76"
    },
    {
        Filename: Dwooby0077,
        Name: "Slick Spill",
        DreamDex: "Slick Spills can control time bubbles that can cast haste or slow around them. If the top of their heads are hit it will go to sleep",
        Numbering: "#77"
    },
    {
        Filename: Dwooby0078,
        Name: "Mood Slime",
        DreamDex: "Mood Slimes change color depending on the mood of people around them. Their elemental attack changes depending on the mood of its enemies.",
        Numbering: "#78"
    },
    {
        Filename: Dwooby0079,
        Name: "Turhandtula",
        DreamDex: "Turhandtula uses its hand like legs to crawl around. It can detach its hand like legs and abdomen and have it crawl around using a strong webbing cord to keep it all connected.",
        Numbering: "#79"
    },
    {
        Filename: Dwooby0080,
        Name: "Mandraga Sprout",
        DreamDex: "Screaming Plants that can lower luck levels and can cause berzerk state on opponents",
        Numbering: "#80"
    },
    {
        Filename: Dwooby0081,
        Name: "Armashello",
        DreamDex: "Armashellos see similar to bhats and have hearing sensors all around their body to listen to the smallest insect touching the ground. They can shoot stones out their mouth like canons",
        Numbering: "#81"
    },
    {
        Filename: Dwooby0082,
        Name: "Baboom",
        DreamDex: "Babooms are very Dangerous Contortionists and can switch between water and fire elemental attacks",
        Numbering: "#82"
    },
    {
        Filename: Dwooby0083,
        Name: "Bimple",
        DreamDex: "Bimple is a shapeshift slime that likes to take the form of anything it fights. Bimples can only do physical damage",
        Numbering: "#83"
    },
    {
        Filename: Dwooby0084,
        Name: "Gill Bird",
        DreamDex: "Gill Birds do not go far from water unless its looking for food. They can soar high above the oceans leaving to the legend of a giant whale that could fly in the skies",
        Numbering: "#84"
    },
    {
        Filename: Dwooby0085,
        Name: "Blight",
        DreamDex: "Blights live in dark caves and summon shadows to fight their battles. They cause blind status to opponents",
        Numbering: "#85"
    },
    {
        Filename: Dwooby0086,
        Name: "Bloop",
        DreamDex: "Bloops inflate like balloons for travel and can absorb all wind attacks.  Its true damage point is its eyes which it keeps closed to protect itself",
        Numbering: "#86"
    },
    {
        Filename: Dwooby0087,
        Name: "Bowcraze",
        DreamDex: "Bowcrazes travel across the earth disguised as flowers sticking out of the ground. They surf the land attacking any vehicles disrupting their territory",
        Numbering: "#87"
    },
    {
        Filename: Dwooby0088,
        Name: "Bullderbear",
        DreamDex: "Bullderbears get stronger the angrier they get. The more rage they have the more reckless they attack. They have very little evasion and can enter a confusion state at random.",
        Numbering: "#88"
    },
    {
        Filename: Dwooby0089,
        Name: "Paratweet",
        DreamDex: "Paratweets are giant creatures that can be mounted if tamed. They have dream and cosmic energy that it uses  to create healing light beams in the thickest darkest parts of the forest",
        Numbering: "#89"
    },
    {
        Filename: Dwooby0090,
        Name: "Candle Camel",
        DreamDex: "Candle Camels are its own oasis in the deserts. They have a long tail with a tree like umbrella at its end to give it shade. Its head has an oil that builds up into a crown that lights. They are a protected species and are not allowed to be hurt.",
        Numbering: "#90"
    },
    {
        Filename: Dwooby0091,
        Name: "Boos Wax",
        DreamDex: "Boos wax are spirits that can be summoned from flames. Usually useful in dark places but don't stare at its flame for too long. It will engulf you.",
        Numbering: "#91"
    },
    {
        Filename: Dwooby0092,
        Name: "Cheese Dragon",
        DreamDex: "A restaurant owning dragon with a kind face until you find out what the secret recipe is. Its true form has never been seen by anyone who lives to tell.",
        Numbering: "#92"
    },
    {
        Filename: Dwooby0093,
        Name: "Riot Rat",
        DreamDex: "Riot rats are demon controlled monsters. Nightmare rats that do not flee in battle. They have a vice like mouth that does not let go even after defeat.",
        Numbering: "#93"
    },
    {
        Filename: Dwooby0094,
        Name: "Sea Trotter",
        DreamDex: "Sea trotters exist in pocket dimensions located in the oceans of the dreamworld. They give oxygen to water and keep organisms alive. These creatures are great for aquariums and large tanks",
        Numbering: "#94"
    },
    {
        Filename: Dwooby0095,
        Name: "Cactress",
        DreamDex: "Cactress always come in threes. They kick up sand storms when they start to spin like a dance with one another as they shoot out needles.",
        Numbering: "#95"
    },
    {
        Filename: Dwooby0096,
        Name: "Dream Clam",
        DreamDex: "Dream Clams grow pearls inside that have properties that can capture creatures inside them. These pearls are harvested by many Beastmasters",
        Numbering: "#96"
    },
    {
        Filename: Dwooby0097,
        Name: "Dragon Kipper",
        DreamDex: "Dragon Kippers are often caught for good luck. If taken care of for many decades they can lay golden fish eggs.",
        Numbering: "#97"
    },
    {
        Filename: Dwooby0098,
        Name: "Roach Bro",
        DreamDex: "Roach bros are pests that live in dirty places. They can withstand many elemental attacks but are weak to shoes.",
        Numbering: "#98"
    },
    {
        Filename: Dwooby0099,
        Name: "Cracker Crab",
        DreamDex: "Cracker Crabs are constantly foaming and use their claws like daggers. THey can turn an entire battlefield into foam if a fight drags on too long",
        Numbering: "#99"
    },
    {
        Filename: Dwooby0100,
        Name: "Hunter Crab",
        DreamDex: "Hunter Crabs have a very thin shell covering their body that is flexible. They are fast and can create explosions with their claws and giant waves",
        Numbering: "#100"
    },
    {
        Filename: Dwooby0101,
        Name: "Echo Cricket",
        DreamDex: "Echo Crickets have a tiny speaker like body part that can shoot soundwaves at its opponents. They use this sound to help jump very high. If hit with a soundwave you could lose your hearing for a while.",
        Numbering: "#101"
    },
    {
        Filename: Dwooby0102,
        Name: "Zalad",
        DreamDex: "Zalads are slow fighters but have a very fast tail. It is used like a whip and has a poisoned leaf like tip on its tail. If hit with it a rash will break out on its opponents.",
        Numbering: "#102"
    },
    {
        Filename: Dwooby0103,
        Name: "Crockarock",
        DreamDex: "Crockarocks live in swampy areas and can open their mouths past its neck. Their bite gets tightened by the algae like skin around it that will wrap around its mouth ",
        Numbering: "#103"
    },
    {
        Filename: Dwooby0104,
        Name: "Dandledruff",
        DreamDex: "Dandruffs are tiny sprites from dream weeds that get lost in the wind. Legend has it if you see a dandruff in the wind to make a wish and hope the wind carries the dandruff to the heavens.",
        Numbering: "#104"
    },
    {
        Filename: Dwooby0105,
        Name: "Deery",
        DreamDex: "The Prince of the forest. Deerys are a nice sight to see but can take down a bullderbear if threatened enough. They have telekinesis and can do lightning attacks.",
        Numbering: "#105"
    },
    {
        Filename: Dwooby0106,
        Name: "Soi Bean",
        DreamDex: "Soi Beans can attack 3 times making them very difficult to fight when it's their turn. ",
        Numbering: "#106"
    },
    {
        Filename: Dwooby0107,
        Name: "T-Wreks",
        DreamDex: "T-Wreks is a carnivorous dinosaur that has extra padding layering its body. It is an excellent hunter despite its  giant size. Its light footed which helps it to sneak up on unsuspecting prey",
        Numbering: "#107"
    },
    {
        Filename: Dwooby0108,
        Name: "Golem Tooth",
        DreamDex: "Golem Teeth have sand pit fillings that can shoot out and take any form. It often uses a form of a hand to smack or grab its targets. Its tail is a spike chain root. The earth tooth is big making many wonder where the body of the golem is",
        Numbering: "#108"
    },
    {
        Filename: Dwooby0109,
        Name: "Dhuck",
        DreamDex: "Dhucks can be very aggressive creatures if left with no other options but to defend themselves. They use their webbed suction feet to absorb the water they stand on and shoot a pressured jet stream of water at targets. They can also hide eXp explosive dhuck eggs in the jet stream blast",
        Numbering: "#109"
    },
    {
        Filename: Dwooby0110,
        Name: "Dragill",
        DreamDex: "Dragills are a type of blue dragon sprite that lives in the ice cold waters. Using blue dragon energy it can have small ice elemental attacks",
        Numbering: "#110"
    },
    {
        Filename: Dwooby0111,
        Name: "Lazerfly",
        DreamDex: "Lazerflys shoot beams of eXp energy from its crystal body. It often leaves a trail of energetic light wherever it flies",
        Numbering: "#111"
    },
    {
        Filename: Dwooby0112,
        Name: "Dumpster Snake",
        DreamDex: "Dumpster snakes have 2 heads that both produce venom. If bitten it can cause paralysis",
        Numbering: "#112"
    },
    {
        Filename: Dwooby0113,
        Name: "Dust Fairy",
        DreamDex: "Dust Fairies are usually in old places that are never cleaned. They leave a dust over anything that would usually have no way of getting in if not for a dust fairy. They say they come from the DreamWorlds Moons",
        Numbering: "#113"
    },
    {
        Filename: Dwooby0114,
        Name: "Keeler",
        DreamDex: "The keeler is an underwater eel that can generate eXp. It has conductors in its body that discharges from its sides",
        Numbering: "#114"
    },
    {
        Filename: Dwooby0115,
        Name: "Wind Elemental",
        DreamDex: "Wind Elementals can cause little dirt devils but if enough gather together they can create giant tornados. They like to collect debris in their wind so it can cause extra damage",
        Numbering: "#115"
    },
    {
        Filename: Dwooby0116,
        Name: "Eskril",
        DreamDex: "Eskrils use their tail like a giant hand. They will use it as a fist or to climb trees. They are more commonly found in large cities",
        Numbering: "#116"
    },
    {
        Filename: Dwooby0117,
        Name: "Fauna",
        DreamDex: "The Fauna never likes to be planted it is a free roaming plant that is curious about the world. The seed is often gifted as an alternative pet",
        Numbering: "#117"
    },
    {
        Filename: Dwooby0118,
        Name: "Cat",
        DreamDex: "The Cat of the DreamWorld is usually everywhere people are. If you pet them they'll follow you around and they are good to have to keep rat battles away",
        Numbering: "#118"
    },
    {
        Filename: Dwooby0119,
        Name: "Unifillet",
        DreamDex: "Flesh eating glow fish. Its transparent skin glows and its teeth are extremely sharp. It has a glowing horn on its head",
        Numbering: "#119"
    },
    {
        Filename: Dwooby0120,
        Name: "Flamenko",
        DreamDex: "The Flamenco is an exotic and fancy bird that has long legs so it can wade in water. It is found in the pink rivers of the pink deserts and is related closely to the phoenix. It does not have the same magical properties but it can refine pink sands into eggs that can instantly max heal someone in need of a cure",
        Numbering: "#120"
    },
    {
        Filename: Dwooby0121,
        Name: "Floomer",
        DreamDex: "Floomers are fuzzy creatures that many often have as a pet. They can have random properties if held but you never know what it will add to you.",
        Numbering: "#121"
    },
    {
        Filename: Dwooby0122,
        Name: "Octafish",
        DreamDex: "Octafish is the baby stage of a much larger monster. At this stage it has not fully developed and has a much different energy source before its transformation. It can shoot a very dark ink smokescreen that can take shape of a monster of its own",
        Numbering: "#122"
    },
    {
        Filename: Dwooby0123,
        Name: "Frauhgy",
        DreamDex: "Frauhgy has warts on its head that resemble a crown and a small pom light on top that it uses to attract insects at night. Frauhgys are necessary in many black magic recipes",
        Numbering: "#123"
    },
    {
        Filename: Dwooby0124,
        Name: "TV Gazer",
        DreamDex: "TV Gazers are the puutis of the nightmare realm. Unlike puutis that follow around great people Gazers follow beings that they believe have great potential to do evil",
        Numbering: "#124"
    },
    {
        Filename: Dwooby0125,
        Name: "Genie Dog",
        DreamDex: "The genie dog is found in pocket dimensions in the clouds. In a realm of manifestation that can grant simple dreams. It has one eye meaning only one wish can be granted.",
        Numbering: "#125"
    },
    {
        Filename: Dwooby0126,
        Name: "Global Mole",
        DreamDex: "Global mole legends are said to be able to dig from one planets soil to another. They are excellent diggers and can dig through an entire planet",
        Numbering: "#126"
    },
    {
        Filename: Dwooby0127,
        Name: "Devil Goat",
        DreamDex: "Devil Goats cast many reflections of itself making it very difficult to tell which is the real one. Its actions are delayed on its reflections so pay attention to the goat that moves first",
        Numbering: "#127"
    },
    {
        Filename: Dwooby0128,
        Name: "Mourning Goat",
        DreamDex: "Mourning goats got their name because of the very sad sounds they make all day. Their horn circles their entire head and they are great mountain climbers",
        Numbering: "#128"
    },
    {
        Filename: Dwooby0129,
        Name: "Scuba Fin",
        DreamDex: "Scuba fins have bone spikes sticking out of its body for protection from predators. These fish are professional swimmers and can dive into deep waters with heavy pressures",
        Numbering: "#129"
    },
    {
        Filename: Dwooby0130,
        Name: "Furilla",
        DreamDex: "The Furilla is a beast that is very agile despite its size. It is strong enough to bend metal and it shoots metallic discs from its mouths",
        Numbering: "#130"
    },
    {
        Filename: Dwooby0131,
        Name: "Croaker",
        DreamDex: "Croakers are changeling frogs that can temporarily switch bodies with its targets",
        Numbering: "#131"
    },
    {
        Filename: Dwooby0132,
        Name: "Grrrm",
        DreamDex: "Grrrm have spiky drills on their heads and dart themselves at targets. Their accuracy is not too great so they often end up upside down sticking from the ground",
        Numbering: "#132"
    },
    {
        Filename: Dwooby0133,
        Name: "Gumbo",
        DreamDex: "Gumbos are magical plant creatures that are always in a good mood. If they find something useful they try to find someone to gift it to them. It is unknown if its 2 creatures or one",
        Numbering: "#133"
    },
    {
        Filename: Dwooby0134,
        Name: "Hamper",
        DreamDex: "Hampers are lazy rodents with extremely thick skulls they spend their days eating and digging small holes",
        Numbering: "#134"
    },
    {
        Filename: Dwooby0135,
        Name: "Hodgepodge",
        DreamDex: "Hodgepodges have a unique spikey back and flexible body that allows them to roll up into a ball so that only the spikes are visible. If you see one running at you then prepare for its spike ball attack.",
        Numbering: "#135"
    },
    {
        Filename: Dwooby0136,
        Name: "Huffalo",
        DreamDex: "Huffalos live on the windmill plains and have horns for horns. A bone from the horn grows into the mouth of the animal allowing it to blow. Huffalos can conduct the wind of the plains",
        Numbering: "#136"
    },
    {
        Filename: Dwooby0137,
        Name: "Hydra",
        DreamDex: "Hydras are saplings of the hydragora plant. They often have their vines growing out of control and will use your own energy against you.",
        Numbering: "#137"
    },
    {
        Filename: Dwooby0138,
        Name: "Gelbug",
        DreamDex: "The Gelbug crawls around leaving a slimy trail that kills any vegetation it travels across and replaces it with a unique flower",
        Numbering: "#138"
    },
    {
        Filename: Dwooby0139,
        Name: "Fantaroo",
        DreamDex: "Fantaroos are Dreamwave rodents that cast sleep and hover status on their targets",
        Numbering: "#139"
    },
    {
        Filename: Dwooby0140,
        Name: "Canerry",
        DreamDex: "Singing birds that can unlock doors and even find exits in places such as caves when there is none.",
        Numbering: "#140"
    },
    {
        Filename: Dwooby0141,
        Name: "Mozza",
        DreamDex: "Mozzas can stretch and twist. They roam around twirling and hopping to keep their body from getting cold. If their body temperature drops then they lose flexibility",
        Numbering: "#141"
    },
    {
        Filename: Dwooby0142,
        Name: "Kroach",
        DreamDex: "The Kroach can fly and has very high defense. This is not a bug that can be squashed. It is only damaged by toxic gas.",
        Numbering: "#142"
    },
    {
        Filename: Dwooby0143,
        Name: "Lab Rat",
        DreamDex: "Lab rats were tested on at Pink Pennant and escaped. They are much more intelligent and evil than their counterparts. They find other ways of attacking by first locking doors behind their targets so they can't escape",
        Numbering: "#143"
    },
    {
        Filename: Dwooby0144,
        Name: "Polkabug",
        DreamDex: "Polkabugs eat bad luck energy but no one knows where that energy goes after they consume it. Some say it warps its way to others so there's always the same amount in the universe ",
        Numbering: "#144"
    },
    {
        Filename: Dwooby0145,
        Name: "Sleach",
        DreamDex: "The Sleach absorbs eXp and Health and makes its targets unable to cure themselves. Its immune to all poisons",
        Numbering: "#145"
    },
    {
        Filename: Dwooby0146,
        Name: "Royal Khat",
        DreamDex: "The Royal Khat is king of the animals. It can erupt volcanoes with its roars and make lava shoot from the earth.",
        Numbering: "#146"
    },
    {
        Filename: Dwooby0147,
        Name: "Red Pincher",
        DreamDex: "Red Pinchers will counter attack any time its tail is up. It's got a giant claw that can slash waves of energy. ",
        Numbering: "#147"
    },
    {
        Filename: Dwooby0148,
        Name: "Mangoka",
        DreamDex: "Mangokas are wild tropical birds but can make excellent pets. They can mimic speech and spells",
        Numbering: "#148"
    },
    {
        Filename: Dwooby0149,
        Name: "Demon Ray",
        DreamDex: "Demon rays are nightmares that attack from above. They shoot straight down and immediately turn into smoke after impact",
        Numbering: "#149"
    },
    {
        Filename: Dwooby0150,
        Name: "Neit Mirror",
        DreamDex: "Neit Mirrors are made of a foreign material from the DreamWorld that can be used to bring nightmares into the regular realm",
        Numbering: "#150"
    },
    {
        Filename: Dwooby0151,
        Name: "Mongi",
        DreamDex: "Mongis have 2 extra hands sticking out its back like tails. They use their extra arms like slingshots to shoot it into the sky",
        Numbering: "#151"
    },
    {
        Filename: Dwooby0152,
        Name: "Muzmux",
        DreamDex: "The Muzmux is the most used mount in the DreamWorld. It has tiny wings and is unable to fly but a perfect breeder can create the flying Muzmux",
        Numbering: "#152"
    },
    {
        Filename: Dwooby0153,
        Name: "Nestor",
        DreamDex: "Nestor monsters look like branches and nests but it actually eats unsuspecting birds that take refuge in its mouths.",
        Numbering: "#153"
    },
    {
        Filename: Dwooby0154,
        Name: "Pacoon",
        DreamDex: "Pacoons have a strong electrical current within their body. They use this to perform electrical attacks",
        Numbering: "#154"
    },
    {
        Filename: Dwooby0155,
        Name: "Octodock",
        DreamDex: "Octodocks are ocean creatures that have a giant monster hidden inside them. The tentacles extend and can stun its target",
        Numbering: "#155"
    },
    {
        Filename: Dwooby0156,
        Name: "Orb Glorb",
        DreamDex: "They produce a natural glow inside. These can stick on anything at will but once they do a chemical inside them begins to mix with another and a chain reaction causes the orb glorb to blow up",
        Numbering: "#156"
    },
    {
        Filename: Dwooby0157,
        Name: "Moyster",
        DreamDex: "Moysters like to use their legs like blades and spin on the floor using their tongues.",
        Numbering: "#157"
    },
    {
        Filename: Dwooby0158,
        Name: "Fire Hog",
        DreamDex: "Fire Hogs can become full flamed beings and shoot fireballs or charge at targets.",
        Numbering: "#158"
    },
    {
        Filename: Dwooby0159,
        Name: "Pindi",
        DreamDex: "Pindis are said to be very intelligent. They are usually chewing on long sticked plants that they sharpen into spear like objects. They say its eyes are actually located on its arms",
        Numbering: "#159"
    },
    {
        Filename: Dwooby0160,
        Name: "Glick Glock",
        DreamDex: "Glick Glocks sit on tree branches waiting for someone to make eye contact with it. Once you've made contact it begins to petrify your body",
        Numbering: "#160"
    },
    {
        Filename: Dwooby0161,
        Name: "Pigapillar",
        DreamDex: "Pigapillar is the larva stage for Paratweets. It can produce a rare silk used in the crafting of armor and weapons",
        Numbering: "#161"
    },
    {
        Filename: Dwooby0162,
        Name: "Pongan",
        DreamDex: "Mounts used in snowy areas. They are excellent for crossing snowy mountains and icy lakes",
        Numbering: "#162"
    },
    {
        Filename: Dwooby0163,
        Name: "Rat Lord",
        DreamDex: "The Rat lord rules over all the rats. These are very high level and can summon the rats in its kingdom to do anything it wants",
        Numbering: "#163"
    },
    {
        Filename: Dwooby0164,
        Name: "Prowlie",
        DreamDex: "Prowlies roll around all day building up energy. When they see outsiders they shoot a giant beam from the opening in their shell. This causes them to roll back.",
        Numbering: "#164"
    },
    {
        Filename: Dwooby0165,
        Name: "Forest Puppet",
        DreamDex: "Tree Puppets are masters at battle. It is unknown if it battles with its own mind or if there is a puppet master.",
        Numbering: "#165"
    },
    {
        Filename: Dwooby0166,
        Name: "Purrball",
        DreamDex: "Purrballs have a wing like cape that can become as hard as steel and take shapes",
        Numbering: "#166"
    },
    {
        Filename: Dwooby0167,
        Name: "Turtle Pot",
        DreamDex: "Turtle Pots have the ultimate defense. They do not hide in their shells but use it as a form of attack. They spin in place with great force and launch",
        Numbering: "#167"
    },
    {
        Filename: Dwooby0168,
        Name: "Visitor",
        DreamDex: "Visitors are alien to the DreamWorld. It is unknown if they are Dream energy or Nightmare energy. They do not attack, just visit.Perhaps they have knowledge of other planets existing in the universe that are growing their own dream seeds",
        Numbering: "#168"
    },
    {
        Filename: Dwooby0169,
        Name: "Rhinozerus",
        DreamDex: "Battle ready and dangerous. Living near the hot volcanic regions of the DreamWorld where nothing but brimstone exists. Their under skin texture is actually a coil that can absorb heat and shoot it out",
        Numbering: "#169"
    },
    {
        Filename: Dwooby0170,
        Name: "Seaskull",
        DreamDex: "Seaskulls fly around with a gang mentality. They'll steal any item or food they see. If you see a flock of them over the ocean it usually means something good is in the water in that direction.",
        Numbering: "#170"
    },
    {
        Filename: Dwooby0171,
        Name: "Starsite",
        DreamDex: "Starsites attach themselves to bigger creatures and take control over their actions. A starsite can feed off its host energy for years.",
        Numbering: "#171"
    },
    {
        Filename: Dwooby0172,
        Name: "Sharakon",
        DreamDex: "Kings of the Ocean. Vicious and fast. They have psychic powers that can control the fish as their armies or food.",
        Numbering: "#172"
    },
    {
        Filename: Dwooby0173,
        Name: "Baabaa",
        DreamDex: "Baabaas can hover for limited amounts of time. They can close up the hood covering their face with their swirly horns in front of them and charge at enemies.",
        Numbering: "#173"
    },
    {
        Filename: Dwooby0174,
        Name: "Shoo",
        DreamDex: "Low level Shoos are annoying pests. They attack without knowing and their big eye looks like they're tools for spying.",
        Numbering: "#174"
    },
    {
        Filename: Dwooby0175,
        Name: "Shrubpup",
        DreamDex: "Shrub pups can be dangerous if fought in packs. If one approaches its best to take it out before more follow.",
        Numbering: "#175"
    },
    {
        Filename: Dwooby0176,
        Name: "Skoosh",
        DreamDex: "Field rodents always scavenging for food. They have 2 limbs on top of their head that are used for foraging. They are hard to catch and love to hit and run",
        Numbering: "#176"
    },
    {
        Filename: Dwooby0177,
        Name: "Croozer",
        DreamDex: "Sky Dragon Air mounts.. Said to be mounts by a hidden ninja village in the clouds. No one's ever seen it but the dragons are proof it must exist",
        Numbering: "#177"
    },
    {
        Filename: Dwooby0178,
        Name: "Slaying Mantis",
        DreamDex: "Slaying Mantis is always up for a fight. Not territorial but very aggressive creatures that are always looking for trouble. They have a very unique style of attacking",
        Numbering: "#178"
    },
    {
        Filename: Dwooby0179,
        Name: "Slime",
        DreamDex: "Common Garden slime that will eat any vegetation or bug. It doesn't really enjoy eating anything salty.",
        Numbering: "#179"
    },
    {
        Filename: Dwooby0180,
        Name: "Alkali Slime",
        DreamDex: "Alkali slimes don't really digest organic matter, instead they love to eat anything mechanical or electronic.",
        Numbering: "#180"
    },
    {
        Filename: Dwooby0181,
        Name: "SmoozMux",
        DreamDex: "Smoosmux is a useful farming beast that can be used for food and milking. Its milk can restore life and its hide is used for many things. If attacked it can get madmux disease so its best to treat these beasts nice",
        Numbering: "#181"
    },
    {
        Filename: Dwooby0182,
        Name: "Snomole",
        DreamDex: "Snomoles live in cold climates and tunnel their ways through ice. They are immune to the cold and make giant ice caverns.",
        Numbering: "#182"
    },
    {
        Filename: Dwooby0183,
        Name: "Snoozecap",
        DreamDex: "So sleepy.. Snoozecaps walk around spreading a mist in the woods that can make trees and other spirits rest.",
        Numbering: "#183"
    },
    {
        Filename: Dwooby0184,
        Name: "Squip",
        DreamDex: "Squips always grab anything near it no matter what. Once attached its very hard to separate. They like to feast on anything it can fit into its mouth",
        Numbering: "#184"
    },
    {
        Filename: Dwooby0185,
        Name: "Saimunk",
        DreamDex: "Saimunks have rubber like bones and fur that goes over them like a coat. They can stretch their coat to put food for transporting inside.",
        Numbering: "#185"
    },
    {
        Filename: Dwooby0186,
        Name: "Starphin",
        DreamDex: "Water mounts that are trained to take travelers across entire oceans on their backs. They're fast and can be used in freshwater.",
        Numbering: "#186"
    },
    {
        Filename: Dwooby0187,
        Name: "Stumbs",
        DreamDex: "Stumbs live near rivers and lake beds. They like to eat entire trees and build great cabins. They live underground when not near water and stick their heads out looking like tree trunks.",
        Numbering: "#187"
    },
    {
        Filename: Dwooby0188,
        Name: "Dream Eater",
        DreamDex: "Dream Mites love the taste of dreams. They hang out in the dreamwaves and eat any unwanted dreams drifting in its space",
        Numbering: "#188"
    },
    {
        Filename: Dwooby0189,
        Name: "Thwombat",
        DreamDex: "Thwombats use their tail to drill into the ground and use it like a spring. If attacked they can bounce back and hit for double damage",
        Numbering: "#189"
    },
    {
        Filename: Dwooby0190,
        Name: "Toadstew",
        DreamDex: "Toadstews are giant mushroom toads that can manifest weapons at will if it sees one in its view",
        Numbering: "#190"
    },
    {
        Filename: Dwooby0191,
        Name: "Tropachan",
        DreamDex: "Tropcachans are found on exotic islands and hate being captured. Only the rare fruit of pom pom trees can get them to come close.",
        Numbering: "#191"
    },
    {
        Filename: Dwooby0192,
        Name: "Trunks",
        DreamDex: "In the Old jungle there is a section of it that moves. For a long time people didn't know why, so they answered it as magic. Only till recently has it been discovered that giant beasts live in the jungles with entire canopys on top of them and some with castles on their back",
        Numbering: "#192"
    },
    {
        Filename: Dwooby0193,
        Name: "Puuti Seeker",
        DreamDex: "Puuti Seekers are everywhere in the DreamWorld but never seen. They hide everywhere, jumping into cosmic holes and looking for great warriors or peace makers. If you ever see a Puuti near you it means it has recognized your existence as a great change to the world that needs to be written down for history.",
        Numbering: "#193"
    },
    {
        Filename: Dwooby0194,
        Name: "Puuti Logger",
        DreamDex: "Puuti Loggers never leave their base. They work tirelessly in their library logging everything Seekers have taken down. If anything is showing great change to world events they will let the boss know.",
        Numbering: "#194"
    },
    {
        Filename: Dwooby0195,
        Name: "Puuti Boss",
        DreamDex: "The Grand Poobah of all stories that get logged in the DreamWorld. He has direct power to delete anything from written history or alter it. He manages the Lower leveled Puutis and gives the final touch of creating Legends",
        Numbering: "#195"
    }
]


export const KardzModalData = [
    {
        Dwoobyx: "dwoobyx - 0227",
        Filename: Bcca,
        Numbering: "#A",
        Name: "Fabian",
        DreamDex: "After appearing in the DreamWorld Fabians real life skills manifest to powers. Using his Magic wand he begins to terraform unused land in the DreamWorld to make his own Kingdom"
    },
    {
        Dwoobyx: "dwoobyx - 0228",
        Filename: Bccb,
        Numbering: "#B",
        Name: "Marjorie",
        DreamDex: "When Marjorie appeared in the DreamWorld she unfortunately came without her luggage. Surveying village after village she found an ancient armor built by a lost civilization. After which she went on to defeat the highest leveled opponents. Never has someone been beat so badly by someone so stylish."
    },
    {
        Dwoobyx: "dwoobyx - 0229",
        Filename: Bccc,
        Numbering: "#C",
        Name: "Alts Anonymous",
        DreamDex: "Alts Anonymous was no stranger to different worlds.. Quickly he saw the realms in the DreamWorld that are hidden to the naked eye. Tuning into the Nightmare frequency he consumed its negative energy and watched as the planet went to war with itself. Aiding his allies in the shadows and keeping mostly to himself."
    },
    {
        Dwoobyx: "dwoobyx - 0230",
        Filename: Bccd,
        Numbering: "#D",
        Name: "Ethalorian",
        DreamDex: "Upon crash landing on the DreamWorld Ethalorian found himself in his own DreamWorld.. Landing in the Pink sands of the Phoenix ashes Ethalorian quickly went to work collecting samples and synthesizing pink sand pills. Researching and discovering things that not even the smartest minds on the DreamWorld could ever find."
    },
    {
        Dwoobyx: "dwoobyx - 0231",
        Filename: Bcce,
        Numbering: "#E",
        Name: "SwoleLuksoBags",
        DreamDex: "Unlike the other StarWorlders, Swole entered a portal to the DreamWorld. Seeing a tear in space shine like gold compelled him to enter. On the other side was the sky pirates treasure as far as the eyes can see.. He knows now the only way to keep it is to defeat everyone."
    },
    {
        Dwoobyx: "dwoobyx - 0232",
        Filename: Bccf,
        Numbering: "#F",
        Name: "LEDFUT",
        DreamDex: "Ledfut took a new form when entering the DreamWorld.. Drawn to it by its vibrations, he could hear a pattern stabilizing its reality. Easily he could feel the planet was alive and sleeping. He could hear the hidden lullaby playing in the DreamWave. Great musicians were also on the DreamWorld.. Maybe in a collab they could figure out if the Cryptoverse had a hidden song as well?"
    },
    {
        Dwoobyx: "dwoobyx - 0233",
        Filename: Bccg,
        Numbering: "#G",
        Name: "YellowTurtle.eth",
        DreamDex: "YellowTurtle was minding his own business in the Cryptoverse until a sudden bolt from the sky struck him. When he awoke and opened his eyes he saw he was In the middle of a colosseum. With fans cheering him on to play the DreamWorld sport of Warball, he decided to accept that it must be a weird dream or he must've died. He joins the game and wins it, not knowing it's all real."
    },
    {
        Dwoobyx: "dwoobyx - 0234",
        Filename: Bcch,
        Numbering: "#H",
        Name: "Sage",
        DreamDex: "Sage appeared on the DreamWorld moments before it's inevitable crash into the Cryptoverse. With the gift of foresight he came to warn many but was too late. After getting captured in the DreamWorld with all the rest. He took time to study the laws and rules of the planet. Quickly understanding more than the others how to win. However Freeing the DreamWorld did not seem to be his priority. He wishes to see every part of it before it's too late again."
    },
    {
        Dwoobyx: "dwoobyx - 0235",
        Filename: Bcci,
        Numbering: "#I",
        Name: "CryptoDaddi",
        DreamDex: "Ha! Cryptodaddis seen worse. You call this a galactic calamity? He calls this a tuesday. This isn't cryptodaddis first rodeo. Where some see misfortune he sees opportunity. Quickly he builds up his eXp and starts power leveling to capped levels. Loving every move he unlocks he becomes one of the Starworlders best battlers. The last thing you'll see before you respawn is the smile of a GladMan"
    },
    {
        Dwoobyx: "dwoobyx - 0236",
        Filename: Bccj,
        Numbering: "#J",
        Name: "WolfOfLukso",
        DreamDex: "Wolf of Lukso was browsing the Cryptoverse when he stumbled upon the DreamWorld. Uninterested to investigate further than viewing from the sky he realized he was trapped inside. \"How troublesome\" was his first thoughts. Trying to make good of a bad situation he went down to the busy Fairy County City and cleared out a bar full of DownTown Dragons. He sat at the bar and turned on the TV \"This is where I'll wait until this planet pops and I can get out of here\" He takes a drink and his kitty takes a smoke."
    },
    {
        Dwoobyx: "dwoobyx-0001",
        Filename: Bcc1,
        Numbering: "#1",
        Name: "Bobbo",
        DreamDex: "Our trustworthy, lazy, and unreliable dreamer who shines last. A mysterious anomaly to the planet which is threatened by his existence. He sets out on an adventure by his fascination of people who dare to adventure. Inspired and hoping somewhere in this that he too can find what it means to be an adventurer. Why does he fight? What is a Bobbo? He may never know."
    },
    {
        Dwoobyx: "dwoobyx-0002",
        Filename: Bcc2,
        Numbering: "#2",
        Name: "Ade",
        DreamDex: "Son of a great adventurer. Impatient, peaceful, and responsible. The treasures he takes on his journey include his boomerang, his dad's set of keys, and a map of 99 treasures he must find on a checklist. What a strange item that was in the first one... He sets out on his adventure to relive stories of history. To adventure because of fame and glory of the past."
    },
    {
        Dwoobyx: "dwoobyx-0003",
        Filename: Bcc3,
        Numbering: "#3",
        Name: "Kalo",
        DreamDex: "From a noble family of Dragonslayers. Kalo was not really one for dangerous situations. One day he took the ornamental dormant sword from its mantle and the sacred sword activated! A trapped dragon inside tried to possess him, but Kalos' will wouldn't have it. He wants to be an adventurer to go out and experience what the world has to offer while he can. To adventure to feel content for the future. Fun Times!"
    },
    {
        Dwoobyx: "dwoobyx-0004",
        Filename: Bcc4,
        Numbering: "#4",
        Name: "Sell",
        DreamDex: "1 of 4 from a gang called the Headless Horsemen, working for a mercenary guild that gets sold out by their leader. He vows revenge but meets t7 along the way. Proving he's more, he joins their group. Mischievous, cool, and random, he always knows everyone's weakness. He can be the champion but loves the midcard. Careful, he might sell you out! or is he? An adventure to prove his character is in control and can change."
    },
    {
        Dwoobyx: "dwoobyx-0005",
        Filename: Bcc5,
        Numbering: "#5",
        Name: "Hen",
        DreamDex: "1 of the few of a lost species that are not of this dimension. Proud and good hearted. Wielding space and summoning Mythical beasts. The full moon prince is the strongest of the DreamWorlders, not just at might but at his humbleness. An adventurer that sets out for the experience of feeding his curiosity and testing his strength so he can grow. This is what it means to be an adventurer."
    },
    {
        Dwoobyx: "dwoobyx-0006",
        Filename: Bcc6,
        Numbering: "#6",
        Name: "Tuna",
        DreamDex: "Cursed by a nightmare and overlooked by the world. He sets out with his scythe to make a memory in everyone's hearts. A dark knight relying on hard work to be normal. In battle with himself and the curse of his gifts. Rival to Bobbo, he struggles keeping up with the natural talents of his friends to be an adventurer. Yet he pulls it off to steal the show when the time comes."
    },
    {
        Dwoobyx: "dwoobyx-0007",
        Filename: Bcc7,
        Numbering: "#7",
        Name: "Hanu",
        DreamDex: "Dedicating his life to helping others. Even letting a terrestrial parasite use his body as a host. Vu is a master of force fields, cures, antidotes, and poisons. Using a plus staff and projecting his parasites healing powers. An adventurer who's unable to say no, so his adventures always advance. An adventure can not begin if you refuse to begin it or continue."
    },
    {
        Dwoobyx: "dwoobyx-0008",
        Filename: Bcc8,
        Numbering: "#8",
        Name: "L",
        DreamDex: "An ability to come back from the dead. This catman is always in the shadow of his rival Sell, so he trains in the shade. Mocked for not having his own abilities and always losing. It fuels his competitive spirit. Rejected from the Fronteers, so The DreamWorlders adopt this cat. He's a lover not an adventurer, but still he has to try. even if it's scary... No matter how many times you fail the adventurer continues against the inevitable."
    },
    {
        Dwoobyx: "dwoobyx-0009",
        Filename: Bcp1,
        Numbering: "#9",
        Name: "Dilden",
        DreamDex: "Unable to find his place in the world so he joins the Fronteers. A group wishing to become the greatest adventurers of hometown, but first they have to take out their competition."
    },
    {
        Dwoobyx: "dwoobyx-0010",
        Filename: Bcp2,
        Numbering: "#10",
        Name: "Kurdt",
        DreamDex: "A genius held back by the damage of his incurable wildness. Filled with inner strife and personal trauma, he realizes life isn't a tragedy it's a comedy. Some small towns still ban these anomies."
    },
    {
        Dwoobyx: "dwoobyx-0011",
        Filename: Bcp3,
        Numbering: "#11",
        Name: "Epek",
        DreamDex: "Quiet and doesn't talk much but get him on his windmill and he takes off! He is from a race called the Solkin that are inverted in relationship from the phoenix. They say Solkins store their personality in their own shadows and release it as an energy."
    },
    {
        Dwoobyx: "dwoobyx-0012",
        Filename: Bcp4,
        Numbering: "#12",
        Name: "Jabroni",
        DreamDex: "S-So MUCH POWER. A frog that wishes to fly but can only jump so high. No one takes him serious. He has dreams of working for Grotto Gear and piloting a ship that can touch the stars in the sky."
    },
    {
        Dwoobyx: "dwoobyx-0013",
        Filename: Bcp5,
        Numbering: "#13",
        Name: "Graul",
        DreamDex: "Leader of the Fronteers and the hometown bully. He's always finding people lower leveled than himself to pick on. Maybe there's a reason though?"
    },
    {
        Dwoobyx: "dwoobyx-0014",
        Filename: Bcp6,
        Numbering: "#14",
        Name: "Strada",
        DreamDex: "1/2 of a tag team. These are pretty nice guys... But lately something's been odd about them and for some reason they're scared of Cell Gels. Their eXp level seems higher than usual as well. Careful for his stinging sword! It somehow drains eXp."
    },
    {
        Dwoobyx: "dwoobyx-0015",
        Filename: Bcp7,
        Numbering: "#15",
        Name: "Denom",
        DreamDex: "2nd half of the tag team! There's something dark growing inside him.. I know he can defeat the nightmares within. His voice doesn't sound like his own but are the puppet strings his own?"
    },
    {
        Dwoobyx: "dwoobyx-0016",
        Filename: Bcp8,
        Numbering: "#16",
        Name: "Ange",
        DreamDex: "A master crafter that repairs and improves gear. Runs a small armor shop in Fairy County and is always in need of new material. She wants to create a perfect armor that's agile, impenetrable and protects the wearer. Careful falling asleep near her because she'll use your body as a blueprint for new ideas."
    },
    {
        Dwoobyx: "dwoobyx-0017",
        Filename: Bcp9,
        Numbering: "#17",
        Name: "Cid",
        DreamDex: "Baby of Ange - While she is busy improving armor stats, she asks her brother Ade to watch him. Easy? No.. He always takes Ade for an adventure that threatens both of their safety. A very promising youth that loves air travel."
    },
    {
        Dwoobyx: "dwoobyx-0018",
        Filename: Bcp10,
        Numbering: "#18",
        Name: "Victory",
        DreamDex: "A champion boxer with fighting spirit! Brother to Sell and Husband to Ange. He's a member of the headless horseman, a now ex thief clan turned resistance against the Qry-Stell Company."
    },
    {
        Dwoobyx: "dwoobyx-0019",
        Filename: Bcp11,
        Numbering: "#19",
        Name: "Bigg",
        DreamDex: "An elite hacker known more for taking things apart than he is at constructing them. If you need a way in he can do it on the spot. He hates timed missions but he works better under pressure. "
    },
    {
        Dwoobyx: "dwoobyx-0020",
        Filename: Bcp12,
        Numbering: "#20",
        Name: "Endy",
        DreamDex: "A speedy silent guy who is one of the many librarians that work for the Puutis. Categorizing the planets records. He was sent to document Hens adventurers for the save crystal when he had to break the rules and interfere in Hens battle. He breaks out of his data from the save crystal and creates a form for himself."
    },
    {
        Dwoobyx: "dwoobyx-0021",
        Filename: Bcp13,
        Numbering: "#21",
        Name: "Kamron",
        DreamDex: "Designed by the creator of Pink Pennant to resemble the creators deceases son. Made from the tech of a lost civilization. This robot is found in a pile of junk by Maiki. When turned on the robot announces \"khmer ON\" which Maiki mistakes for Kamron. The 2 become best friends and fight its evil programming together."
    },
    {
        Dwoobyx: "dwoobyx-0022",
        Filename: Bcp14,
        Numbering: "#22",
        Name: "Athroh",
        DreamDex: "Sacrificed to fuze with the phoenix by an ancient civilization. His atoms were ripped apart for millions of years until finally collecting into an orb. This powerful phoenix orb was held by many great leaders, but finally separated and freed from it, it has done damage to his body. He now needs music to keep his body in tact."
    },
    {
        Dwoobyx: "dwoobyx-0023",
        Filename: Bcp15,
        Numbering: "#23",
        Name: "Quazi",
        DreamDex: "A twisted vision without the funding. An ex-Shard that defected to Pink Pennant. He uses cities as test subjects and his fingers are everywhere. He's into genetics and wants to create the perfect evolutionary species. He hears voices in his head and has a dark vibe around him. "
    },
    {
        Dwoobyx: "dwoobyx-0024",
        Filename: Bcp16,
        Numbering: "#24",
        Name: "CornCezar",
        DreamDex: "Held in containment at Pink Pennant to experiment on his necromancy. Cezar is freed by T7 and escapes the facility. He later runs into the Fronteers and forms a deep bound on the battlefield with Graul. From his experience, he believes everyone is ugly inside but that's what's beautiful."
    },
    {
        Dwoobyx: "dwoobyx-0025",
        Filename: Bcp17,
        Numbering: "#25",
        Name: "Hill",
        DreamDex: "A travelling hunter and collector of rare specimens. He wants them to battle in a D-D-DUEL of his own game. He joined Pink Pennant in hopes of finding a way to start capturing species of men in his fossil cards. He sees all of life as rare beasts after researching the era of Beastmen."
    },
    {
        Dwoobyx: "dwoobyx-0026",
        Filename: Bcp18,
        Numbering: "#26",
        Name: "Na",
        DreamDex: "An inevitable soldier no matter the world. Born in an extreme life or death situation. He survived until he was captured in war and became prisoner. After the war ended he was thought to be KIA. Abducted and taken to an underground facility, he was brainwashed and groomed to be a perfect soldier. Though his memories are in tact, his vision of a frightful non existing past and future drives him mad."
    },
    {
        Dwoobyx: "dwoobyx-0027",
        Filename: Bcp19,
        Numbering: "#27",
        Name: "Cheetah Baby",
        DreamDex: "Playful and bursting with character! This extra dimensional imp was hanging out in a pocket dimension until accidently pulled out of a gummisack by TuNa. Her origins are mysterious. She loves to challenge TuNa and throw the dreamworld into crazy scenarios. She's become attached to the phoenix pearl, who she's taken as an adoptive mother"
    },
    {
        Dwoobyx: "dwoobyx-0028",
        Filename: Bcp20,
        Numbering: "#28",
        Name: "Geodi",
        DreamDex: "Preaching the word of prophecies written down from before the first exodus of man. He tells the story about a team of 7+1 who will unite to save all life on many occasions. He has dedicated his life to practicing the ways of these saviors and spreading the good faith."
    },
    {
        Dwoobyx: "dwoobyx-0029",
        Filename: Bcp21,
        Numbering: "#29",
        Name: "Lareina",
        DreamDex: "The princess of the palfox family and older sister to Kalo. She gets worried her brother has been gone so long, so she goes on a journey to get closure for her family! Experiencing a great journey travelling through mystery and danger. She returns to teach young minds what the world has to offer."
    },
    {
        Dwoobyx: "dwoobyx-0030",
        Filename: Bcp22,
        Numbering: "#30",
        Name: "Jest",
        DreamDex: "Enlisted in the shards then tasked to extract the metal from Bobbo and dispose of him. His entire village was encased in an alloy during an eclipse. Infected himself, he uses untested medication to keep the curse at bay. He wishes one day to free his village from their curse and get revenge."
    },
    {
        Dwoobyx: "dwoobyx-0031",
        Filename: Bcp23,
        Numbering: "#31",
        Name: "Eremi",
        DreamDex: "A rare remaining Beastman from a forgotten time. Eremi is a high ranking Qrystal Gnite in the Shards. A top secret squad tasked to take down the DreamWorlders. Their orders given to them by politicians all wearing a black tassel. Eremi goes after Ade to reclaim a stolen priceless treasure and bring him to justice."
    },
    {
        Dwoobyx: "dwoobyx-0032",
        Filename: Bcp24,
        Numbering: "#32",
        Name: "Kimbop",
        DreamDex: "Likes plain hotslogs and most likely a super genius. Part of the Qrystel Intelligence Agency, she has a special crystal from space that absorbs eXp energy and weakens people. She's been tasked by the Shards to take down hens kin. They're labeled a dangerous species that has threatened the universe before."
    },
    {
        Dwoobyx: "dwoobyx-0033",
        Filename: Bcp25,
        Numbering: "#33",
        Name: "Senior",
        DreamDex: "Leader of the Shards and commands the Qrystel Gnites. He's tasked to take down Sell, a deserter and traitor charged with terrorism. He's a crackshot, perfect soldier and he loves what he does. He hates deserters or anyone that would bring shame to the uniform. "
    },
    {
        Dwoobyx: "dwoobyx-0034",
        Filename: Bcp26,
        Numbering: "#34",
        Name: "Lew",
        DreamDex: "\"A Warm Wind Blows\" On a mission to retrieve Overture, Kalos sword, now classified as a weapon of mass destruction and to arrest Kalo. Lew wields a legendary swords himself with the power to counter Overture. Contained in this sword is the king of all monsters that his ancestors slayed. He is a slayer of dragonslayers."
    },
    {
        Dwoobyx: "dwoobyx-0035",
        Filename: Bcp27,
        Numbering: "#35",
        Name: "Yekub",
        DreamDex: "In an initiation to join the mafia gang The DownTown Dragons, he was to destroy all evidence hidden in a secret location by rigging explosions under the building. Backstabbed and left to die by Sell in his own trap but he survived. He was put in a prison and escaped. The Qrystel Gnites recaptured him and instead put his skills to better use."
    },
    {
        Dwoobyx: "dwoobyx-0036",
        Filename: Bcp28,
        Numbering: "#36",
        Name: "Nerv",
        DreamDex: "A double agent! Nerv is a prince from the other continent. He is part of a tribe that protects the Pink Sands and exterminates demons. Working with the Qrystel Gnites, he offers a trade. He'll capture TuNa and seal his demon in exchange he demands a secret black ops mission by QZ Corps leave his sacred sands and takes down their outposts."
    },
    {
        Dwoobyx: "dwoobyx-0037",
        Filename: Bcp29,
        Numbering: "#37",
        Name: "Kare",
        DreamDex: "Beastman found on the other continent. A pair of wildcat twins that see L in action. Seeing his cat like features they begin to idolize him and follow him back home. They ask to be his students and L accepts."
    },
    {
        Dwoobyx: "dwoobyx-0038",
        Filename: Bcp30,
        Numbering: "#38",
        Name: "Kal",
        DreamDex: "Team L blast off at the speed of sound! surrender now or ground and pound! These are some unusual cats.. but they seem good hearted. Every move L shows them they seem to perform masterfully, Why are they scared when they look off into the distance of the pink sands?"
    },
    {
        Dwoobyx: "dwoobyx-0039",
        Filename: Bcp31,
        Numbering: "#39",
        Name: "Elly",
        DreamDex: "In another time she may be a boring employee of the company Grotto Gear but in this timeline she is part of a gang of sky pirates and 2nd in command! Even if it feels she's in charge at times. She's devoted to fighting off the dying goblin empire after losing her husband in an explosion caused by the Goblin King."
    },
    {
        Dwoobyx: "dwoobyx-0040",
        Filename: Bcp32,
        Numbering: "#40",
        Name: "Lambert",
        DreamDex: "In a different time he is the chief of the Qry-Stell corporation. A mega conglomerate that also owns the Qrystal Gnites. In this time he is a captain of a sky ship designed to compete with the goblin empires eXptech. Like his other time version, he is perverted and when he dreams, he dreams big. He's all business and has ideas."
    },
    {
        Dwoobyx: "dwoobyx-0041",
        Filename: Bcp33,
        Numbering: "#41",
        Name: "Lil V",
        DreamDex: "A former engineer for the Goblin King. During an attack he was trapped in rubble but the rebel group known as Plummet, freed him. After changing his opinion of his enemies, he could no longer stay with the Tetranimh. He seeks out plummet with secret plans and whereabouts to take down the Goblin King."
    },
    {
        Dwoobyx: "dwoobyx-0042",
        Filename: Bcp34,
        Numbering: "#42",
        Name: "Maren",
        DreamDex: "Maren  was captured by the goblin forces and sent to play in Warball as a slave entertainer. Even though he didn't like it, he became the greatest player the sport has ever seen. He escaped one day and meets up with a group willing to pay him if he can help them with a job. He accepts and joins their team."
    },
    {
        Dwoobyx: "dwoobyx-0043",
        Filename: Bcp35,
        Numbering: "#43",
        Name: "Bellidia",
        DreamDex: "A distant relative to Kalos family that believes her side of the family is the rightful heir to their lost kingdom. Part of a group called the Martears, she is on a mission to be queen even if it means everything should burn"
    },
    {
        Dwoobyx: "dwoobyx-0044",
        Filename: Bcp36,
        Numbering: "#44",
        Name: "FOTRG",
        DreamDex: "Talk about mysterious. Fairy County's own that running guy has been spotted around the world, always running. He never stops, but something seems familiar about his eXp.. He only ever says one thing \"Howdy\" but i heard him whisper under his breath \"If i stop, my world will disappear\" Weird guy."
    },
    {
        Dwoobyx: "dwoobyx-0045",
        Filename: Bcp37,
        Numbering: "#45",
        Name: "Tiger Yin",
        DreamDex: "DreamWorld Master Tiger Yin. On a mission to take down the Goblin king and chew bubble slime, and he's all out of bubble slime. He is a legendary fighter but I wonder if the tales of his heroics are actually true. They say he kicked a mountain so hard it flew into the ocean and made an island. What a hero."
    },
    {
        Dwoobyx: "dwoobyx-0046",
        Filename: Bcp38,
        Numbering: "#46",
        Name: "Wibu",
        DreamDex: "1 of the 5 satellites the planet made to protect itself, but their ways of protection were too  righteous, so it banished them to the abyss. The satellites would find a dead planet in the cosmos to test their theories and after a long time they would return home to take control of the mother planet."
    },
    {
        Dwoobyx: "dwoobyx-0047",
        Filename: Bcp39,
        Numbering: "#47",
        Name: "Tuxedo Hat",
        DreamDex: "The leader of the Axolytes. After revealing himself to the DreamWorlders he gives his reason for invasion. It sounds all so correct but is he really doing this for others or out of hate? Is the planet really that much of a danger to itself? Either way his plans of injecting an AI fairy queen into the planet  sound crazy"
    },
    {
        Dwoobyx: "dwoobyx-0048",
        Filename: Bcp40,
        Numbering: "#48",
        Name: "Vest Guy",
        DreamDex: "What a cool looking vest.. I wonder what he's supposed to be? Like an angel? Noo.. he's  a vest guy! Careful who you speak with though.. It might be the vest guy deceiving you. He's so good at causing disruption and mistrust from the inside. It must be that vest? I wish I had a vest like that."
    },
    {
        Dwoobyx: "dwoobyx-0049",
        Filename: Bcp41,
        Numbering: "#49",
        Name: "Jubi",
        DreamDex: "A romanticizer. This summoner family is known for their extreme happiness and this one is bursting with it! She seems to forget her summon at times, but she never forgets to be kind to others. She's always on the prowl for a new bf. She has a crush on Hanu and shows her real Mythical power to The DreamWorlders on occasion."
    },
    {
        Dwoobyx: "dwoobyx-0050",
        Filename: Bcp42,
        Numbering: "#50",
        Name: "Ladle",
        DreamDex: "The more deeper silent genius of the summoners. He has so much potential but he needs direction. He is caring and a good kid. When things are their bleakest his weapons shine like Hens golden Transformation. His summon is reserved as well. His archery skills are so good that the DreamWorlders have trouble going up against him."
    },
    {
        Dwoobyx: "dwoobyx-0051",
        Filename: Bcp43,
        Numbering: "#51",
        Name: "Maiki",
        DreamDex: "Due to an accident as a baby Maiki managed to fuze with a mythical beast. Growing up has been tough so he gets bored staying home all day away from the fights. To keep up, he starts summoning the powers of his siblings summons. They accuse him of stealing but he's just borrowing them for a second!"
    },
    {
        Dwoobyx: "dwoobyx-0052",
        Filename: Bcp44,
        Numbering: "#52",
        Name: "Doughni",
        DreamDex: "The smartest and most down to earth of the summoner family. He has done his homework on his own species and has tried to give a way to share it with the world. His summon is sometimes chained up for research but together they work on ways to embed their family's powers into weapons and tools for better use."
    },
    {
        Dwoobyx: "dwoobyx-0053",
        Filename: Bcp45,
        Numbering: "#53",
        Name: "Roka",
        DreamDex: "The surrogate mother to all people who need a mother. She keeps this band all together no matter the cost. Making happiness and love contagious. She seems to be stronger than her summon. It constantly reminds her to take care of herself as well. She makes the nightmares understandable and forces them to the light."
    },
    {
        Dwoobyx: "dwoobyx-0054",
        Filename: Bcp46,
        Numbering: "#54",
        Name: "Koupe",
        DreamDex: "Father of the noble house of Kalo. His ancestors come from a line of dragon slayers that can seal them away into artifacts. He held Overture, passed down by his fathers and great grand fathers. He is always disappearing somewhere, and where is the money of the noble accounts going? So mysterious."
    },
    {
        Dwoobyx: "dwoobyx-0055",
        Filename: Bcp47,
        Numbering: "#55",
        Name: "Lina",
        DreamDex: "Ah Lina! Mother of Kalo and Lareina, She has a nice amount of wealth and loves to throw parties. She works so hard to be where she's at. She is the rightful Queen of an ancient flying city, the solraq kingdom, even if it doesn't exist anymore. Still she's happier being among the people."
    },
    {
        Dwoobyx: "dwoobyx-0056",
        Filename: Bcp48,
        Numbering: "#56",
        Name: "Moose",
        DreamDex: "The greatest adventurer to ever exist. He's encounter everything! Instead of collecting treasure, he went out into the world and buried everything he found. After retiring he left his son the keys to every treasure chest he buried. He meant to go back one day to retrace his adventurous life, but due to an illness he never recovered from during the Goblin War, he had to hand it down."
    },
    {
        Dwoobyx: "dwoobyx-0057",
        Filename: Bcp49,
        Numbering: "#57",
        Name: "Green Cell Gel",
        DreamDex: "Planet antibodies, Usually rummaging through trash to help clean the planet. They appear near cities where waste accumulated. They don't really have personality but they seem to be very nosey creatures... Always staring and listening."
    },
    {
        Dwoobyx: "dwoobyx-0058",
        Filename: Bcp50,
        Numbering: "#58",
        Name: "Cave Bhat",
        DreamDex: "These can get annoying. Their echolocation can mess with your equilibrium and their high powered cackle can damage your ears. They have no eyes but can see all vibrations around them. Painting a picture in their minds like a sonar. Their wings have great acoustics"
    },
    {
        Dwoobyx: "dwoobyx-0059",
        Filename: Bcp51,
        Numbering: "#59",
        Name: "Street Wrat",
        DreamDex: "Street wrats are more likely to flee then to fight but also like to steal shiny things. They're very hard to catch"
    },
    {
        Dwoobyx: "dwoobyx-0060",
        Filename: Bcp52,
        Numbering: "#60",
        Name: "Treasure Box Mimic",
        DreamDex: "More than meets the eyes. Treasure box mimics were once normal treasure boxes. Shapeless nightmares have consumed the objects that adventurers find so addicting. Creating monsters that would not have existed were they not so greedy."
    },
    {
        Dwoobyx: "dwoobyx-0061",
        Filename: Bcp53,
        Numbering: "#61",
        Name: "Phantoon",
        DreamDex: "Gas balloon ghosts that fill the sky with a blinding fog. They appear at night when their fog can be thickest. The last things you'll see are 2 glowing yellow eyes."
    },
    {
        Dwoobyx: "dwoobyx-0062",
        Filename: Bcp54,
        Numbering: "#62",
        Name: "Slyther",
        DreamDex: "A hypnotic instrumental snake that can put its targets into a dead sleep. Its extended wing like neck acts as a sound wall to bounce its music into large areas"
    },
    {
        Dwoobyx: "dwoobyx-0063",
        Filename: Bcp55,
        Numbering: "#63",
        Name: "Akraknid",
        DreamDex: "Be Careful what you pull out from the sand in the deserts of the dreamworld, because it might belong to the hoarding of akraknids. Spiders that use their web to create a storage type exoskeletons so that they can hold rare objects in themselves. Their hard drying web ability was used in ancient times to create many things."
    },
    {
        Dwoobyx: "dwoobyx-0064",
        Filename: Bcp56,
        Numbering: "#64",
        Name: "Pezant Biiz",
        DreamDex: "The Lower class of Biiz that do all the hard work. They are a hivemind so they always come in pairs. If you could convince them you were their leader, there could be some way to have them sacrifice their life for yours. "
    },
    {
        Dwoobyx: "dwoobyx-0065",
        Filename: Bcp57,
        Numbering: "#65",
        Name: "Mean Street",
        DreamDex: "earth elementals come in many shapes and sizes. These ones always choose to come alive during rush hour. It's not as slow as it looks. It gets a burst of energy when the green traffic light on its back lights up."
    },
    {
        Dwoobyx: "dwoobyx-0066",
        Filename: Bcp58,
        Numbering: "#66",
        Name: "Wild K-Nein",
        DreamDex: "There's no room for mercy with Wild dogs.. They usually travel in packs and they can enhance the gravity around their jaws to create a devastating bite."
    },
    {
        Dwoobyx: "dwoobyx-0067",
        Filename: Bcp59,
        Numbering: "#67",
        Name: "Bedhead",
        DreamDex: "Nightmares that are attracted to anxiety. Their eyes will make you lose concentration and become lost. If you ever get that feeling like someones watching you, then a Bedhead is nearby."
    },
    {
        Dwoobyx: "dwoobyx-0068",
        Filename: Bcp60,
        Numbering: "#68",
        Name: "Red Cell Gel",
        DreamDex: "You will usually find red cell gells near fires absorbing heat. When a fire is blazing they gather round to watch it burn and consume its heat. Are they helping to put out the fire or just eating energy?"
    },
    {
        Dwoobyx: "dwoobyx-0069",
        Filename: Bcp61,
        Numbering: "#69",
        Name: "Scarecorn",
        DreamDex: "Gigantic crow like birds that are actually born from plants. They're very aggressive and if you disturb them they will attack. They project knife like feathers."
    },
    {
        Dwoobyx: "dwoobyx-0070",
        Filename: Bcp62,
        Numbering: "#70",
        Name: "Green Dragon Sprite",
        DreamDex: "They say these pests manifest from dragon energy so if you see these, it means the source must be nearby. Green Dragon sprites shoot electric shocks"
    },
    {
        Dwoobyx: "dwoobyx-0071",
        Filename: Bcp63,
        Numbering: "#71",
        Name: "Gatekeeper",
        DreamDex: "Gatekeepers exist between realms of pocket dimensions within the DreamWorld. They keep doorways possible, but also try to protect who can come in and leave between them"
    },
    {
        Dwoobyx: "dwoobyx-0072",
        Filename: Bcp64,
        Numbering: "#72",
        Name: "Cave Wrat",
        DreamDex: "Cave Wrats hate the light. They live in dark caves and become aggressive when near light. They have a horn on top of their head that accumulates exp from the energy of digging underground and can shoot out a blast of pure eXp"
    },
    {
        Dwoobyx: "dwoobyx-0073",
        Filename: Bcp65,
        Numbering: "#73",
        Name: "Lunar Infantry",
        DreamDex: "The Lunar infantry guards one of the sacred moons of the DreamWorld. They are made of the stone from the sacred moon and have eyes as dark as space"
    },
    {
        Dwoobyx: "dwoobyx-0074",
        Filename: Bcp66,
        Numbering: "#74",
        Name: "Dumpster Pup",
        DreamDex: "Dumpster pups dwell in the stinkiest slimiest of all places. Not only to feast but to add to the waste. These monsters are toxic in nature and should properly be handled with caution"
    },
    {
        Dwoobyx: "dwoobyx-0075",
        Filename: Bcp67,
        Numbering: "#75",
        Name: "Wrisp Warrior",
        DreamDex: "The wrisp warrior knows only battle and they see no other purpose to life. Living in the bogs and swamps in the DreamWorld and living in fortresses made of mud. Their stinger is made of a metal and their arms can shoot projectiles"
    },
    {
        Dwoobyx: "dwoobyx-0076",
        Filename: Bcp68,
        Numbering: "#76",
        Name: "K-Nein Howler",
        DreamDex: "Howlers are a selected breed of k-neins that were created to exploit their space like properties. Allowing meteors to shoot out from portals on its back. They work for the Wisps, or police forces, of the DreamWorlds in many big towns."
    },
    {
        Dwoobyx: "dwoobyx-0077",
        Filename: Bcp69,
        Numbering: "#77",
        Name: "Cursed Armor",
        DreamDex: "Armor that has been bathed in blood and failed at protecting its host. It now sits in dark dungeons and battlefields where it was slain. Twisting in torment and inviting nightmare energy to allow its revenge."
    },
    {
        Dwoobyx: "dwoobyx-0078",
        Filename: Bcp70,
        Numbering: "#78",
        Name: "Corridor Amoeba",
        DreamDex: "The corridor amoeba takes over entire pathways and consumes everything that runs into it. These organisms start off very small and grow very fast. They have a thick barrier wall that acts like millions of hands to pull in anything that lands on it."
    },
    {
        Dwoobyx: "dwoobyx-0079",
        Filename: Bcp71,
        Numbering: "#79",
        Name: "Hydragora",
        DreamDex: "The hydragora is the brain of the forest. Tapped in to all that goes on in its domain and the more closer you get to it the more protected it is. Cut off a vine and 2 more grow in its place. If its vines get you, then it will absorb the life from your body."
    },
    {
        Dwoobyx: "dwoobyx-0080",
        Filename: Bcp72,
        Numbering: "#80",
        Name: "Tool Box Mimic",
        DreamDex: "The toolbox mimic is a nightmare energy filled monster that has chosen to take over a toolbox chest and wait for adventurers to come so close to it that there is no escape. They're more stickier than other types of mimics and more hyper"
    },
    {
        Dwoobyx: "dwoobyx-0081",
        Filename: Bcp73,
        Numbering: "#81",
        Name: "MoonRay",
        DreamDex: "Moonrays live in the deepest darkest part of the oceans and have created their own source of illumination to attract prey. When spotted in the distance they can look like a red glowing smiley face but they're actually charging up lasers when they feel a threat"
    },
    {
        Dwoobyx: "dwoobyx-0082",
        Filename: Bcp74,
        Numbering: "#82",
        Name: "Street Urchin",
        DreamDex: "Street urchins are spiky monsters that like to go idle in locations that they know are commonly crossed. They have popsicle like spikes that can freeze to almost any location. Allowing them to stick to locations like ceilings. They would rather be bumped into first before attacking so its best to go around them."
    },
    {
        Dwoobyx: "dwoobyx-0083",
        Filename: Bcp75,
        Numbering: "#83",
        Name: "Blue Dragon Sprite",
        DreamDex: "Blue Dragon Sprites use the power of ice and protect the domain of the blue dragon. They can also control temperatures and make the areas they are in extremely cold"
    },
    {
        Dwoobyx: "dwoobyx-0084",
        Filename: Bcp76,
        Numbering: "#84",
        Name: "Hermit Grab",
        DreamDex: "Hermit Grabs are creepy nightmares. The figure in front is a disguise to hide a tongue like claw that grabs unsuspecting prey. It's a nightmare that hides in plain site and appears on rainy days playing a song to attract people's curiosity. It waits for them to get close and springs at them. They'll take anything you have and hurt you in the process. Its base is an exoskeleton with an eye on its side."
    },
    {
        Dwoobyx: "dwoobyx-0085",
        Filename: Bcp77,
        Numbering: "#85",
        Name: "Slime Crime",
        DreamDex: "Slime crimes roam around trying to capture prey into its mouth which acts like a prison. If held too long it will suffocate its prey.. There is  no way out besides back through its mouth."
    },
    {
        Dwoobyx: "dwoobyx-0086",
        Filename: Bcp78,
        Numbering: "#86",
        Name: "Field Wrat",
        DreamDex: "Field wrats are born with a single horn but as they mature the horn grows over their entire face until it resembles a skull. This wrat type is much more braver than others in its species"
    },
    {
        Dwoobyx: "dwoobyx-0087",
        Filename: Bcp79,
        Numbering: "#87",
        Name: "Cycrops",
        DreamDex: "the legend of bushman living in the woods is all too real. The cycrops use to be folk tales told by the locals until fires forced these creatures out of their homes. Their bodies create a substance that gives rashes and blisters. They can easily camouflage so you will never know when they're around until they open their eye."
    },
    {
        Dwoobyx: "dwoobyx-0088",
        Filename: Bcp80,
        Numbering: "#88",
        Name: "Canyon Harpy",
        DreamDex: "Canyon harpys live in nests built into the sides of canyons. They use their powers over the winds to create wind tunnels and haunt the air that blows"
    },
    {
        Dwoobyx: "dwoobyx-0089",
        Filename: Bcp81,
        Numbering: "#89",
        Name: "Royal Biiz",
        DreamDex: "The royal class of biiz have hookshot type stingers that inject a deadly honey. Inside their body is a mini hive that holds larva biiz"
    },
    {
        Dwoobyx: "dwoobyx-0090",
        Filename: Bcp82,
        Numbering: "#90",
        Name: "Soul Stinger",
        DreamDex: "Soul stingers are ancient insects that lived thousands of years ago with ancient races. They are very aggressive and wild but were once tamed and used to sculpt giant stones using their lazer claws."
    },
    {
        Dwoobyx: "dwoobyx-0091",
        Filename: Bcp83,
        Numbering: "#91",
        Name: "Gorgon",
        DreamDex: "The gorgons live on an island that's infested with snakes of all kinds. They are most vulnerable after they shed their skin. Careful for their rattle dance it means a dangerous attack is coming soon."
    },
    {
        Dwoobyx: "dwoobyx-0092",
        Filename: Bcp84,
        Numbering: "#92",
        Name: "Drooper",
        DreamDex: "Droopers have a dangerous lick. They say if a drooper ever licks you that death will always be attracted to you. Careful for Drooper ghosts they create thick fogs and sink through upstair floors."
    },
    {
        Dwoobyx: "dwoobyx-0093",
        Filename: Bcp85,
        Numbering: "#93",
        Name: "Vermz",
        DreamDex: "The Vermz control the pink Sands. Gigantic worms living underground that protect the land where the mightiest of all mythical beasts have died. Vermz attack anything that walk on its sand and regenerate if chopped into 2"
    },
    {
        Dwoobyx: "dwoobyx-0094",
        Filename: Bcp86,
        Numbering: "#94",
        Name: "Gremlin Xr3",
        DreamDex: "Grotto Gear has unveiled its first peace weapons to patrol the streets. The Gremlin XR3s. Only something has gone terribly wrong and the XR3s have begun attacking everything. "
    },
    {
        Dwoobyx: "dwoobyx-0095",
        Filename: Bcp87,
        Numbering: "#95",
        Name: "Goblin Bomber",
        DreamDex: "Goblin bombers mastered the art of eXp bomb making. They do not hold back. They invade with full force leveling an entire forest before foot troops go in. Attached to its fists are 2 projectile daggers that explode on impact."
    },
    {
        Dwoobyx: "dwoobyx-0096",
        Filename: Bcp88,
        Numbering: "#96",
        Name: "Furaknid",
        DreamDex: "Furaknids dangle from trees in large numbers. In the dark they're always hard to see especially if the stomach side is facing you. Their stomach side looks like stars in the sky to fool people into walking into them at night. They swing in the wind in a hypnotic way, making it feel like time has been lost"
    },
    {
        Dwoobyx: "dwoobyx-0097",
        Filename: Bcp89,
        Numbering: "#97",
        Name: "Well Wight",
        DreamDex: "Wellwights are sprite like monsters that appear in wet areas. Usually found in sewers or showers, these elementals have a thicker vein system running through them. They can use their enchanted water to cure or drown."
    },
    {
        Dwoobyx: "dwoobyx-0098",
        Filename: Bcp90,
        Numbering: "#98",
        Name: "Phoenix Harpy",
        DreamDex: "Phoenix Harpys hunt over the Pink sands. shooting a molten pink flame that causes a rapid regeneration that kinetically charges and bursts into flames. They Are somewhat related and cursed by the phoenix."
    },
    {
        Dwoobyx: "dwoobyx-0099",
        Filename: Bcp91,
        Numbering: "#99",
        Name: "Spicy DragonRoll",
        DreamDex: "Volcano Wyverns known as Dragonrolls can summon great beams of fire. They drink the lava from volcanoes and breathe out a toxic smoke."
    },
    {
        Dwoobyx: "dwoobyx-0100",
        Filename: Bcp92,
        Numbering: "#100",
        Name: "Pin Yatta!",
        DreamDex: "Pin Yattas are exploding type monsters. Upon explosion they often drop items. Every time you hit them they become more unstable."
    },
    {
        Dwoobyx: "dwoobyx-0101",
        Filename: Bcp93,
        Numbering: "#101",
        Name: "Wild Khat",
        DreamDex: "Wild Khats are always on the prowl. One bite from a Wildkhat causes an infection that drives the target crazy. Their colors change with the season"
    },
    {
        Dwoobyx: "dwoobyx-0102",
        Filename: Bcp94,
        Numbering: "#102",
        Name: "Lunar Armada",
        DreamDex: "The lunar armada are high level Gargoyles that live on one of the DreamWorlds Moons. They guard the entrances to the inside of the moon. Highly Intelligent and amazing at warfare. They can cast anything into stone."
    },
    {
        Dwoobyx: "dwoobyx-0103",
        Filename: Bcp95,
        Numbering: "#103",
        Name: "Demon Dollz",
        DreamDex: "The demon dolls are a top secret operation done off site by an unknown organization known as the QZ CORPS. There is an army of them made for invasion. Since nightmares can not cross over, they use these dolls to possess and control."
    },
    {
        Dwoobyx: "dwoobyx-0104",
        Filename: Bcp96,
        Numbering: "#104",
        Name: "Knell Gel",
        DreamDex: "Knell Gels appear in the fall when the leaves start to die. The sound of them is haunting. They say every time time a Knell Gel rings a leaf dies."
    },
    {
        Dwoobyx: "dwoobyx-0105",
        Filename: Bcs1,
        Numbering: "#105",
        Name: "RagnaRock",
        DreamDex: "The Ragnarok is an ancient creature that is tasked with destroying worlds that threaten the end of the universe. Though it is a giant skull like metal crystal no one really knows where the rest of its body is or if there is one. "
    },
    {
        Dwoobyx: "dwoobyx-0106",
        Filename: Bcs2,
        Numbering: "#106",
        Name: "Zetris",
        DreamDex: "Zetris is an AI fairy that was injected into the planet. It was fuzed with a guardian of the Dreamworld that agreed to the cause so it could heal the planets traumatic experiences. The ai however was too strong and has taken over the merge."
    },
    {
        Dwoobyx: "dwoobyx-0107",
        Filename: Bcs3,
        Numbering: "#107",
        Name: "Solroque",
        DreamDex: "The solroque is a piece of ancient terra tech. Powered by one of the biggest eXp crystals. It was a flying island created as a warship. Once activated it can be controlled by the one it designates as its master."
    },
    {
        Dwoobyx: "dwoobyx-0108",
        Filename: Bcs4,
        Numbering: "#108",
        Name: "Miss Nightmare",
        DreamDex: "Miss Nightmare is the Queen of the Nightmares. She knows all your secrets and she will use them against you. She plans with Neitmare and shares the rewards of his victories. She was once tricked by Moose who offered his hand in marriage to her. Be Careful what you promise."
    },
    {
        Dwoobyx: "dwoobyx-0109",
        Filename: Bcs5,
        Numbering: "#109",
        Name: "King Cell Gel",
        DreamDex: "King Cell Gel use to be a normal Cell Gel but one day he was in the sewers of Fairy County where he ate a magical treasure. It stayed inside him for years until it was taken out. By then he had absorbed all of its energy. King Cell Gel use to be transparent as well, but that's a story for another time."
    },
    {
        Dwoobyx: "dwoobyx-0110",
        Filename: Bcs6,
        Numbering: "#110",
        Name: "Spiraqle",
        DreamDex: "Spiraqle was a dragon trapped in ice. When the islands fell from the sky, the water dragon got caught underneath the one that crashed into the island. He sank to the icy bottom and there he stayed until he was freed."
    },
    {
        Dwoobyx: "dwoobyx-0111",
        Filename: Bcs7,
        Numbering: "#111",
        Name: "Star Mother",
        DreamDex: "The celestial Star Mother that guards the final gate to the Dreamverse. Inside her is her very own DreamSeed that she uses as an energy source. She is one of the creators of the universe and the key to the final gate."
    },
    {
        Dwoobyx: "dwoobyx-0112",
        Filename: Bcs8,
        Numbering: "#112",
        Name: "Majoris",
        DreamDex: "The Phoenix was trapped in an orb for thousands of centuries. Captured by an ancient race then finally freed by Cheetah Baby. It has destroyed an entire continent in one blast. They say the phoenix is born from suns and when they go out it is reincarnated as a new sun. "
    },
    {
        Dwoobyx: "dwoobyx-0113",
        Filename: Bca51,
        Numbering: "#113",
        Name: "H2Po",
        DreamDex: "An HP Potion that restores 2 health points on a single target its always cool and refreshing"
    },
    {
        Dwoobyx: "dwoobyx-0114",
        Filename: Bca52,
        Numbering: "#114",
        Name: "Maiden's Kiss",
        DreamDex: "A moisturizing balm that has been kissed by a maiden. It cures the transform frog status when its applied."
    },
    {
        Dwoobyx: "dwoobyx-0115",
        Filename: Bca53,
        Numbering: "#115",
        Name: "Fairy Dust",
        DreamDex: "The dust of fairies collected in a bag. This Fairy dust gives the user levitation. Very useful against people without ranged attacks"
    },
    {
        Dwoobyx: "dwoobyx-0116",
        Filename: Bca54,
        Numbering: "#116",
        Name: "CORE",
        DreamDex: "Core is an energy drink that improves accuracy and evasion. It can cause a hyper state of focus but you're going to be up all night."
    },
    {
        Dwoobyx: "dwoobyx-0117",
        Filename: Bca55,
        Numbering: "#117",
        Name: "Def Booster",
        DreamDex: "An easy to use squeeze style syringe that is filled with a drug. This drug strengthens and hardens the skin to increase defense"
    },
    {
        Dwoobyx: "dwoobyx-0118",
        Filename: Bca56,
        Numbering: "#118",
        Name: "Dragon Shot",
        DreamDex: "A shot of pure elixir that restores 2 health points and 2 energy points. The taste is very bitter."
    },
    {
        Dwoobyx: "dwoobyx-0119",
        Filename: Bca57,
        Numbering: "#119",
        Name: "Music Box",
        DreamDex: "A music box that plays a song so beautiful it can make a statues stone eye cry. It cures petrification."
    },
    {
        Dwoobyx: "dwoobyx-0120",
        Filename: Bca58,
        Numbering: "#120",
        Name: "Regen",
        DreamDex: "A regen gel made partly from green cell gels. This Regen gel restores 1 health point at the end of every turn"
    },
    {
        Dwoobyx: "dwoobyx-0121",
        Filename: Bca59,
        Numbering: "#121",
        Name: "Blood Berry",
        DreamDex: "Juice filled berries that are very sweet and slightly tart. These berries increase production of blood by using the body's inner energy.  It converts 1 energy point to 1 health point."
    },
    {
        Dwoobyx: "dwoobyx-0122",
        Filename: Bca60,
        Numbering: "#122",
        Name: "Reconoteur",
        DreamDex: "A scouter visor used by the Qrystal Gnites. This scouter can scan a targets vitals and show their strength and weakness"
    },
    {
        Dwoobyx: "dwoobyx-0123",
        Filename: Bca61,
        Numbering: "#123",
        Name: "Refresh Jelly",
        DreamDex: "This Jelly contains orange cell Gels and refreshes eXp energy. it will restore 1 energy point after every turn. It's very refreshing and has electrolytes."
    },
    {
        Dwoobyx: "dwoobyx-0124",
        Filename: Bca62,
        Numbering: "#124",
        Name: "Antidote",
        DreamDex: "cures poison status. To activate this antidote you must first crack the inner tube and mix its contents by shaking it. Do not consume more than 4 a day."
    },
    {
        Dwoobyx: "dwoobyx-0125",
        Filename: Bca63,
        Numbering: "#125",
        Name: "Tent",
        DreamDex: "An easy to set up tent that can fit a family. Its flooring is soft and its rain proof. One nights rest in it will bring your vitals back to 100%"
    },
    {
        Dwoobyx: "dwoobyx-0126",
        Filename: Bca64,
        Numbering: "#126",
        Name: "Cookiez",
        DreamDex: "Cookiez are very rare snacks to be found in the DreamWorld. These specially made cookiez can cure the magical transform status known as chibi"
    },
    {
        Dwoobyx: "dwoobyx-0127",
        Filename: Bca65,
        Numbering: "#127",
        Name: "Monster jam",
        DreamDex: "Monster jam can temporarily alter dna and transform a person into any number of monsters."
    },
    {
        Dwoobyx: "dwoobyx-0128",
        Filename: Bca66,
        Numbering: "#128",
        Name: "Remedy",
        DreamDex: "A miracle remedy that can cure any status effect. Just press down on the top to puncture an opening into its steel casing and drink the contents. If you are unable to directly drink, then pour it on the affected area. Consult a white mage if status does not fully disappear"
    },
    {
        Dwoobyx: "dwoobyx-0129",
        Filename: Bca67,
        Numbering: "#129",
        Name: "Tablet",
        DreamDex: "Attack tablets are small chewable pills that increase your attack strength by a point. They do not stay in the body long and the user can grow a tolerance to them."
    },
    {
        Dwoobyx: "dwoobyx-0130",
        Filename: Bca68,
        Numbering: "#130",
        Name: "Ether Plus",
        DreamDex: "A super ether tank can. Crack open the can and take a big drink. This super ether restores up to 5 energy points"
    },
    {
        Dwoobyx: "dwoobyx-0131",
        Filename: Bca69,
        Numbering: "#131",
        Name: "CB Capsule",
        DreamDex: "The CB or ComeBack Capsules unlock repressed memories and emotions in troubled minds and expands your minds horizon to do a full powered attack more creatively"
    },
    {
        Dwoobyx: "dwoobyx-0132",
        Filename: Bca70,
        Numbering: "#132",
        Name: "eXcite Bite",
        DreamDex: "this piece of candy will increase the multiplication of your comeback attack  by +1"
    },
    {
        Dwoobyx: "dwoobyx-0133",
        Filename: Bca71,
        Numbering: "#133",
        Name: "Hourglass",
        DreamDex: "An hourglass filled with the ashes of the Phoenix. It can slow down time."
    },
    {
        Dwoobyx: "dwoobyx-0134",
        Filename: Bca72,
        Numbering: "#134",
        Name: "Muzmux Milk",
        DreamDex: "Fresh milk straight from a Muzmux. This milk is filled with so many nutrients it builds strong bones. Drinking this milk will increase your defense by 1 point"
    },
    {
        Dwoobyx: "dwoobyx-0135",
        Filename: Bca73,
        Numbering: "#135",
        Name: "Root",
        DreamDex: "A very bitter root that taste very great in most recipes in very small doses. But for its healing properties it must be eaten raw. This root can restore 1 health point and 1 energy point"
    },
    {
        Dwoobyx: "dwoobyx-0136",
        Filename: Bca74,
        Numbering: "#136",
        Name: "Pause Shroom",
        DreamDex: "the pause shroom is a mushroom that came from space. Eating this pause shroom can cause an overlap of history allowing you to do the same event twice"
    },
    {
        Dwoobyx: "dwoobyx-0137",
        Filename: Bca75,
        Numbering: "#137",
        Name: "Beastman Flask",
        DreamDex: "The beastman flask is a very dangerous alcoholic based drink made by the beastman tribes ancient ways. One drink of this causes a person to lash out in a reflex triggered by a traumatic memory or increased emotion of anger."
    },
    {
        Dwoobyx: "dwoobyx-0138",
        Filename: Bca76,
        Numbering: "#138",
        Name: "Repel Bomb",
        DreamDex: "A smoke canister that hides your location. It is so thick no one can see through it."
    },
    {
        Dwoobyx: "dwoobyx-0139",
        Filename: Bca77,
        Numbering: "#139",
        Name: "Repel Incense",
        DreamDex: "This pwiggy incense keeps away pesky monsters when it is lit. It is very useful when exploring locations with low resources available."
    },
    {
        Dwoobyx: "dwoobyx-0140",
        Filename: Bca78,
        Numbering: "#140",
        Name: "Zombi Vax",
        DreamDex: "In the event that you get bitten and become a Zzzombi take this shot to bring you back before anything permanent occurs"
    },
    {
        Dwoobyx: "dwoobyx-0141",
        Filename: Bca79,
        Numbering: "#141",
        Name: "Fire Water",
        DreamDex: "FIrewater can only be taken by a body that is prepared for its journey. Distilled from dragon drool, this liquid solidifies an entire body making it as strong as dragon scales."
    },
    {
        Dwoobyx: "dwoobyx-0142",
        Filename: Bca80,
        Numbering: "#142",
        Name: "Demon Eyelight",
        DreamDex: "A flashlight that casts shadow. Point at someone to suppress their thoughts and make them complacent."
    },
    {
        Dwoobyx: "dwoobyx-0143",
        Filename: Bca81,
        Numbering: "#143",
        Name: "Steal Pump",
        DreamDex: "The steal pump is a very high tech absorbing vacuum. Adjust its settings to steal an item lighter or heavier."
    },
    {
        Dwoobyx: "dwoobyx-0144",
        Filename: Bca82,
        Numbering: "#144",
        Name: "NetherKube",
        DreamDex: "The netherkube is a die that can be rolled and where it lands it will create an energy construct of different shapes and colors that disables energy attacks"
    },
    {
        Dwoobyx: "dwoobyx-0145",
        Filename: Bca83,
        Numbering: "#145",
        Name: "Potion Plus",
        DreamDex: "Potion plus is a higher strength potion. The contents of it are higher quality. This potion can restore 6 health points."
    },
    {
        Dwoobyx: "dwoobyx-0146",
        Filename: Bca84,
        Numbering: "#146",
        Name: "Reaper Crystal",
        DreamDex: "Reaper crystals are the anti eXp crystals. They absorb and nullify all energy in its range. If you are directly holding this crystal, the reaper fairy that lives inside may like you and share with you what it absorbs."
    },
    {
        Dwoobyx: "dwoobyx-0147",
        Filename: Bca85,
        Numbering: "#147",
        Name: "Ice Gel",
        DreamDex: "Heals sick burns"
    },
    {
        Dwoobyx: "dwoobyx-0148",
        Filename: Bca86,
        Numbering: "#148",
        Name: "xPeanut",
        DreamDex: "a peanut from a rare DreamWorld tree. the peanut clusters inside restore energy"
    },
    {
        Dwoobyx: "dwoobyx-0149",
        Filename: Bca87,
        Numbering: "#149",
        Name: "Cyclop Drops",
        DreamDex: "a medicated lubricant for the eyes. It was developed for the cyclops and clears out any mess from the eye. It can even cure temporary blindness"
    },
    {
        Dwoobyx: "dwoobyx-0150",
        Filename: Bca88,
        Numbering: "#150",
        Name: "Vis Vitalis",
        DreamDex: "A canister of freshly produced phoenix ashes that have been capture mid cycle in its reborn phase. It is a canister of miracles that can revive the departed"
    },
    {
        Dwoobyx: "dwoobyx-0151",
        Filename: Bca89,
        Numbering: "#151",
        Name: "Fairy Tank",
        DreamDex: "A trapped fairy in an energy tank is very useful in battles. Harvest its healing energy while taking on damage it will restore you."
    },
    {
        Dwoobyx: "dwoobyx-0152",
        Filename: Bca90,
        Numbering: "#152",
        Name: "Fairy Tank +",
        DreamDex: "This sacred orb can absorb higher level fairies like a piggy bank. The only way to extract them is to speak the cantation on the box"
    },
    {
        Dwoobyx: "dwoobyx-0153",
        Filename: Bca91,
        Numbering: "#153",
        Name: "Panacea",
        DreamDex: "An HQ Ether filtered 100 times and aged for 100 years in a cask made of magical wood. The panacea will restore all energy."
    },
    {
        Dwoobyx: "dwoobyx-0154",
        Filename: Bca92,
        Numbering: "#154",
        Name: "Super Potion +",
        DreamDex: "Super potion plus is the highest quality of potions. It is synthesized with blue cell gels and restores full life."
    },
    {
        Dwoobyx: "dwoobyx-0155",
        Filename: Bca93,
        Numbering: "#155",
        Name: "Esuna Capsules",
        DreamDex: "Esuna capsules look average in appearance but contain a miracle cure inside. These were created by top scientist and biologist at pink pennant. They heal all status and shed their healing properties to others around you."
    },
    {
        Dwoobyx: "dwoobyx-0156",
        Filename: Bca94,
        Numbering: "#156",
        Name: "Slapstick",
        DreamDex: "The slapstick is a piano hammer made with the spirit of a great warrior trapped inside. This piano hammer can make a music note on any object that can only be heard in the heavens. One tap by this piano hammer is a near instant kill"
    },
    {
        Dwoobyx: "dwoobyx-0157",
        Filename: Bca95,
        Numbering: "#157",
        Name: "Reverb Gas",
        DreamDex: "Reverb gas inhalers that heal mute status and bring voice back to the voiceless"
    },
    {
        Dwoobyx: "dwoobyx-0158",
        Filename: Bca96,
        Numbering: "#158",
        Name: "Save Crystal",
        DreamDex: "The save crystal restores your body's vitals to a point in time where you felt were their peak. It has access to data about your entire life."
    },
    {
        Dwoobyx: "dwoobyx-0159",
        Filename: Bca97,
        Numbering: "#159",
        Name: "Dragon Steak",
        DreamDex: "A well done dragon steak cures any animal urge. When in a berzerk state, take a big chunk of this meat and satisfy your meat tooth and cure your berserk status"
    },
    {
        Dwoobyx: "dwoobyx-0160",
        Filename: Bca98,
        Numbering: "#160",
        Name: "Exit Door",
        DreamDex: "The exit door is a useful magical item that can be set up anywhere and teleport you to a location outside the zone"
    },
    {
        Dwoobyx: "dwoobyx-0161",
        Filename: Bcs9,
        Numbering: "#161",
        Name: "Knuckle Busters",
        DreamDex: "infused with the spirit of the grey dragon and then reforged with a paradox alloy to withstand existing in any universe. These weighted knuckles can knockout anyone putting them to sleep. They allow an extra hit pulling in opponents faces like magnets to the fist"
    },
    {
        Dwoobyx: "dwoobyx-0162",
        Filename: Bcs10,
        Numbering: "#162",
        Name: "Boomerang",
        DreamDex: "infused with the spirit of the green dragon and then reforged with a paradox alloy to withstand existing in any universe. This boomerang always comes back even after hitting its target. It is mischievous in nature and will attempt to steal from anyone it attacks"
    },
    {
        Dwoobyx: "dwoobyx-0163",
        Filename: Bcs11,
        Numbering: "#163",
        Name: "Overture",
        DreamDex: "infused with the spirit of the red dragon and then reforged with a paradox alloy to withstand existing in any universe. This sword protects its wielder, creating force fields of magic fire  defending against physical attacks and flamethrower style attacks that can shoot out from its hilt"
    },
    {
        Dwoobyx: "dwoobyx-0164",
        Filename: Bcs12,
        Numbering: "#164",
        Name: "Lucky Gun",
        DreamDex: "infused with the spirit of the black dragon and then reforged with a paradox alloy to withstand existing in any universe. This gun is built on chaos energy and can either work for you or against you. "
    },
    {
        Dwoobyx: "dwoobyx-0165",
        Filename: Bcs13,
        Numbering: "#165",
        Name: "Feather Lance",
        DreamDex: "infused with the spirit of the golden dragon and then reforged with a paradox alloy to withstand existing in any universe. This spear gives the gift of flight even in universes without eXp energy existing. Its attack can be portaled from the heavens."
    },
    {
        Dwoobyx: "dwoobyx-0166",
        Filename: Bcs14,
        Numbering: "#166",
        Name: "Ice Driver",
        DreamDex: "infused with the spirit of the blue dragon and then reforged with a paradox alloy to withstand existing in any universe. This scythe has a drive shift increasing its slicing speed. It can control water and ice allowing it to survive fiery kinetic speeds"
    },
    {
        Dwoobyx: "dwoobyx-0167",
        Filename: Bcs15,
        Numbering: "#167",
        Name: "Plus Staff",
        DreamDex: "infused with the spirit of the white dragon and then coated with a paradox alloy to withstand existing in any universe. This plus staff is a holy relic that has the power to heal all allies and make force fields against magical exp attacks."
    },
    {
        Dwoobyx: "dwoobyx-0168",
        Filename: Bcs16,
        Numbering: "#168",
        Name: "Nunchucks",
        DreamDex: "infused with the spirit of the purple dragon and then coated with a paradox alloy to withstand existing in any universe. these nunchucks have the power to stretch to an iinfinite length. Encoded into them is a poisonous spell."
    },
    {
        Dwoobyx: "dwoobyx-0169",
        Filename: Bca1,
        Numbering: "#169",
        Name: "Artico",
        DreamDex: "Artico wishes to get married but has no money for a ring. He enters the tournaments for the grand prize but can never do the final hit. No one knows he enlists to fight. If only someone could help him."
    },
    {
        Dwoobyx: "dwoobyx-0170",
        Filename: Bca2,
        Numbering: "#170",
        Name: "Baba",
        DreamDex: "Baba lives near the windy hills where the muzmux love to roam. They're simple farmers that charge very high price for the fabrics they weave. Pillows and sheets made by baba can cure any insomnia and give a good night's rest."
    },
    {
        Dwoobyx: "dwoobyx-0171",
        Filename: Bca3,
        Numbering: "#171",
        Name: "Beta",
        DreamDex: "Beta is from a pocket realm in the DreamWorld where everyone is miniature. He often questions his reality and wonder what lies beyond the realms. He is tasked to take care of the Omegga and has a brother that went missing. He believes his brother to be in another realm but how could he ever find him."
    },
    {
        Dwoobyx: "dwoobyx-0172",
        Filename: Bca4,
        Numbering: "#172",
        Name: "Dayla",
        DreamDex: "Dayla is a fallen star fairy that shares a life force with Liquid smoke. She is captured in a tower by an evil organization known as Bottlecap. She keeps a journal of the visions she sees of Liquids adventures. This is our heroes favorite tv series but something feels off about it. Whenever an episode airs, it seems connected to what's happening now."
    },
    {
        Dwoobyx: "dwoobyx-0173",
        Filename: Bca5,
        Numbering: "#173",
        Name: "Death",
        DreamDex: "Death is a twin whose other half is missing from this planet. He understands eXp blasts really well and uses them to fight the police force known as the Wisps from overreaching "
    },
    {
        Dwoobyx: "dwoobyx-0174",
        Filename: Bca6,
        Numbering: "#174",
        Name: "Eden",
        DreamDex: "Eden lives in the hidden Dendros forest where the great DreamTree use to exist. Her tribe has carved an entire city into its stump. They say one day an outsider will come accompanied by one of their own and be the hero the planet needs. Eden loves this prophecy so is always away from home looking for the one to escort to her village."
    },
    {
        Dwoobyx: "dwoobyx-0175",
        Filename: Bca7,
        Numbering: "#175",
        Name: "Fan",
        DreamDex: "Fan is a ghost with no memory of who he use to be or how he died. He is non aggressive and not social. When trains are heard in the distance the air around him becomes cold."
    },
    {
        Dwoobyx: "dwoobyx-0176",
        Filename: Bca8,
        Numbering: "#176",
        Name: "Flare",
        DreamDex: "Flare is one of the villains of the fictional show our heroes watch. He crash lands from space to kill Liquid Smoke, but maybe this fictional story isn't so fictional after all."
    },
    {
        Dwoobyx: "dwoobyx-0177",
        Filename: Bca9,
        Numbering: "#177",
        Name: "Gargle",
        DreamDex: "Gargle the Cyborg! Part Boy Part Toy. Gargle has to look at life with a sense of humor because of his body. Meddling with Tech magic disfigured him but his mother wasn't too traumatized by it. He now knows exactly what Not to do with tech magic and can be of great help."
    },
    {
        Dwoobyx: "dwoobyx-0178",
        Filename: Bca10,
        Numbering: "#178",
        Name: "Gekaman",
        DreamDex: "Gekaman was captured by Pink Pennant and had tests done to his body, altering his DNA. He escaped before they could install the sleeper cell inside his body. He tried to return back to his village but was seen as a monster, so he retreated to the wild where he only wishes to be left alone."
    },
    {
        Dwoobyx: "dwoobyx-0179",
        Filename: Bca11,
        Numbering: "#179",
        Name: "Gideon",
        DreamDex: "Gideon is a small time bounty hunter working in the deserts. He is not the best but he is not the worst. He is certified however and has done some good in his job. It beats his old job working at the energy factory crushing crystals all day. Gideon's first successful bounty awarded him a magic whistle that can summon the desert winds."
    },
    {
        Dwoobyx: "dwoobyx-0180",
        Filename: Bca12,
        Numbering: "#180",
        Name: "Hishaku",
        DreamDex: "Hishaku is the waitress at the Dragonstar burgers 7 star bar. She is the most clumsy waitress the restaurant has ever seen but the owner sees massive potential in her so does not fire her. Her balance is off and she can't find the perfect item to fix this problem of hers."
    },
    {
        Dwoobyx: "dwoobyx-0181",
        Filename: Bca13,
        Numbering: "#181",
        Name: "Jones",
        DreamDex: "The berserk anomy known as Jones. Fully given in to his own Nightmarez he lives in a welded shut prison cell that he made himself. If only someone could find something good in this world to give him the peace he needs."
    },
    {
        Dwoobyx: "dwoobyx-0182",
        Filename: Bca14,
        Numbering: "#182",
        Name: "Kammy",
        DreamDex: "Kammy once heard a song that was so beautiful being played by the planet it was forbidden to the inhabitants. It caused her to lose her sense of hearing and speaking. Though she can never be repaired there is a magic that can turn her into a chibi state that allows all her senses to come back momentarily."
    },
    {
        Dwoobyx: "dwoobyx-0183",
        Filename: Bca15,
        Numbering: "#183",
        Name: "Kusanoha",
        DreamDex: "Kusanoha is the young knight of the grasslands and was best friends to brazer before he disappeared. Even though brazer has returned rapidly aged, kusanoha is still in his rightful age."
    },
    {
        Dwoobyx: "dwoobyx-0184",
        Filename: Bca16,
        Numbering: "#184",
        Name: "Lauren",
        DreamDex: "Lauren is species of catgirl that studies the arts of the nekonomicon. She lives alone in the woods and knows many secrets about her own tribe and some from L"
    },
    {
        Dwoobyx: "dwoobyx-0185",
        Filename: Bca17,
        Numbering: "#185",
        Name: "Lilly",
        DreamDex: "Lilly use to have a boyfriend that she claims turned into a bird and flew away. She has a feather that can make anything as light as air when the feather rests on top of it. She was to use it to meet him one day but unfortunately it blew away and now is underneath a giant boulder"
    },
    {
        Dwoobyx: "dwoobyx-0186",
        Filename: Bca18,
        Numbering: "#186",
        Name: "Lin",
        DreamDex: "Lin is a doll that a very rare Dream Mimic has entered.. It only wishes to play games and fears its own destruction."
    },
    {
        Dwoobyx: "dwoobyx-0187",
        Filename: Bca19,
        Numbering: "#187",
        Name: "Liquid Smoke",
        DreamDex: "Liquid Smoke is the fictional hero of a series that our heroes watch. He is a superhero being with powers gifted to him by a star fairy. He spends eternity to rescue a princess in a castle while sharing a life force."
    },
    {
        Dwoobyx: "dwoobyx-0188",
        Filename: Bca20,
        Numbering: "#188",
        Name: "Mae",
        DreamDex: "The fortune Teller Mae! She sees not one, not two, not three, but 4 different versions of your history. What could be the meaning in that? Perhaps her powers are broken or is there something greater to the universe that is unknown?"
    },
    {
        Dwoobyx: "dwoobyx-0189",
        Filename: Bca21,
        Numbering: "#189",
        Name: "Meddle",
        DreamDex: "Meddle is a low level robot that guards Qry-Stels secret mining borders."
    },
    {
        Dwoobyx: "dwoobyx-0190",
        Filename: Bca22,
        Numbering: "#190",
        Name: "Nu",
        DreamDex: "The discoverer of the omegga prophecy. she loves books and can read through them faster than the wind blows. There is nothing left to read in her plane though.. She wishes so much for more books even if they are from other universes... Her favorite genre is secretly romance."
    },
    {
        Dwoobyx: "dwoobyx-0191",
        Filename: Bca23,
        Numbering: "#191",
        Name: "Omegga",
        DreamDex: "Omegga is the sacred child of Betas village. Beta has been tasked to watch over this orphaned eggman and guide its growth in the right direction. They say it will one day encase its entire pocket realm and decide to hatch it for good or for evil onto the dreamworld"
    },
    {
        Dwoobyx: "dwoobyx-0192",
        Filename: Bca24,
        Numbering: "#192",
        Name: "Pawn",
        DreamDex: "Pawn comes from a secret village in the skies that were once marked by nightmares but then purified. They seek isolation and work closely with the sky dragon"
    },
    {
        Dwoobyx: "dwoobyx-0193",
        Filename: Bca25,
        Numbering: "#193",
        Name: "Pear",
        DreamDex: "Pear is the teacher at the adventure academy. Though she does not have the physical skill, she knows a lot about the education needed to adventure in the field and the name of many monsters and their weakness"
    },
    {
        Dwoobyx: "dwoobyx-0194",
        Filename: Bca26,
        Numbering: "#194",
        Name: "Poa",
        DreamDex: "Poa is queen of the grasslands after her little brothers disappearance. Her land is often in rivalry with the neighboring village which proclaims itself to be superior in living and fashion."
    },
    {
        Dwoobyx: "dwoobyx-0195",
        Filename: Bca27,
        Numbering: "#195",
        Name: "Pye",
        DreamDex: "Pye is from the same village as beta and is always eating. He is actually an expert with the sword was the only witness to betas older brothers disappearance "
    },
    {
        Dwoobyx: "dwoobyx-0196",
        Filename: Bca28,
        Numbering: "#196",
        Name: "Quil",
        DreamDex: "The small sprite known as quil protects her small part of nature but is infatuated with T.V. Recording dreamwaves into magical tapes she can see packets of moments from the people on the planet."
    },
    {
        Dwoobyx: "dwoobyx-0197",
        Filename: Bca29,
        Numbering: "#197",
        Name: "Raru",
        DreamDex: "Raru is a very rare character in the DreamWorld. She's an alternate timeline version of someone named Koi that has a completely different name. She was a love interest of TuNas character but not in this life."
    },
    {
        Dwoobyx: "dwoobyx-0198",
        Filename: Bca30,
        Numbering: "#198",
        Name: "Rocky",
        DreamDex: "Rocky was once a legendary bird that a witch turned into a human. The only way to reverse it was to forget all experiences of being human which Rocky found very difficult to let go of his new life. Upon remembering his old life he remembered he was the last bird of his kind."
    },
    {
        Dwoobyx: "dwoobyx-0199",
        Filename: Bca31,
        Numbering: "#199",
        Name: "Ruth",
        DreamDex: "Working at a white mage clinic in the jungle is a nurse that has seen it all.. Mainly annoyed with the mini monkey type creatures in the area that steal her tools, but she does the best with what she has. So many men have passed out in front of her needing cpr that she has trained a monkey to do it. Shortly after the problem seemed to be cured."
    },
    {
        Dwoobyx: "dwoobyx-0200",
        Filename: Bca32,
        Numbering: "#200",
        Name: "Sadie",
        DreamDex: "Sadie the solkin is a race that hides their energy in their shadows. She joined the remake game of Warball and has become a top player after Marens departure."
    },
    {
        Dwoobyx: "dwoobyx-0201",
        Filename: Bca33,
        Numbering: "#201",
        Name: "Sandra",
        DreamDex: "Sandra runs the Inn at the beach village. She once fell in love with a downed soldier from the goblin war but it seems it didn't work out. She does know of the crash site that is still in tact."
    },
    {
        Dwoobyx: "dwoobyx-0202",
        Filename: Bca34,
        Numbering: "#202",
        Name: "Ploom",
        DreamDex: "Ploom leads a cult that know of the powers of cheetah baby. Though it's unclear if Ploom and followers are real or figments of Cheetah baby's imagination. He seems to be the best person to talk to about matters pertaining to cheetah baby."
    },
    {
        Dwoobyx: "dwoobyx-0203",
        Filename: Bca35,
        Numbering: "#203",
        Name: "Torobo",
        DreamDex: "Torobo loves school but is always getting in trouble. The teachers say he's conflicted between learning and experiencing. If he could only be set straight or scared in some way he would do more school work."
    },
    {
        Dwoobyx: "dwoobyx-0204",
        Filename: Bca36,
        Numbering: "#204",
        Name: "Trocity",
        DreamDex: "Trocity! from Fossil Canyon is a big fanboy of Grotto Gear. Despite the politics of people hating on the tech company he believes it is the future and wishes he had more samples to understand how it works."
    },
    {
        Dwoobyx: "dwoobyx-0205",
        Filename: Bca37,
        Numbering: "#205",
        Name: "Vest Guy",
        DreamDex: "The fisherman that's always fishing but never catches anything.. he's been fishing for years and doesn't even realize his wife has left him. His dreams of catching the biggest fish in the world one day eclipses all else. Maybe one day he will finally catch something big?"
    },
    {
        Dwoobyx: "dwoobyx-0206",
        Filename: Bca38,
        Numbering: "#206",
        Name: "Wallflower",
        DreamDex: "Wallflower is a recurrent npc in the planets consciousness. She is a fairy without a purpose."
    },
    {
        Dwoobyx: "dwoobyx-0207",
        Filename: Bca39,
        Numbering: "#207",
        Name: "Wifi",
        DreamDex: "Wifi has crashed down on the DreamWorld from another planet that was destroyed by the Ragnarok. She is very sensitive to DreamWaves and loves to listen to the sleeping planet"
    },
    {
        Dwoobyx: "dwoobyx-0208",
        Filename: Bca40,
        Numbering: "#208",
        Name: "Yami",
        DreamDex: "Yami is the older sister of Beta and seen as the wisest and mother of the village of the chibi eggman. Her entire tribe lives in a pocket realm of the DreamWorld where everything shrinks but nature. She has a missing brother and wishes to one day give him a sword he left behind before disappearing."
    },
    {
        Dwoobyx: "dwoobyx-0209",
        Filename: Bca41,
        Numbering: "#209",
        Name: "Zaga",
        DreamDex: "Zaga is a descendant of savages that did horrible attacks on neighboring villages purely for their resources. His tribe no longer exists but zaga understands what it means to be judged by a past."
    },
    {
        Dwoobyx: "dwoobyx-0210",
        Filename: Bca42,
        Numbering: "#210",
        Name: "Legend Man",
        DreamDex: "The Legend of LegendMan is of a boy they say that surfed a tsunami and made the water go in a different direction from the beach town.. stories of his deeds are greatly exaggerated though.. His surfboard seems to be somewhat magical as well."
    },
    {
        Dwoobyx: "dwoobyx-0211",
        Filename: Bca43,
        Numbering: "#211",
        Name: "Bloomer",
        DreamDex: "The airship industry is slowly rolling back out after a long time of being banned because of a horrible war. Bloomer however has never left it. She is an expert in skydiving and will teach you if it's for the right cause."
    },
    {
        Dwoobyx: "dwoobyx-0212",
        Filename: Bca44,
        Numbering: "#212",
        Name: "Tomacho",
        DreamDex: "TOMACHO The tomato racer. Using his motorcycle to travel from coast to coast without stopping so the spores on his tires have built up so much energy its dangerous. That's how he lives life,  riding with danger!"
    },
    {
        Dwoobyx: "dwoobyx-0213",
        Filename: Bca45,
        Numbering: "#213",
        Name: "Jipzy",
        DreamDex: "Don't let her looks fool you. Jipzy loves to battle in the tournaments. Using sound abilities she summons soundbites that could be very useful in long battles."
    },
    {
        Dwoobyx: "dwoobyx - 0214",
        Filename: Bca46,
        Numbering: "#214",
        Name: "Whim",
        DreamDex: "Whim.. no one likes whim.. They say he found a helmet in a cave that lets him talk to worms.. He now has so many slimy friends. Maybe he has info on the VERMZ in the dunes?"
    },
    {
        Dwoobyx: "dwoobyx - 0215",
        Filename: Bca47,
        Numbering: "#215",
        Name: "Zoli",
        DreamDex: "Zoli the magnificent! A little thief on the boardwalk. If you ever bump into him then check your pockets. If something is missing he likes to hide in the cargo docks near the ports."
    },
    {
        Dwoobyx: "dwoobyx - 0216",
        Filename: Bca48,
        Numbering: "#216",
        Name: "Brazer",
        DreamDex: "Brazer fell into a pocket realm in the DreamWorld one day and couldn't find a way back. In the pocket realm time was much faster and he grew to adulthood before returning. Only he knows the way in and out of this certain pocket realm."
    },
    {
        Dwoobyx: "dwoobyx - 0217",
        Filename: Bca49,
        Numbering: "#217",
        Name: "Kei",
        DreamDex: "The golden child. Kei lives in a laid back village and always wishes to go on an adventure but being the chief's son means he will have big shoes to fill one day. He is a natural at everything he does and often hides the keys to the temple to escape his daily tasks."
    },
    {
        Dwoobyx: "dwoobyx - 0218",
        Filename: Bca50,
        Numbering: "#218",
        Name: "Dr00L",
        DreamDex: "The DR-00L is one of Grotto Gears first mecha models. Originally a mobile outpost it has been modified to look more human in appearance."
    },
    {
        Dwoobyx: "dwoobyx - 0219",
        Filename: Bcc9,
        Numbering: "#219",
        Name: "Minotaurus",
        DreamDex: "Deep in the ancient maze forest of the Dreamworld there is said to be a vein that sprouts from the planets dreamseed. There in its location is a the one known only as the Minotaurus.. His origin is unknown.. but his mission is to destroy all who come near. He is the best fighter on the planet and uses his sword energized by the planets fairies."
    },
    {
        Dwoobyx: "dwoobyx - 0220",
        Filename: Bcc10,
        Numbering: "#220",
        Name: "Goblin King",
        DreamDex: "A tetranimh of royal blood. The goblin king is an ancient reincarnating being that went to war with the species of men. If he is not stopped he will rule the DreamWorld and enter it into a dark age to feed the nightmares for eternity. He is a successful Conqueror who has destroyed planets before."
    },
    {
        Dwoobyx: "dwoobyx - 0221",
        Filename: Bcc11,
        Numbering: "#221",
        Name: "Dragon",
        DreamDex: "The mysterious gang leader known as Dragon has corrupted all the top cities of the planet. No one knows where his power comes from or his real identity. He has no desire to be a ruler of the world he only wishes to feed its energy to the nightmares."
    },
    {
        Dwoobyx: "dwoobyx - 0222",
        Filename: Bcc12,
        Numbering: "#222",
        Name: "Requiem",
        DreamDex: "A changeling from a dying timeline with the mission of resurrecting a time fading into the dreamwave. A desperate Prince of the goblin king who wishes to go home by reconstructing the same event that doomed his time."
    },
    {
        Dwoobyx: "dwoobyx - 0223",
        Filename: Bcc13,
        Numbering: "#223",
        Name: "Gaiden",
        DreamDex: "Gaiden, the guardian of the Dreamworld is activated. His lifeforce is connected to the Dreamworld, he alone can choose whether the planet lives or dies. The great surveyor has decided.. You are the threat to its survival."
    },
    {
        Dwoobyx: "dwoobyx - 0224",
        Filename: Bcc14,
        Numbering: "#224",
        Name: "NeitMare",
        DreamDex: "The Lord of Nightmares remembers very little of who he was or use to be. He only knows he is in a realm of energy that has manifested shape and land and gave life to negative energy. A prisoner of his own universe. He wishes to find a challenger in other realms that he can defeat for the right to crossover."
    },
    {
        Dwoobyx: "dwoobyx - 0225",
        Filename: Bcc15,
        Numbering: "#225",
        Name: "Overture",
        DreamDex: "Overture is a dragon that rules over a certain moment in time. The present. It is its responsibility to organize massive amounts of data and set it in canon. What happens when a universe enters a multiverse? Data overload. Overture goes mad being unable to process it all and wants only silence."
    },
    {
        Dwoobyx: "dwoobyx - 0226",
        Filename: Bcc16,
        Numbering: "#226",
        Name: "Negative One",
        DreamDex: "Deep within the nightmare realm a being exists that grows in power whenever a dream is shattered. Using a forbidden musical art he has discovered the theme of the universe. Using its notes he wishes to conduct a symphony that will invert reality. If he plays a single note wrong it could mean the destruction of the universe."
    }
]