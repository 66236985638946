import React from "react";
import WaveBanner from "./WaveBanner";
import WaveSection from "./WaveSection";
import GoToTop from "../components/GoToTop.jsx";
import WaveTopGif from "../assets/topButton/m-spraito-hen.gif";

function WaveHome() {
  return (
    <div>
      <WaveBanner />
      <WaveSection />
      <div className=" fixed -right-[0%] top-[88%] z-50 rotate-[270]">
        <GoToTop imageSrc={WaveTopGif} />
      </div>
    </div>
  );
}

export default WaveHome;
