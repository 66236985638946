import DwapeHero from "./DwapeHero";
import GoToTop from "../../components/GoToTop.jsx";
import DwoobyTopGif from "../../assets/topButton/m-spraito-neighto.gif";

const DwapeHome = () => {
    return (
        <>
            <DwapeHero />

            <div className=" fixed -right-[0%] top-[88%] z-50 rotate-[270]">
                <GoToTop imageSrc={DwoobyTopGif} />
            </div>
        </>
    )
}
export default DwapeHome;