const DwoobyDetails = () => {
    return (
        <>
            <div className="pinkBg">
                <div className="container py-20 text-xl text-[#cccccc]">
                    <div className="leading-8">
                        The DreamWorlds merge into the blockchain has caused all the data in the Save Crystal to become
                        completely corrupted! Secrets of historical past events and modern times are no longer protected.
                        A group of Scribals whose job it is to document all beings in the universe for the save crystal have
                        taken this opportunity to jump chains and leak top secret information of the DreamWorld!
                        <br /><br />
                        Releasing the
                        Strategy Guide Cards known as DWOOBY that contain the Scribals research. These cards are filled with
                        beings of all types that exist in the DreamWorld! The save crystal is weakened and has no way of
                        recovering the lost information.
                        <br /><br />
                        The Transparency of the DreamWorld has been taken to an entirely
                        new level. The Scribals are leaking! Some rare and some never before seen life of the DreamWorld
                        has now been uncovered
                    </div>
                </div>
            </div>
        </>
    )
}
export default DwoobyDetails;