import DwogHero from "./DwogHero";
import GoToTop from "../../components/GoToTop.jsx";
import DwoobyTopGif from "../../assets/topButton/m-spraito-neono.gif";

const DwogHome = () => {
    return (
        <>
            <DwogHero />
            <div className=" fixed -right-[0%] top-[88%] z-50 rotate-[270]">
                <GoToTop imageSrc={DwoobyTopGif} />
            </div>
        </>
    )
}
export default DwogHome;