import React from "react";
import { NavLink } from "react-router-dom";
import twitter from "../assets/logo/twitter_logo_White.png";
import lukso from "../assets/logo/lukso-white-CCC.png";
import universaldev from "../assets/logo/universaldev-white-CCC.png";

const Footer = () => {
  const socialLinks = [
    {
      id: 1,
      img: twitter,
      link: `https://x.com/dwopes`,
    },
    {
      id: 2,
      img: lukso,
      link: `https://profile.link/dwope@aDD8`,
    },
    {
      id: 3,
      img: universaldev,
      link: `https://universal.page/drops/dwoobz`,
    },
  ];

  return (
    <div className=" relative bg-[#232323]">
      <div className=" container py-20">
        <div className="flex flex-col items-center justify-around gap-10  text-[#cccccc] sm:flex-row">
          <h3 className="text-[20px] text-[#D1D1D1] font-['Jost-light'] font-light">
            <NavLink to="/terms" duration={500}>
              Terms Of Use
            </NavLink>
          </h3>
          <div className="">
            <h1 className="font-twist font-normal text-[60px] text-center leading-[50px] w-[358px] h-[43px]">DWOPE</h1>
          </div>
          <h3 className="text-[20px] text-[#D1D1D1] font-['Jost-light'] font-light">
            <NavLink to="/privacy" duration={500}>
              Privacy Policy
            </NavLink>
          </h3>
        </div>

        <div className="mt-7 flex justify-center">
              {socialLinks.map(({ id, link, img }) => (
                <a
                  href={link}
                  rel="noreferrer"
                  target="_blank"
                  key={id}
                  className="cursor-pointer rounded-full border-2 border-[#cccccc] mx-2 px-4 py-1 text-xl duration-200 hover:scale-110"
                >
                  <img src={img} alt="" className="h-6 w-6 object-contain" />
                </a>
              ))}
            </div>
      </div>
    </div>
  );
};

export default Footer;
