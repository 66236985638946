import React from "react";
import AboutImg from "../assets/about/about.png";
import BG1 from "../assets/backgrounds/Pearl_Texture_2Loop_Inverted.webm";
import S1 from "../assets/sponser/stratos-logo-hb-bz.png";
import S2 from "../assets/sponser/output-onlinepngtools-18-2.png";
import DreamSeed from "./DreamSeed";

const AboutDwope = () => {
  return (
    <div className="blueBg">
      <div className="container">
        <h1 className="py-20 text-3xl text-[#cccccc]  sm:text-5xl md:text-7xl">
          About{" "}
          <span className="animate-text  bg-gradient-to-r from-[#EF7A76]  to-[#F6D794] bg-clip-text text-transparent">
            DWOPE
          </span>
        </h1>

        <div className="container mx-auto flex flex-col items-center gap-16 pb-20 md:flex-row">
          <div className="h-full w-full md:mb-0 md:w-[48%] ">
            <div className="h-full w-full object-fill">
              <img
                className="h-full w-full rounded object-fill object-center"
                alt="hero"
                src={AboutImg}
              />
            </div>
            <div className="relative my-5 flex justify-center">
              <h3 className="animate-text bg-gradient-to-r  from-[#EF7A76] to-[#F6D794]  bg-clip-text text-3xl text-transparent">
                Cheetah Baby
              </h3>
              <h3 className="absolute top-0 animate-text bg-gradient-to-r  from-[#EF7A76] to-[#F6D794]  bg-clip-text text-4xl text-transparent blur">
                Cheetah Baby
              </h3>
            </div>
          </div>
          <div className="flex w-full flex-col items-center text-center  md:w-[52%]  md:items-start  md:text-left lg:flex-grow ">
            <p className="leading-[1.5rem] text-[#cccccc] text-[18px]">
              Hijacking the WEAVEz for her amusement, Cheetah Baby has
              collided the DreamWorld into the blockchain and tangled it with a
              Dream Seed that’s too big and too complicated to unravel. Every
              character has become mixed and mashed in a sea of powerful ideas
              and spells. Whirling and twirling with no escape in sight.
              Corrupting the very core of the Save Crystal, which backs up all
              data.
              <br />
              <br />
              Even though The DreamWorld seems to always be one step away from
              destruction by dragons, demons, aliens, robots, mythical
              creatures, and people gaining immense power from an energy known
              as eXp, it doesn’t matter, because our heroes, their allies, and
              its citizens always find a way!
              <br />
              <br />
              <span className="text-2xl text-[#FFC700]">
                But wait, why are our heroes ducks?
              </span>
              <br />
              <br />
              Oh no… and Apes..Dear god and Dogs..
              <br />
              <br />
              <span className="text-2xl text-[#FFC700]">
                {" "}
                If this has all been amalgamated, can it be saved?{" "}
              </span>
              <br />
              <br />
              Well, there is a common currency, that many know of as “DEED” in
              the DreamWorld, that is in the bleed of all this mess right now.
              It seems to have mixed with something in the blockchain, making it
              stronger and more valuable. Even during such a chaotic time, the
              planet is secreting it. Does It want to be saved?
              <br />
              <br />
              No, The DreamWorld wants to rescue its inhabitants from the cosmic
              games of Cheetah Baby! This will be the currency that keeps this
              fever dream from cementing. It is the cure! With this, many new
              adventures are waiting to be found in this new world! There is
              hope!
            </p>
          </div>
        </div>
      </div>

      <div className="mainpage">
        <div className="video-docker absolute left-0 top-0 h-full w-full overflow-hidden">
          <video
            className="absolute min-h-full min-w-full object-cover "
            src={BG1}
            type="video/webm"
            autoPlay
            muted
            loop
          ></video>
        </div>
        <div className="relative w-full">
          <div className="container">
            <div className="my-28 flex w-full flex-col items-center justify-around gap-10 text-[#cccccc] md:flex-row">
              <div>
                <h3 className="text-4xl md:text-5xl text-center">
                  Available{" "}
                  <span className="animate-text  bg-gradient-to-r from-[#EF7A76]  to-[#F6D794] bg-clip-text  text-transparent">
                    On
                  </span>
                </h3>
                <img
                  src={S1}
                  alt=""
                  className="h-20 w-full object-contain md:h-24 "
                />
              </div>
              <div>
                <h3 className="text-4xl md:text-5xl">
                  Powered{" "}
                  <span className="animate-text  bg-gradient-to-r from-[#EF7A76]  to-[#F6D794] bg-clip-text  text-transparent">
                    By
                  </span>
                </h3>
                <img
                  src={S2}
                  alt=""
                  className="h-14 w-full object-contain md:h-20"
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      <DreamSeed />
    </div>
  );
};

export default AboutDwope;
