import { useState, useEffect } from "react";
import Banner from "../../components/Banner";
const WeepleHero = () => {

    const [images, setimages] = useState([]);

    const loadEachTime = 20;
    const [showMoreData, setShowMoreData] = useState(loadEachTime);
    const showMore = () => {
        if ((showMoreData >= loadEachTime) && (showMoreData <= images.length)) {
            setShowMoreData(showMoreData + loadEachTime);
        }
    };
    const importAll = (r) => {
        return r?.keys()?.map(r);
    }
    const getImages = async () => {
        let temps = await importAll(require?.context('../../assets/dwoobz/weeple', false, /\.(png|gif)$/));
        setimages(temps)
    }
    useEffect(() => {
        getImages();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    return (
        <div>
            <Banner bannerText="WEEPLE" />
            <div>
                <div className="pinkBg">
                    <div className="container">
                        <div className="py-20 text-xl text-[#cccccc]">
                            <p className="leading-8">
                                IT HAS BEGUN!! The Weeple have shown themselves! The 3rd Dimension has been breached!
                                As Deeple leaked into the DreamWorld the frequency has entirely flipped its norms!
                                <br /><br />
                                A new dimension of energy has emerged and with it, the guardians of the multiverse channels
                                themselves have shown themselves.
                                <br /><br />
                                The Weeple are here and with them comes an entirely new set of physics over the Dream!
                                What does this mean for the future?! CHAOS has created new ORDER..
                                <br /><br />
                                THE WEEPLE ARE HERE!!
                            </p>
                        </div>
                    </div>
                </div>
                <div className="yellowBg">
                    <div className="container p-6">
                        <div className="grid grid-cols-3 md:grid-cols-4 xl:grid-cols-5 gap-5 p-5 bg-[#252525] rounded-xl">
                            {images?.slice(0, showMoreData)?.map((item, index) => (
                                <div key={index}>
                                    <div className="items-center justify-center overflow-hidden rounded-xl bg-[#0a0a0a56]">
                                        <img
                                            src={item}
                                            alt={item}
                                            className=" scale-100 hover:scale-125 ease-in duration-500"
                                        />
                                    </div>
                                </div>
                            ))}
                        </div>

                        {(images.length > showMoreData) ?
                            <>
                                <div className="text-center my-4 p-4">
                                    {images.length > loadEachTime && (
                                        <button type="button" onClick={showMore}
                                            className="button text-xl text-[#232323] p-3 rounded-xl border border-amber-300 bg-gradient-to-t from-indigo-400  via-pink-300 to-red-200">
                                            Show More
                                        </button>
                                    )}
                                </div>
                            </>
                            :
                            ''
                        }
                    </div>
                </div>
            </div>
        </div>
    )
}
export default WeepleHero;