import Banner from "../components/BannerWithSub";
const TermAndConditionBanner = () => {
   
    return ( 
        <>
           <div className="relative">
                <Banner bannerText="DWOPE" BannerTextSub="Term Of Use"/>
            </div>
        </>
    )
}
export default TermAndConditionBanner;