import React from "react";

import Banner from "../components/Banner";

const DwoobyBanner = () => {
  return (
    <div className="relative">
      <Banner bannerText="DWOOBY" />
    </div>
  );
};

export default DwoobyBanner;
