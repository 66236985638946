import React from "react";
import PropTypes from "prop-types";

const GoToTop = ({ imageSrc }) => {
  const goToBtn = () => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  };

  return (
    <button className="top-btn" onClick={goToBtn}>
      <img src={imageSrc} alt="" className="h-28 w-28" />
    </button>
  );
};

GoToTop.propTypes = {
  imageSrc: PropTypes.string.isRequired,
};

export default GoToTop;
