import React from "react";
import { CategoryData } from "../data/CategoryData";

const Category = () => {
  return (
    <div>
      <div className="relative w-full">
        <div className="container py-20">
          <div className="grid w-full grid-cols-1 place-content-center place-items-center gap-10 sm:grid-cols-2">
            {" "}
            {CategoryData.map((item) => {
              return (
                <div
                  key={item.id}
                  className="item-cneter flex flex-col items-center justify-center "
                >
                  <div>
                    <img src={item.img} alt="" className="h-40 w-40" />
                  </div>

                  <h1 className="text-center text-2xl text-[#cccccc]">
                    {item.title}
                  </h1>
                  <h1 className="text-center text-2xl text-[#cccccc]">
                    {item.subtitle}
                  </h1>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Category;
