import React from "react";
import { dreamSeedData } from "../data/dreamSeedData";

const DreamSeed = () => {
  return (
    <div className="container">
      <h1 className="pt-20 text-center text-3xl text-[#cccccc] sm:text-5xl md:text-7xl">
        Dream Seed{" "}
        <span className="animate-text  bg-gradient-to-r from-[#EF7A76]  to-[#F6D794] bg-clip-text text-transparent">
          $DEED
        </span>
      </h1>
      <p className="mt-5 pb-20 text-center  tracking-widest text-[#cccccc]">
        Staking reward, which has definitely no value, not a penny to it’s name
      </p>

      <div className="grid grid-cols-2 gap-5 sm:grid-cols-2 md:grid-cols-3">
        {dreamSeedData.map((item) => {
          return (
            <div
              className="overflow-hidden rounded-2xl bg-black/30 backdrop-blur-sm"
              key={item.id}
            >
              <div className="aspect-w-1 aspect-h-1">
                <div className="overflow-hidden rounded-xl">
                  <img
                    src={item.img}
                    alt=""
                    className="rounded-2xl object-cover scale-100 hover:scale-125 ease-in duration-500"
                  />
                </div>
              </div>

              <div className="flex flex-col md:flex-row justify-start px-3 pt-3">
                <h1 className="text-xl md:text-2xl text-[#cccccc]">{item.title}</h1>
                {/* <h1 className="text-sm md:text-2xl text-[#cccccc]">{item.count}</h1> */}
              </div>
              <h1 className="p-3 text-[16px] md:text-[20px] font-[350] font-[Roc-Grotesk-light] text-[#cccccc] leading-[150%]">
                {item.inspire}
              </h1>
            </div>
          );
        })}
      </div>

      <div className="flex flex-col items-center justify-center py-16">
        <h1 className="animate-text bg-gradient-to-r from-[#EF7A76] to-[#F6D794]  bg-clip-text text-4xl text-transparent">
          Click Em
        </h1>
        <h1 className="text-4xl text-[#cccccc]">1,979</h1>
      </div>
    </div>
  );
};

export default DreamSeed;
