import DeepleHero from "./DeepleHero";

import GoToTop from "../../components/GoToTop.jsx";
import DwoobyTopGif from "../../assets/topButton/m-spraito-L.gif";

const DeepleHome = () => {
    return (
        <>
            <DeepleHero />
            <div className=" fixed -right-[0%] top-[88%] z-50 rotate-[270]">
                <GoToTop imageSrc={DwoobyTopGif} />
            </div>
        </>
    )
}
export default DeepleHome;