import C1 from "../assets/icons/Economy.jpg";
import C2 from "../assets/icons/SteakStation.png";
import C3 from "../assets/icons/DreamStore.png";
import C4 from "../assets/icons/DreamWave.png";

export const CategoryData = [
  {
    id: 1,
    img: C1,
    title: "DWOOBZ",
    subtitle: "Mint",
  },
  {
    id: 2,
    img: C2,
    title: "SteakStation",
    subtitle: "Staking",
  },
  {
    id: 3,
    img: C3,
    title: "DreamStore",
    subtitle: "Boosters + Items",
  },
  {
    id: 4,
    img: C4,
    title: "WEAVEz",
    subtitle: "Trading Card Game",
  },
];
