
import Dwape from "../assets/dwoobz/leadImage/dwapemix.gif"
import Dwog from "../assets/dwoobz/leadImage/dwogmix.gif"
import Deeple from "../assets/dwoobz/leadImage/deeplemix.gif"
import Weeple from "../assets/dwoobz/leadImage/ezgif.com-gif-maker (12).gif"
import Dwuck from "../assets/dwoobz/leadImage/dwuckmix.gif"
import Dwagon from "../assets/dwoobz/leadImage/dwagonmix.gif"

const DwoobzTopImage = [
    {
        name: "Weeple",
        link: "/dwoobz/weeple",
        image: Weeple,
    },
    {
        name: "Deeple",
        link: "/dwoobz/deeple",
        image: Deeple,
    },
    {
        name: "Dwog",
        link: "/dwoobz/dwog",
        image: Dwog,
    },
    {
        name: "Dwuck",
        link: "/dwoobz/dwuck",
        image: Dwuck,
    },
    {
        name: "Dwagon",
        link: "/dwoobz/dwagon",
        image: Dwagon,
    },
    {
        name: "Dwape",
        link: "/dwoobz/dwape",
        image: Dwape,
    },
]
export default DwoobzTopImage;