import React from "react";
import BG1 from "../assets/backgrounds/Pearl_Texture_2Loop_Inverted.webm";
import { BsArrowDownCircle } from "react-icons/bs"

const Hero = () => {


  return (
    <div>
      {" "}
      <div className="mainpage">
        <div className="video-docker  fixed left-0 top-0 h-full w-full overflow-hidden">
          <video
            className="absolute min-h-full min-w-full object-cover "
            src={BG1}
            type="video/webm"
            autoPlay
            muted
            loop
          ></video>
        </div>
        <div className="relative w-full">
          <div className="container">
            <div className="my-36 md:mb-28 md:mt-72">
              <h1 className="text-4xl leading-[4rem]  text-[#CCCCCC] sm:text-5xl md:text-[80px] md:leading-[6rem]">
                Experience the <br />{" "}
                <span className="animate-text  bg-gradient-to-r from-[#EF7A76]  to-[#F6D794] bg-clip-text text-transparent">
                  Future of Collecting
                </span>
              </h1>
              <div className="mt-10 flex flex-wrap items-center gap-20">
                <h1 className="outlined-text font-roboto text-4xl !font-black  sm:text-5xl md:text-[8rem]">
                  NFTs 2.0
                </h1>

                <a
                  href="/"
                  className="rounded-full border-2 border-[#cccccc] px-6 py-3 font-roboto text-[#cccccc] duration-500 hover:underline md:px-10 md:py-5 md:text-2xl  "
                >
                  Let's Explore More
                </a>
              </div>
            </div>
            <div>
              {/* <img src={ScrollDownImg} alt="" /> */}

              <div>
                <svg viewBox="0 0 2500 500">
                  <defs>
                    <path
                      d="M50,250c0-110.5,89.5-200,200-200s200,89.5,200,200s-89.5,200-200,200S50,360.5,50,250"
                      id="textcircle"
                    >
                      <animateTransform
                        attributeName="transform"
                        begin="0s"
                        dur="10s"
                        type="rotate"
                        from="0 250 250"
                        to="360 250 250"
                        fill="red"
                        repeatCount="indefinite"
                        textAnchor="middle"
                      />
                    </path>
                  </defs>
                  <text

                    alignmentBaseline="middle"
                    fontVariant="all-small-caps"
                    fontFamily="SuperLegendBoy"
                    fontSize="28"
                    fontWeight="bold"
                    fill="#DFD7BF"

                    className="text-5xl " dy="50" textLength="1200">
                    <textPath className="animate-spin text-5xl" xlinkHref="#textcircle">Scroll Down. </textPath>
                  </text>
                  <foreignObject x="150" y="150" width="200" height="200">
                    <div className="flex justify-center items-center h-full">
                      <BsArrowDownCircle color="#DFD7BF" size={200} />
                    </div>
                  </foreignObject>
                </svg>
              </div>





            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Hero;
