"use client";

import React, { useState } from "react";
import { Transition } from "@headlessui/react";

const FAQ = () => {
  const [activeIndex, setActiveIndex] = useState(null);

  const faqs = [
    {
      question: "Why are there 6 families ?",
      answer:
        "Keep in mind that all these families will be available in the same mint. Regardless, each family are entitled to different perks (in the SteakStation 🥩, and the WEAVEz 🎮), there will also be perks to holding multiple",
    },
    {
      question: "What's the mint price ?",
      answer: "42",
    },
    {
      question: "W E N ?",
      answer:
        "When you buy and hold the floor",
    },
    {
      question: "What's this WEAVEz game all about ?",
      answer:
        "It’s essentially a card game played with an open-world where each families characters can hang out but shhhh it’s still a secret 😜 The game will be developped by our friends at ",
    },
    {
      question: "Is there a Whitelist ?",
      answer:
        "Nope, no whitelist, nothing, nada, void, it’s a pure free-for-all, first come first serve UwU",
    },
    //
    // Add more FAQ items as needed
  ];

  const toggleAccordion = (index) => {
    setActiveIndex(activeIndex === index ? null : index);
  };

  return (
    <section className="yellowBg" id="faq">
      <div className="container  py-20">
        <h1 className="pt-20 text-3xl text-[#cccccc]  sm:text-5xl md:text-7xl">
          Frequently Asked{" "}
          <span className="animate-text  bg-gradient-to-r from-[#EF7A76]  to-[#F6D794] bg-clip-text text-transparent">
            Questions
          </span>
        </h1>

        <div className="mt-20 w-full">
          {faqs.map((faq, index) => (
            <div key={index} className="w-full  py-4">
              <div
                className="flex w-full cursor-pointer items-center justify-between rounded-2xl bg-black/30 p-3 backdrop-blur-sm"
                onClick={() => toggleAccordion(index)}
              >
                <h3 className="text-xl  text-[#cccccc]">{faq.question}</h3>
                <svg
                  className={`h-5 w-5 transform  text-[#cccccc] transition-transform duration-300 ${
                    activeIndex === index ? "rotate-0" : "-rotate-180"
                  }`}
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M19 9l-7 7-7-7"
                  />
                </svg>
              </div>
              <Transition
                show={activeIndex === index}
                enter="transition duration-100 ease-out"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="transition duration-75 ease-out"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                {(ref) => (
                  <p
                    ref={ref}
                    className="mt-2 rounded-2xl bg-black/30 p-3 text-base text-[#cccccc] backdrop-blur-sm"
                  >
                    {faq.answer}
                  </p>
                )}
              </Transition>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default FAQ;
