import React from 'react';

const PageNotFound = () => {
    return (
        <>
            <div className="pinkBg">
                <div className="container flex flex-col text-xl md:text-3xl xl:text-5xl leading-loose h-screen w-screen items-center justify-center">
                    <div>
                        404 Error
                    </div>
                    <div>
                        Page Not found
                    </div>
                </div>

            </div>
        </>
    )
}

export default PageNotFound;
