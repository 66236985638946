import React from "react";
import DwoobxBanner from "./DwoobxBanner";
import DwoobxDetails from "./DwoobxDetails";
import DwoobxSections from "./DwoobxSections";
import GoToTop from "../components/GoToTop.jsx";
import DwoobxTopGif from "../assets/topButton/m-spraito-dwago.gif";

const DwoobxHome = () => {
  return (
    <div>
      <DwoobxBanner />
      <DwoobxDetails />
      <DwoobxSections />
      <div className=" fixed -right-[0%] top-[88%] z-50 rotate-[270]">
        <GoToTop imageSrc={DwoobxTopGif} />
      </div>
    </div>
  );
};

export default DwoobxHome;
