import TermAndConditionBanner from "./TermAndConditionBanner";
import TermAndConditionDetails from "./TermAndConditionDetails";
import GoToTop from "../components/GoToTop.jsx";
import TermsTopGif from "../assets/topButton/m-spraito-overto.gif";

const TermAndConditionHome = () => {
  return (
    <div className="">
      <TermAndConditionBanner />
      <TermAndConditionDetails />
      <div className=" fixed -right-[0%] top-[88%] z-50 rotate-[270]">
        <GoToTop imageSrc={TermsTopGif} />
      </div>
    </div>
  );
};
export default TermAndConditionHome;
