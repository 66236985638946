import D1 from "../assets/dream/dwape.png";
import D2 from "../assets/dream/dwog.png";
import D3 from "../assets/dream/deeple.png";
import D4 from "../assets/dream/weeple.png";
import D5 from "../assets/dream/dwuck.png";
import D6 from "../assets/dream/1441.gif";

export const dreamSeedData = [
  {
    id: 1,
    title: "DWAPE",
    count: "404",
    img: D1,
    inspire: "Inspired By BAYC",
  },
  {
    id: 2,
    title: "DWOG",
    count: "576",
    img: D2,
    inspire: "Inspired by the Doge Pound",
  },
  {
    id: 3,
    title: "DEEPLE",
    count: "242",
    img: D3,
    inspire: "Inspired By CryptoPunks",
  },
  {
    id: 4,
    title: "DWEAR",
    count: "242",
    img: D4,
    inspire: "Inspired By Okay Bears",
  },
  {
    id: 5,
    title: "DWUCK",
    count: "499",
    img: D5,
    inspire: "Inspired By Wassies",
  },
  {
    id: 6,
    title: "DWAGON",
    count: "16",
    img: D6,
    inspire: "Inspired By Boryoku Drogonz",
  },
];
