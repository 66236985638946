import { useState, useEffect } from "react";
import Banner from "../../components/Banner";
const DwapeHero = () => {

    const [images, setimages] = useState([]);

    const loadEachTime = 20;
    const [showMoreData, setShowMoreData] = useState(loadEachTime);
    const showMore = () => {
        if ((showMoreData >= loadEachTime) && (showMoreData <= images.length)) {
            setShowMoreData(showMoreData + loadEachTime);
        }
    };

    const importAll = (r) => {
        return r?.keys()?.map(r);
    }
    useEffect(() => {
        //Runs on every render
        let temps = importAll(require?.context('../../assets/dwoobz/dwape', false, /\.(png|gif)$/));
        setimages(temps)
    }, []);
    return (
        <>
            <Banner bannerText="DWAPE" />
            <>
                <div className="pinkBg">
                    <div className="container">
                        <div className="py-20 text-xl text-[#cccccc]">
                            <p className="leading-8">
                                The planet was snoring and the dream was good. The nightmares were defeated and the planet
                                was in an age of Golden Slumbers.
                                <br />
                                <br />
                                Then for what appeared to be no reason at all, every civilian, samaritan, and citizen, rich and
                                poor felt it in their bones.  A loud giggling, followed with crackling rainbows lighting up the
                                sky. A voice rang out over the planet's skies and a single word was spoken.. “BOOOOOOOORING…”.
                                This single word would sweep the galaxy off its feet and plunge us into a new world…  Fur was
                                everywhere and memories of multiple lives were blending into each other
                                <br />
                                <br />
                                Cheetah Baby's odd mixture of universes has turned every NPC in the dreamworld into some sort of..
                                Disinterested Primate.. Even the panacea won't cure this status! Yet deep inside each attribute
                                seems to be a hint of hope.. Even at times like this, these unsung heroes are helping to progress
                                the planet to a new adventure.
                            </p>
                        </div>
                    </div>
                </div>
                <div className="yellowBg">
                    <div className="container p-6">
                        <div className="grid grid-cols-3 md:grid-cols-4 xl:grid-cols-5 gap-5 p-5 bg-[#252525] rounded-xl">
                            {images?.slice(0, showMoreData)?.map((item, index) => (
                                <div key={index}>
                                    <div className="items-center justify-center overflow-hidden rounded-xl bg-[#0a0a0a56]">
                                        <img
                                            src={item}
                                            alt={item}
                                            className=" scale-100 hover:scale-125 ease-in duration-500"
                                        />
                                    </div>
                                </div>
                            ))}
                        </div>

                        {(images.length > showMoreData) ?
                            <>
                                <div className="text-center my-4 p-4">
                                    {images.length > loadEachTime && (
                                        <button type="button" onClick={showMore}
                                            className="button text-xl text-[#232323] p-3 rounded-xl border border-amber-300 bg-gradient-to-t from-indigo-400  via-pink-300 to-red-200">
                                            Show More
                                        </button>
                                    )}
                                </div>
                            </>
                            :
                            ''
                        }
                    </div>
                </div>
            </>
        </>
    )
}
export default DwapeHero;