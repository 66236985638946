import React from "react";
import Banner from "../components/Banner";
const DwoodzBanner = () => {
    return (
        <>
            <div className="relative">
                <Banner bannerText="DWOOBZ" />
            </div>
        </>
    )
}
export default DwoodzBanner;