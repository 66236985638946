import Nav from "./global/Nav";
import Footer from "./global/Footer";
import Home from "./home/Home";
import Dwoobx from "./dwoobx/DwoobxHome";
import Dwooby from "./dwooby/DwoobyHome";
import DwoodzHome from "./dwoodz/DwoodzHome";
import BattleCardzHome from "./BattleCardz/BattleCardzHome";
import { Routes, Route } from "react-router-dom";
import WaveHome from "./wave/WaveHome";
import FounderHome from "./founders/FounderHome";
import TermAndConditionHome from "./TermAndCondition/TermAndConditionHome";
import PrivacyPolicyHome from "./privacyPolicy/PrivacyPolicyHome";
import DwapeHome from "./dwoodz/dwape/DwapeHome";
import PageNotFound from "./PageNotFound";
import Contollers from "./BtnElems/Contollers";
import Player from "./BtnElems/Player";
import Assets from "./BtnElems/Assets";
import DwogHome from "./dwoodz/dwog/DwogHome";
import DeepleHome from "./dwoodz/deeple/DeepleHome";
import WeepleHome from "./dwoodz/weeple/WeepleHome";
import DwuckHome from "./dwoodz/dwuck/DwuckHome";
import DwagonHome from "./dwoodz/dwagon/DwagonHome";
const App = () => {
  return (
    <div className="App">
      <Nav />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/dwoobx" element={<Dwoobx />}>
          <Route index element={<Contollers />} />
          <Route path="controllers" element={<Contollers />} />
          <Route path="players" element={<Player />} />
          <Route path="assists" element={<Assets />} />
        </Route>
        <Route path="/dwooby" element={<Dwooby />} />
        <Route path="/dwoobz" element={<DwoodzHome />} />
        <Route path="/dwoobz/dwape" element={<DwapeHome />} />
        <Route path="/dwoobz/dwog" element={<DwogHome />} />
        <Route path="/dwoobz/deeple" element={<DeepleHome />} />
        <Route path="/dwoobz/weeple" element={<WeepleHome />} />
        <Route path="/dwoobz/dwuck" element={<DwuckHome />} />
        <Route path="/dwoobz/dwagon" element={< DwagonHome />} />

        <Route path="/Weave" element={<WaveHome />} />
        <Route path="/Team" element={<FounderHome />} />
        <Route path="/Kardz" element={<BattleCardzHome />} />
        <Route path="/terms" element={<TermAndConditionHome />} />
        <Route path="/privacy" element={<PrivacyPolicyHome />} />
        <Route path="*" element={<PageNotFound />} />
      </Routes>
      <Footer />
    </div>
  );
};

export default App;
