import DwagonHero from "./DwagonHero";
import GoToTop from "../../components/GoToTop.jsx";
import DwoobyTopGif from "../../assets/topButton/m-spraito-minoto.gif";


const DwagonHome = () => {
    return (
        <>
            <DwagonHero />

            <div className=" fixed -right-[0%] top-[88%] z-50 rotate-[270]">
                <GoToTop imageSrc={DwoobyTopGif} />
            </div>
        </>
    )
}
export default DwagonHome;