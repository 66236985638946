import React from 'react'
import Banner from "../components/Banner";



function FounderBanner() {
  return (
    <div>
        <Banner bannerText="FOUNDERS" />
    </div>
  )
}

export default FounderBanner