import React from "react";
import fBg from "../assets/backgrounds/founderBg.png";
import avatar1 from "../assets/backgrounds/Avatar/fAvatar1.png";
import avatar2 from "../assets/backgrounds/Avatar/fAvatar2.png";

function FounderSection() {
  return (
    <div className="pinkBlueBg">
      <div className="container flex  h-full w-full flex-col items-center justify-center py-20 ">
        <div className="relative md:p-8">
          <div>
            <img src={fBg} alt="" className="mx-auto md:w-[700px]" />
          </div>
          <div className="absolute left-[40%] top-1/2 -translate-x-1/2 -translate-y-1/2 transform">
            <div className="flex items-center gap-5 text-[#ccc]">
              <img
                src={avatar1}
                alt=""
                className="h-20 w-20 object-contain md:h-40   md:w-40"
              />
              <div className="">
                <h1 className="text-2xl sm:text-4xl ">CHEYCKIT</h1>
                <h4 className="text-lg sm:text-xl ">Lead of DWOPE</h4>
              </div>
            </div>
          </div>
        </div>

        <div className="relative mt-10 md:p-8">
          <div>
            <img src={fBg} alt="" className="mx-auto md:w-[700px]" />
          </div>
          <div className="absolute left-[45%] top-1/2 -translate-x-1/2 -translate-y-1/2 transform md:left-[50%]">
            <div className="flex w-full items-center gap-5 text-[#ccc]">
              <div className="">
                <h1 className="text-2xl sm:text-4xl ">eXpStrife</h1>
                <h4 className="text-lg sm:text-xl">Hands of DWOPE</h4>
              </div>
              <img
                src={avatar2}
                alt=""
                className="h-20 w-20 object-contain md:h-40 md:w-40"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default FounderSection;
